
import { useDispatch, useSelector } from "react-redux"
import bg from "../../assets/images/home/bg.png"
import coin from '@/assets/images/coin.png'
import invite from "../../assets/images/home/invite.png"
import avatar from "../../assets/images/home/avatar.png"
import cards from "../../assets/images/home/cards.png"
import firework from "../../assets/images/home/firework.png"
import { useNavigate } from "react-router-dom"
import coinsMusic from "@/assets/mp3/coins.mp3"
import finger from "@/assets/images/myInvites/finger.png"
import { changeUserInfo } from "../../store/module/tabbar"
import DrawCardsDialog from "../../components/DrawCardsDialog"
import NFTDrawCardsDialog from "../../components/NFTDrawCardsDialog"
import { useCallback, useEffect, useRef, useState } from "react"
import NewUserGift from "../../components/NewUserGIftDialog"
import { loginRewards, loginCheckIn, getUserInfo } from '@/api/url.js'
const cardList = [
    {
        id: 1,
        title: "INVITE",
        text: [
            <p>🚀 Earn up to <span className="text-[#FFCA00]">100 USDT</span> by inviting</p>,
            <p>friends—withdrawable! 🎡 More prizes</p>,
            <p>with lucky wheel draws! 🎁</p>,
        ],
        img: invite,
        path: "/my-invites",
    },
    {
        id: 2,
        title: "AI CARDS",
        text: [

            <p>🔥 Refine & trade cards to earn <span className="text-[#FFCA00]">1M</span></p>,
            <p><span className="text-[#FFCA00]">$STAR</span>! 💫 Unlock more than <span className="text-[#FFCA00]">10 TON</span> </p>,
            <p>with card PK and NFT Minting! 🎉</p>,
        ],
        img: cards,
        path: `/cards?id=${2}`,
    },
    {
        id: 3,
        title: "AI  AGENTS",
        text: [

            <p>🌟 Chat daily for <span className="text-[#FFCA00]">20K $STAR</span>! 💸 Create </p>,
            <p>characters & earn endless income from</p>,
            <p>visits! 🌐</p>,
        ],
        img: avatar,
        path: `/agents?id=${1}`,
    }
]


const Home = () => {
    const [isSHowNewUserGiftDialog, setShowNewUserGiftDialog] = useState(false)
    const userInfo = useSelector((state: any) => state.tabbar.userInfo)
    const dispatch = useDispatch()
    const address_params = localStorage.getItem('address_params')
    const navigate = useNavigate();
    const [arrayData, setArrayData] = useState([])
    const isSoundPlay = useSelector((state: any) => state.settings.settings.sound)
    const AudioRef = useRef<any>()
    const boxRef = useRef<any>(null)
    const getRewards = async () => {
        try {
            const { code, data } = await loginRewards()
            if (code == '0' && data.tasks.length > 0) {
                setShowNewUserGiftDialog(true)
                setArrayData(data.tasks)
            }
        } catch {

        }
    }
    useEffect(() => {
        getRewards()
    }, [])
    const getCoinAnimation = (x: any, y: any) => {
        const R = 100; // 扩散半径
        const time = 0.25; //扩散过度时间
        const time2 = 1; // 收集过度时间
        const w = 26; // 金币大小
        const Num = 8 //金币数量
        setlits(x, y)



        if (AudioRef.current) {
            // AudioRef.current.currentTime = 0;
            AudioRef.current.play()
        }



        const total = document.getElementsByClassName('total_1')[0] // 获取金币最终飞向
        function setlits(X: any, Y: any) {
            var species = document.createElement('div');
            species.className = 'species'
            for (let i = 0; i < Num; i++) {
                var img = document.createElement('img');
                img.className = 'speciesstyle'
                img.style.width = w + 'px',
                    img.style.height = w + 'px',
                    img.style.left = X + 'px',
                    img.style.top = Y + 'px',
                    img.style.transform = 'translate(0%, 0%)',
                    img.style.transition = `${time}s`
                img.src = coin
                species.appendChild(img);
            }
            boxRef.current.appendChild(species)
            setTimeout(() => {
                clickss(X, Y)
            }, 0)
        }
        // 计算金币扩散范围和坐标
        function clickss(X: number, Y: number) {
            const specielist: any = document.getElementsByClassName('speciesstyle')
            const num = specielist.length
            const center = {
                x: X,
                y: Y
            }
            for (let i = 0; i < num; i++) {
                const xz = center.x + Math.floor(Math.random() * ((R * 1.1) - (R * 0.6) + 1)) + (0.6 * R) * Math.sin(2 * Math.PI * i / num)
                const yz = center.y + Math.floor(Math.random() * ((R * 1.1) - (R * 0.6) + 1)) + (0.6 * R) * Math.cos(2 * Math.PI * i / num)
                specielist[i].style.transform = `translate(${xz - (X + w / 2)}px, ${yz - (Y + w / 2)}px)`
            }
            setTimeout(() => {
                for (let i = 0; i < num; i++) {
                    specielist[i].style.transition = `${time2}s`
                    specielist[i].style.transform = `translate(0,0)`
                    specielist[i].style.left = Math.floor(total?.getBoundingClientRect().x) + 'px'
                    specielist[i].style.top = Math.floor(total?.getBoundingClientRect().y) + 'px'
                }
                // 结束清除蒙层
                setTimeout(() => {
                    remove()
                }, (Number(time2) + 0.5) * 500) //飞行时间结束

            }, (Number(time) + 0.5) * 500) //扩散时间结束
        }
        // 清除dom
        function remove() {
            document.getElementsByClassName('species')[0].remove()
        }
    }

    const goToPage = (path: string) => {
        navigate(path);
    }
    const closeDrawCard = (val: any) => {
        dispatch(changeUserInfo({ ...userInfo, card_rewards: [], coins: 0 }))
    }

    const setUserInfo = useCallback(async () => {
        const UserInfo = await getUserInfo(address_params)
        if (UserInfo.code == "0") {
            dispatch(changeUserInfo(UserInfo.data))
        }
    }, [])

    return (<div ref={boxRef} className="px-4 py-[32px] relative h-[calc(100vh)] pb-[120px] overflow-auto text-white flex flex-col justify-between" style={{ background: `url(${bg})`, backgroundSize: "100% 100%" }}>
        {
            cardList.map(item => {
                return (
                    <div key={item.id} className="home-card flex flex-col justify-center items-center" onClick={() => {
                        goToPage(item.path);
                        if (item.id == 3) {
                            sessionStorage.setItem('avatarListId', "1")
                        }
                    }}>
                        <div className="card-title text-xl text-white">{item.title}</div>
                        <div className="card-content w-full mt-2  relative">
                            {
                                item.id === 1 && (
                                    <>
                                        <img className="w-[64px] absolute left-10 -top-5" src={firework} alt="" />
                                        <img className="w-[64px] absolute right-10 top-5" src={firework} alt="" />
                                        <img className="w-[64px] absolute left-4 bottom-5" src={firework} alt="" />
                                        <img className="w-[64px] absolute right-28 bottom-2" src={firework} alt="" />
                                    </>

                                )
                            }

                            <img className="" src={item.img} alt="" />
                            <div className="text absolute text-lg top-[50%] -translate-y-[50%] text-shadows text-white  w-full text-center">
                                {
                                    item.text.map((text: any, index: any) => {
                                        return <div key={`${index}-textShadow`} className="text-white" style={{ textShadow: '2px 2px 10px rgba(0, 0, 0, 1)' }}>{text}</div>
                                    })
                                }
                            </div>

                            {/* <svg className="absolute top-2 right-[30px] animate-pulsate" width="43px" height="48px" viewBox="0 0 43 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                
                                <defs>
                                    <filter x="-48.0%" y="-40.0%" width="196.0%" height="180.0%" filterUnits="objectBoundingBox" id="filter-1">
                                        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                                        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                                        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                                        <feMerge>
                                            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                                            <feMergeNode in="SourceGraphic"></feMergeNode>
                                        </feMerge>
                                    </filter>
                                    <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-2">
                                        <stop stopColor="#FFFFFF" offset="0%"></stop>
                                        <stop stopColor="#FFFFFF" offset="100%"></stop>
                                    </linearGradient>
                                </defs>
                                <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g id="Home页" transform="translate(-315.000000, -199.000000)" fill="url(#linearGradient-2)" fillRule="nonzero">
                                        <g id="编组" filter="url(#filter-1)" transform="translate(336.500000, 223.000000) scale(-1, 1) translate(-336.500000, -223.000000) translate(324.000000, 208.000000)">
                                            <path d="M25,23.5749895 L25,6.42501046 L22.8750163,6.42501046 L22.8750163,23.5749895 L25,23.5749895 Z M20.7499983,23.5749895 L20.7499983,6.42501046 L16.487516,6.42501046 L16.487516,23.5749895 L20.7499983,23.5749895 Z M0,15 L10.1124963,30 L10.1124963,23.5749895 L14.362498,23.5749895 L14.362498,6.42501046 L10.1124963,6.42501046 L10.1124963,0 L0,15 Z" id="形状"></path>
                                        </g>
                                    </g>
                                </g>
                            </svg> */}
                            <img className={`w-9 absolute bottom-2 animate-pulsate left-[50%] -translate-x-[50%]`} src={finger} alt="" />
                        </div>

                    </div>
                )
            })
        }
        {/* <DrawCardsDialog
            dialog={true}
            setDialog={closeDrawCard}
            description={'34567890'}
            coins={34567890}
            cardLevel={3}
            imgUrl={''}
            setCardUrl={() => { }}
        ></DrawCardsDialog> */}
        {Array.isArray(userInfo.card_rewards) && (userInfo.card_rewards.length > 0 || !!userInfo?.coins) && <>
            {(userInfo.card_rewards.length > 0 ? userInfo.card_rewards[0]?.is_card : !!userInfo?.coins) ?
                <DrawCardsDialog
                    getCoinAnimation={getCoinAnimation}
                    coins={userInfo?.coins}
                    property={userInfo.card_rewards[0].property}
                    power={userInfo.card_rewards[0].power}
                    dialog={userInfo.card_rewards.length > 0 ? userInfo.card_rewards[0]?.is_card : userInfo?.coins}
                    setDialog={closeDrawCard}
                    description={userInfo.card_rewards.length > 0 ? userInfo.card_rewards[0]?.is_card : ''}
                    cardLevel={userInfo.card_rewards.length > 0 ? userInfo.card_rewards[0]?.is_card : ''}
                    imgUrl={userInfo.card_rewards.length > 0 ? userInfo.card_rewards[0]?.is_card : ''}
                    setCardUrl={() => { }}
                ></DrawCardsDialog> :
                <NFTDrawCardsDialog></NFTDrawCardsDialog>
            }
        </>}
        {
            isSoundPlay && <audio ref={AudioRef} src={coinsMusic} id="audio"></audio>
        }

        {
            isSHowNewUserGiftDialog && <NewUserGift arrayData={arrayData} closeHandle={async () => {
                setShowNewUserGiftDialog(false)
                const width = window.innerWidth;
                const height = window.innerHeight;
                await loginCheckIn()
                getCoinAnimation(width / 2, height / 2)
                setUserInfo()

            }}></NewUserGift>
        }
    </div>
    )
}

export default Home
