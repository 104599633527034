import './edit.less'
import Crop from 'xcrop'
import audioIcon from '../../../assets/images/audio_icon_1.png';
import audioIcon1 from '../../../assets/images/audio_icon_2.png';
import audioIcon2 from '../../../assets/images/audio_icon_3.png';
import { Input, Switch, Toast, Uploader } from "react-vant"
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import Back from "../../../components/common/BackComp"
import SelectComp from "../../../components/common/SelectComp"
import ButtonComp from "../../../components/common/ButtonComp"
import DigitalInputComp from "../../../components/common/DigitalInputComp"
import deleteImg from "../../../assets/images/delete.svg"
import { isIOS } from '../../../utils/judgeClient'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PromptDialog from '../../../components/PromptDialog'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import TalkMessage from '../../../components/message/TalkMessageToast'
import PublicDialog from '../../../components/PublicDialog'
import { getAvatarDetails, getAvatarImage, getUserInfo, putAvatarDetails, getVoice } from '../../../api/url.js'
import { changeUserInfo } from '../../../store/module/tabbar.js'
import { changeAvatarAudio } from '../../../store/module/progress.js';


const Edit = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [createData] = useState<any>(useSelector((state: any) => state.createAvatar.createData))
  const [active, setActive] = useState<any>(0)
  const [valueSlider, setValueSlider] = useState('');
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [textMessage, setTextMessage] = useState("");
  const dispath = useDispatch()
  const [isMessage, setIsMessage] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const address_params = localStorage.getItem('address_params')
  const [gender, setGender] = useState(createData.gender || 'woman')
  const [inputFocus, setInputFocus] = useState<any>(false)
  const [dialog, setDialog] = useState<any>(false)
  const [payDialog, setPayDialog] = useState<any>(false)
  const [voice, setVoice] = useState<any>(createData.voice)
  const [voiceList, setVoiceList] = useState<any>([])
  const [isNsfw, setIsNsfw] = useState(createData.is_nsfw);
  const [nameText, setNameText] = useState(createData.name)
  const [avatarTypesValue, setAvatarTypesValue] = useState(1)
  const [initType, setInitType] = useState(false)
  const [imgFile, setImgFile] = useState('')
  const [activeArr, setActiveArr] = useState<any>(createData.images)
  const [tags, setTags] = useState<any>(createData.tags)
  const [backgroundPlaceholder, setBackgroundPlaceholder] = useState(createData.prompt)
  const [avatarPlaceholder, setAvatarPlaceholder] = useState(createData.opening_statements)
  const [dialogueExampleList, setDialogueExampleList] = useState([{ question: '', answer: '' }])
  const [introductionPlaceholder, setIntroductionPlaceholder] = useState(createData.introduction)

  const [id] = useState(searchParams.get("id"))
  const [type] = useState(searchParams.get("type") || '')
  const [mintState, setMintState] = useState(0)
  useEffect(() => {
    getEditAvatar()
  }, [])

  const getEditAvatar = async () => {
    if (type == 'edit') {
      const { data, code } = await getAvatarDetails(id)
      if (code == '0') {
        setGender(data.gender == 1 ? 'male' : data.gender == 2 ? 'female' : 'gender neutral')
        setNameText(data.name)
        setMintState(data.mint_state)
        setIntroductionPlaceholder(data.introduction)
        setBackgroundPlaceholder(data.prompt)
        setAvatarPlaceholder(data.opening_statements)
        setActiveArr(data.main_images)
        setImgFile(data.image)
        setIsNsfw(data.is_nsfw)
        setTags(data.tags)
        setAvatarTypesValue(data.avatar_type)
        setValueSlider(data.price.toString())
        let arr: any = []
        if (data.chat_example) {
          data.chat_example.forEach((element: any) => {
            arr.push({ question: element[0], answer: element[1] })
          });
        } else {
          arr.push({ question: '', answer: '' })
        }
        setVoice(data.voice)
        setDialogueExampleList(arr)
      }
    }
  }
  const genderItem = [
    {
      value: 'female',
      text: 'Woman',
    },
    {
      value: 'male',
      text: 'Male',
    },
    {
      value: 'gender neutral',
      text: 'Non-binary',
    },
  ]
  const avatarTypesItem = [
    {
      value: 1,
      text: 'Public',
    },
    {
      value: 2,
      text: 'Commercial',
    },
    {
      value: 3,
      text: 'Private',
    },
  ]

  const options = {}
  const crop = new Crop(options)
  crop.options.circle = true
  crop.setBorder({ width: 300, height: 300 })
  crop.show()
  crop.on('cancel', function (crop: any) {
    crop.hide()
  })
    .on('confirm', function (crop: any) {
      setImgFile(crop.get())
      crop.hide()
    })
  const cropImg = new Crop(options)
  cropImg.setBorder({ width: 512, height: 768 })
  cropImg.show()
  cropImg.on('cancel', function (crop: any) {
    crop.hide()
  })
    .on('confirm', function (crop: any) {
      setActiveArr([...activeArr, crop.get()])
      crop.hide()
    })

  function buttonClick() {
    if (mintState == 3) return
    if (active || active === 0) {
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
      });
      crop.load(activeArr[active])
    }
  }

  const addDialogueExampleList = () => {
    setDialogueExampleList([...dialogueExampleList, {
      question: '',
      answer: ''
    },])
  }
  function handleFocus() {
    setInputFocus(true)
  }
  function handleBlur() {
    setInputFocus(false)
  }
  async function handleVoice() {
    const { code, data } = await getVoice()
    if (code == '0') {
      setVoiceList(data)
    }
  }
  useEffect(() => {
    handleVoice()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setIsMessage(false)
    }, 3000)
  }, [isMessage])

  const checks = useMemo(() => {
    return [
      { condition: !nameText, message: `Please set the Agent‘s name.` },
      { condition: !introductionPlaceholder, message: `Please set the Agent‘s introduction.` },
      { condition: !backgroundPlaceholder, message: `Please set the Agent‘s background story.` },
      { condition: !imgFile, message: `Please set the Agent‘s profile picture.` },
      { condition: !voice, message: `Please set the Agent‘s voice.` },
      { condition: avatarTypesValue === 2 && (valueSlider === "0" || !valueSlider), message: `Please set the Agent‘s one-time fee. ` },
      { condition: !avatarPlaceholder, message: `Please set the Agent‘s opening line. ` },

    ]
  }, [imgFile, avatarPlaceholder, avatarTypesValue, nameText, introductionPlaceholder, backgroundPlaceholder, voice, valueSlider])


  const createFun = async () => {
    const token = window.localStorage.getItem("token");

    const hasError = checks.some(check => check.condition);
    if (hasError) {
      const errorCheck: any = checks.find(check => check.condition);
      setTextMessage(errorCheck.message);
      setIsMessage(true);
      return;
    }

    const toast = Toast.loading({
      message: 'Loading...',
      forbidClick: true,
      duration: 0
    });
    const params = {
      session_id: createData.session_id,
      name: nameText,
      gender: gender,
      introduction: introductionPlaceholder,
      avatar_image: imgFile.replace('data:image/jpeg;base64,', ''),
      opening_statements: avatarPlaceholder,
      chat_example: dialogueExampleList,
      images: activeArr,
      avatar_type: avatarTypesValue,
      price: Number(valueSlider),
      voice: voice,
      is_nsfw: isNsfw,
      tags: tags,
      state: 3,
      prompt: backgroundPlaceholder,
    }
    fetchEventSource(`${import.meta.env.VITE_CHARACTER_API}/api/v1/miniapp/character`, {
      // fetchEventSource(`/api/v1/miniapp/character`, {
      openWhenHidden: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(params),
      async onmessage(res) {
        if (res.data.indexOf("code") !== -1) {
          if (JSON.parse(res.data).code == '0') {
            setDialogSuccess(true)
            toast.clear()
          }
        } else {
          setTitle('Attention')
          setText(JSON.parse(res.data).msg)
          setDialog(true)
          toast.clear()
        }
      },
      onerror(err: any) {
        toast.clear()
        throw err;
      }
      , onclose() {
        toast.clear()
      },
    }).catch((e: any) => {
      console.log(e)
      toast.clear()
    })
    upUserInfo()
  }

  const dialogContent = () => {
    return (
      <div className=' flex justify-center items-center pt-[42px] flex-col'>
        <div className=' flex justify-center items-center flex-col'>
          Created Agent Successfully!🎉
          <img src={activeArr[active]} className='w-40 rounded-[10px] mt-4 mb-5' alt="" />
        </div>
        <ButtonComp title="OK" width={174} mt={0} height={36} callBack={() => { navigate(`/agents?id=${3}`), setDialogSuccess(false) }} fontSize={14}></ButtonComp>
      </div>
    )
  }

  const regenerate = async () => {
    const toast = Toast.loading({
      message: 'Loading...',
      forbidClick: true,
      duration: 0
    });
    try {
      const { data, code, msg } = await getAvatarImage(id)
      if (code == '0') {
        setActiveArr(data)
        setImgFile(data.image)
      } else {
        setDialog(true)
        setTitle('Attention')
        setText(msg)
      }
    } catch {

    }
    toast.clear()
    upUserInfo()
  }

  const putAvatar = async () => {

    const hasError = checks.some(check => check.condition);
    if (hasError) {
      const errorCheck: any = checks.find(check => check.condition);
      setTextMessage(errorCheck.message);
      setIsMessage(true);
      return;
    }

    const toast = Toast.loading({
      message: 'Loading...',
      forbidClick: true,
      duration: 0
    });
    const params = {
      session_id: id,
      name: nameText,
      gender: gender,
      introduction: introductionPlaceholder,
      avatar_image: imgFile.replace('data:image/jpeg;base64,', ''),
      opening_statements: avatarPlaceholder,
      chat_example: dialogueExampleList,
      images: activeArr.map((el: any) => {
        return el.replace('data:image/jpeg;base64,', '')
      }),
      avatar_type: avatarTypesValue,
      price: Number(valueSlider),
      voice: voice,
      is_nsfw: isNsfw,
      tags: tags,
      first_image_index: active,
      prompt: backgroundPlaceholder,
    }
    try {
      const { code, msg } = await putAvatarDetails(id, params)
      if (code == '0') {
        setDialog(true)
        setTitle('Attention')
        setText(msg)
        navigate(`/agents?id=${3}`)
      } else {
        setDialog(true)
        setTitle('Attention')
        setText(msg)
      }
    } catch {
      navigate(`/agents?id=${3}`)
    }
    toast.clear()
  }

  const upUserInfo = useCallback(async () => {
    const UserInfo = await getUserInfo(address_params)
    dispath(changeUserInfo(UserInfo.data))
  }, [])

  // const base64ToFile = (base64, fileName) => {
  //   let data = base64.split(",");
  //   let type = data[0].match(/:(.*?);/)[1];
  //   let suffix = type.split("/")[1];
  //   const bstr = window.atob(data[1]);
  //   let n = bstr.length;
  //   const u8arr = new Uint8Array(n);
  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }
  //   // 利用构造函数创建File文件对象
  //   const file = new File([u8arr], `${fileName}.${suffix}`, {
  //     type: type,
  //   });
  //   return file;
  // };
  const back = () => {
    if (type == 'edit') {
      navigate(`/agents?id=${3}`)
    } else {
      setDialog(true)
      setTitle('Warning')
      setText('If you leave the page, the data will be lost. Do you want to continue?')
    }
  }


  // 实际业务中需要自己实现对应上传逻辑
  const upload = async (file: File) => {
    if (mintState == 3) return
    try {
      let base64 = ''
      if (file) {
        // 创建FileReader对象
        const reader = new FileReader();

        reader.onload = function (e: any) {
          // 读取文件并转换为Base64
          const content = e.target.result;

          // 使用btoa()转换Base64
          base64 = btoa(content);
          cropImg.load(atob(base64))
          // 输出或处理Base64字符串

        };

        // 以DataURL的形式读取文件
        reader.readAsDataURL(file);
      }
      return ''
    } catch (error) {
      return ''
    }
  }

  const tagsChange = (e: any, index: number) => {
    let newArr: any = []
    tags.forEach((el: any, ind: number) => {
      if (ind == index) {
        newArr.push(e.target.value.toUpperCase())
      } else {
        newArr.push(el)
      }
    })
    setTags(newArr)
  }

  const isAudioActive = useRef(-1)

  useLayoutEffect(() => {
    const audioList = document.querySelectorAll(".audio-style")
    for (let i = 0; i < audioList.length; i++) {
      audioList[i].addEventListener('click', () => {
        if (isAudioActive.current !== -1) {
          audioList[isAudioActive.current].children[1].children[1].classList.remove("voice_1")
          audioList[isAudioActive.current].children[1].children[2].classList.remove("voice_2")
          audioList[isAudioActive.current].children[1].children[3].classList.remove("voice_3")
          audioList[isAudioActive.current]?.children[2].pause()
          audioList[isAudioActive.current].classList.remove("active")
        }

        isAudioActive.current = i
        const uuid = audioList[isAudioActive.current].getAttribute("id") as string
        setVoice(uuid)
        audioList[i].classList.add("active")
        audioList[i].children[2].currentTime = 0;
        audioList[i].children[2].play()
        audioList[i].children[1].children[1].classList.add("voice_1")
        audioList[i].children[1].children[2].classList.add("voice_2")
        audioList[i].children[1].children[3].classList.add("voice_3")
        audioList[i].children[2].addEventListener("ended", () => {
          audioList[i].children[1].children[1].classList.remove("voice_1")
          audioList[i].children[1].children[2].classList.remove("voice_2")
          audioList[i].children[1].children[3].classList.remove("voice_3")
        })

      })
    }
  })




  const addTags = () => {
    if (tags && tags?.length) {
      setTags([...tags, ''])
    } else {
      setTags([''])
    }

  }
  return (
    <div style={{ paddingBottom: `${isIOS() && inputFocus ? '360px' : ''}` }} className="editBox pt-[64px] pb-6 h-[100vh] overflow-auto">
      <Back title={'Edit Agent'} callback={back} position={true} background="#050A0A"></Back>
      <div className="px-4">
        <div>
          <p className={`text-[16px] leading-[19px]  font-bold ${mintState == 3 ? 'text-[#a6a6a6]' : 'text-[#fff]'}`}> Name </p>
          <input readOnly={mintState == 3 ? true : false} onFocus={handleFocus} onBlur={handleBlur} placeholder={nameText} value={nameText}
            onChange={(el: any) => {
              if (el.target.value.length >= 20) {
                setNameText(nameText)
              } else {
                setNameText(el.target.value)
              }
            }}
            className={` rounded-[10px] p-[10px] text-[14px] bg-[#333333] mt-[10px] ${mintState == 3 ? 'text-[#a6a6a6]' : 'text-[#fff]'}  w-full h-10`} type="text" />
        </div>
        <div className=" pt-[15px]">
          <p className={`text-[16px] pb-[10px] leading-[19px] ${mintState == 3 ? 'text-[#A6a6a6]' : 'text-[#fff]'}  font-bold`}> Gender </p>
          <div className="relative w-full h-10">
            <SelectComp readOnly={mintState == 3 ? true : false} selectItem={genderItem} value={gender || 'woman'} setValue={setGender}></SelectComp>
          </div>
        </div>
        <div className="mt-[15px]">
          <p className=" text-[16px] leading-[19px] text-[#fff] font-bold"> Introduction </p>
          <Input.TextArea
            onFocus={handleFocus} onBlur={handleBlur}
            className="rounded-[10px] p-[10px] edit_avatars text-[14px] bg-[#333333] mt-[10px] !text-[#fff] w-full"
            placeholder={introductionPlaceholder}
            value={introductionPlaceholder}
            onChange={(e) => {
              setIntroductionPlaceholder(e)
            }}
            autoSize
          />
        </div>
        <div className="mt-[15px]">
          <p className=" text-[16px] leading-[19px] text-[#fff] font-bold"> Background Story </p>
          <Input.TextArea
            onFocus={handleFocus} onBlur={handleBlur}
            className="rounded-[10px] p-[10px] edit_avatars text-[14px] bg-[#333333] mt-[10px] !text-[#fff] w-full"
            placeholder={backgroundPlaceholder}
            value={backgroundPlaceholder}
            onChange={(e) => {
              setBackgroundPlaceholder(e)
            }}
            autoSize />
        </div>
        <div className="mt-[15px]">
          <p className={`text-[16px] leading-[19px] font-bold ${mintState == 3 ? 'text-[#a6a6a6]' : 'text-[#fff]'}`}> Image </p>
          <div className=" bg-[#333333] rounded-[10px] mt-[10px] p-2 f-full">
            <div className="flex gap-2 items-center w-full">
              <div className="flex gap-[6px] w-[calc(100%_-_68px)]">
                {activeArr.map((el: any, index: number) =>
                  <div onClick={() => {
                    if (mintState == 3) return
                    setActive(index)

                  }} key={`${index}_Image`}
                    className="rounded-[10px] relative box-border w-[25%] ">
                    <img src={el} className="w-full rounded-[10px]" alt="" />
                    {activeArr.length > 1 && mintState != 3 && <img onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      if (mintState == 3) return
                      if (index < active) {
                        setActive(active - 1)
                      } else if (index == active) {
                        setActive(0)
                      }
                      setActiveArr(activeArr.filter((el: any, ind: any) => ind !== index))

                    }} className="absolute z-20 top-[2px] right-[2px]" src={deleteImg} alt="" />}
                    <div style={{ border: `${active === index ? '2px solid #00E49F' : ''}` }} className="top-0 left-0 rounded-[10px] absolute w-full h-full"></div>
                  </div>
                )}
                {activeArr.length < 4 && type == 'edit' &&
                  <div style={{ height: 'inherit' }} className='w-[25%] min-h-[90px] flex items-center justify-center bg-[#444444] rounded-[10px] '>
                    {mintState != 3 && <Uploader
                      upload={upload}
                      previewImage={false}
                      previewSize={60} // 自定义视图尺寸
                      maxSize={5 * 1024 * 1024}
                      onOversize={() => {
                        setTextMessage(`Upload images with a maximum size of 5Mb.`)
                        setIsMessage(true)
                      }}
                      uploadIcon={
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                          <defs>
                            <linearGradient x1="-2.72351586e-14%" y1="49.6527778%" x2="100%" y2="50.3472222%" id="linearGradient-1">
                              <stop stopColor="#00E49F" offset="0%"></stop>
                              <stop stopColor="#54ADFF" offset="100%"></stop>
                            </linearGradient>
                            <linearGradient x1="49.6527778%" y1="-1.94289029e-13%" x2="50.3472222%" y2="100%" id="linearGradient-2">
                              <stop stopColor="#00E49F" offset="0%"></stop>
                              <stop stopColor="#54ADFF" offset="100%"></stop>
                            </linearGradient>
                          </defs>
                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g transform="translate(-240.000000, -667.000000)" fillRule="nonzero">
                              <g transform="translate(240.000000, 667.000000)">
                                <path d="M22.8313043,13 L1.16869567,13 C0.525913044,13 0,12.55 0,12 C0,11.45 0.525913044,11 1.16869567,11 L22.8313043,11 C23.474087,11 24,11.45 24,12 C24,12.55 23.474087,13 22.8313043,13 Z" id="路径" fill="url(#linearGradient-1)"></path>
                                <path d="M11,22.8313044 L11,1.16869564 C11,0.525913018 11.45,0 12,0 C12.55,0 13,0.525913045 13,1.16869564 L13,22.8313044 C13,23.474087 12.55,24 12,24 C11.45,24 11,23.474087 11,22.8313044 L11,22.8313044 Z" id="路径" fill="url(#linearGradient-2)"></path>
                              </g>
                            </g>
                          </g>
                        </svg>
                      } // 自定义上传图标
                    />}
                  </div>}
              </div>
              <div onClick={buttonClick} className="w-[60px] h-[60px] rounded-full">
                {imgFile ? <img src={imgFile} className=" w-[60px] rounded-full h-[60px]" alt="" /> :
                  <div className='w-[60px] rounded-full flex justify-center items-center relative bg-[#444] h-[60px] '>
                    <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink">
                      <defs>
                        <linearGradient x1="-2.72351586e-14%" y1="-1.94289029e-13%" x2="100%" y2="100%" id="linearGradient-1">
                          <stop stopColor="#00E49F" offset="0%"></stop>
                          <stop stopColor="#54ADFF" offset="100%"></stop>
                        </linearGradient>
                      </defs>
                      <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="创建数字人确认" transform="translate(-305.000000, -664.000000)" fill="url(#linearGradient-1)"
                          fillRule="nonzero">
                          <g id="编组" transform="translate(305.000000, 664.000000)">
                            <path
                              d="M24.375,21.328125 L24.375,7.33886719 C24.375,7.08984375 24.4746094,6.85253906 24.6503906,6.67675781 L29.3261719,2.00097656 C29.6923828,1.63476562 29.6923828,1.04003906 29.3261719,0.673828125 C28.9599609,0.307617188 28.3652344,0.307617188 27.9990234,0.673828125 L23.3232422,5.34960938 C23.1474609,5.52539062 22.9101562,5.625 22.6611328,5.625 L8.671875,5.625 C8.54296875,5.625 8.4375,5.51953125 8.4375,5.390625 L8.4375,0.234375 C8.4375,0.10546875 8.33203125,0 8.203125,0 L5.859375,0 C5.73046875,0 5.625,0.10546875 5.625,0.234375 L5.625,5.390625 C5.625,5.51953125 5.51953125,5.625 5.390625,5.625 L0.234375,5.625 C0.10546875,5.625 0,5.73046875 0,5.859375 L0,8.203125 C0,8.33203125 0.10546875,8.4375 0.234375,8.4375 L5.390625,8.4375 C5.51953125,8.4375 5.625,8.54296875 5.625,8.671875 L5.625,22.5 C5.625,23.5341797 6.46582031,24.375 7.5,24.375 L21.328125,24.375 C21.4570312,24.375 21.5625,24.4804688 21.5625,24.609375 L21.5625,29.765625 C21.5625,29.8945312 21.6679688,30 21.796875,30 L24.140625,30 C24.2695312,30 24.375,29.8945312 24.375,29.765625 L24.375,24.609375 C24.375,24.4804688 24.4804688,24.375 24.609375,24.375 L29.765625,24.375 C29.8945312,24.375 30,24.2695312 30,24.140625 L30,21.796875 C30,21.6679688 29.8945312,21.5625 29.765625,21.5625 L24.609375,21.5625 C24.4804688,21.5625 24.375,21.4570312 24.375,21.328125 Z M8.671875,8.4375 L19.6699219,8.4375 C19.8779297,8.4375 19.9833984,8.68945312 19.8369141,8.83886719 L8.83886719,19.8369141 C8.69238281,19.9833984 8.4375,19.8808594 8.4375,19.6699219 L8.4375,8.671875 C8.4375,8.54296875 8.54296875,8.4375 8.671875,8.4375 Z M21.328125,21.5625 L10.3300781,21.5625 C10.1220703,21.5625 10.0166016,21.3105469 10.1630859,21.1611328 L21.1611328,10.1630859 C21.3076172,10.0166016 21.5625,10.1191406 21.5625,10.3300781 L21.5625,21.328125 C21.5625,21.4570312 21.4570312,21.5625 21.328125,21.5625 Z"
                              id="形状"></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>}
              </div>
            </div>
            {type == 'edit' && <div className="mt-[10px] flex items-center justify-center flex-col">
              <ButtonComp disabled={mintState == 3} title="Regenerate" children={
                <svg className="mr-1" width="13px" height="12px" viewBox="0 0 13 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-144.000000, -740.000000)" fill="#FFFFFF" fillRule="nonzero">
                      <g transform="translate(134.000000, 734.000000)">
                        <g transform="translate(10.000000, 6.000000)">
                          <path d="M2.1425456,4.79179726 C1.75183847,6.29717158 2.11382642,7.90488377 3.10737616,9.07693947 C4.1009259,10.2489952 5.59480096,10.8305793 7.08910068,10.6270744 C7.43922145,10.5843441 7.75757342,10.8404528 7.80364427,11.2019123 C7.84971512,11.5633718 7.60640732,11.8960395 7.25753793,11.9485878 C5.33662303,12.2101586 3.41624628,11.4626788 2.13883289,9.95620336 C0.861419501,8.44972795 0.395640413,6.38317346 0.897313089,4.44786606 L0.128173617,4.23457539 C0.0702502302,4.21882624 0.0244314612,4.17303094 0.00723772445,4.11370147 C-0.00995601234,4.054372 0.00395499475,3.99006495 0.0439549933,3.94396686 L2.18121741,1.45202093 C2.22430565,1.40143322 2.29135966,1.38040797 2.35435915,1.39773114 C2.41735865,1.41505431 2.4654592,1.46774397 2.47856072,1.53378235 L3.12395037,4.79801823 C3.13570472,4.85819288 3.11659985,4.9203804 3.07341752,4.96250575 C3.03023519,5.00463111 2.96908928,5.02073008 2.91168507,5.00508792 L2.1425456,4.79179726 Z M10.8574544,7.20820274 C11.2481615,5.70282842 10.8861736,4.09511623 9.89262384,2.92306053 C8.8990741,1.75100484 7.40519904,1.1694207 5.91089932,1.37292564 C5.68097075,1.40755848 5.45081428,1.31152804 5.30850437,1.12158249 C5.16619447,0.93163694 5.13376217,0.677181811 5.22361841,0.45558999 C5.31347465,0.23399817 5.51170876,0.079574354 5.74246207,0.0514122343 C7.66337697,-0.210158591 9.58375372,0.537321221 10.8611671,2.04379664 C12.1385805,3.55027205 12.6043596,5.61682654 12.1026869,7.55213394 L12.8718264,7.76542461 C12.9297498,7.78117376 12.9755685,7.82696906 12.9927623,7.88629853 C13.009956,7.945628 12.996045,8.00993505 12.956045,8.05603314 L10.8187826,10.5479791 C10.7756943,10.5985668 10.7086403,10.619592 10.6456408,10.6022689 C10.5826413,10.5849457 10.5345408,10.532256 10.5214393,10.4662176 L9.87604963,7.20198177 C9.86429528,7.14180712 9.88340015,7.0796196 9.92658248,7.03749425 C9.96976481,6.99536889 10.0309107,6.97926992 10.0883149,6.99491208 L10.8574544,7.20820274 Z" id="形状"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              } width={107} callBack={
                () => {
                  setTitle('Attention')
                  setText('Regenerate images will cost 300,000 $STAR.')
                  setPayDialog(true)
                }
              } height={24} fontSize={14}></ButtonComp>
              <p className=" font-semibold text-[12px] leading-[15px] text-[#a6a6a6] mt-1">Regenerate images will cost 300,000 $STAR.</p>
            </div>}
          </div>
        </div>
        {type == 'edit' && <div className="mt-[15px]">
          <p className=" text-[16px] leading-[19px] text-[#fff] font-bold"> Voice </p>
          <div className='flex flex-wrap gap-[6px] mt-[10px] items-center'>
            {voiceList.map((el: any, index: number) =>
              <div className={`audio-style !w-fit m-0 p-[3px_6px] ${el.uuid == voice ? 'active' : ''}`} key={el.uuid} id={el.uuid}>
                <span>Voice{index + 1}</span>
                <div className="audio-icon">
                  <img src={audioIcon} />
                  <div style={{ width: '2px', height: '2px', background: '#fff' }}> </div>
                  <img style={{ width: '3px', marginLeft: '0px' }} src={audioIcon1} />
                  <img style={{ width: '6px', marginLeft: '-2px' }} src={audioIcon2} />
                </div>
                <audio src={el.tg_url} hidden={true}></audio>
              </div>
            )}
          </div>
        </div>}
        <div className="mt-[15px]">
          <p className=" text-[16px] leading-[19px] text-[#fff] font-bold"> Tags </p>
          <div className='flex justify-start mt-[10px] flex-wrap w-[calc(100vw_-_32px)] overflow-hidden gap-[10px]'>
            {Array.isArray(tags) && tags.map((el: string, index: number) =>
              <div key={`${el}-${index}`} className='w-fit flex text-[14px] leading-[18px] text-[#ffffff] font-semibold items-center pr-[10px] gap-[10px] bg-[#333333] rounded'>
                {/* {el} */}
                <div className='relative h-[36px] min-w-5'>
                  <span className='min-w-5 ml-[10px] opacity-0'>{el}</span>
                  <input
                    style={{ width: '-webkit-fill-available', }}
                    onFocus={
                      () => {
                        handleFocus()
                        setInitType(true)
                      }
                    }
                    autoFocus={initType ? true : false}
                    onBlur={
                      () => {
                        handleBlur()
                        setTags(tags.filter((el: any, ind) => el))
                      }}
                    className="top-[10px] z-0 h-[14px] left-[10px] absolute text-[14px] bg-transparent !text-[#fff]"
                    value={el}
                    onChange={(e: any) => { tagsChange(e, index) }} />
                </div>
                <svg className=' relative z-10' onClick={() => {
                  setTags(tags.filter((el: any, ind) => ind != index))
                }} width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-78.000000, -840.000000)" fill="#FFFFFF" fillRule="nonzero">
                      <g transform="translate(78.000000, 840.000000)">
                        <path d="M14.8184489,8.23743687 L-0.818448926,8.23743687 C-1.28243396,8.23743687 -1.66205807,7.68059028 -1.66205807,6.99999999 C-1.66205807,6.3194097 -1.28243396,5.76256313 -0.818448926,5.76256313 L14.8184489,5.76256313 C15.282434,5.76256313 15.6620581,6.31940972 15.6620581,6.99999999 C15.6620581,7.68059025 15.282434,8.23743687 14.8184489,8.23743687 Z" id="路径" transform="translate(7.000000, 7.000000) rotate(-315.000000) translate(-7.000000, -7.000000) "></path>
                        <path d="M5.76256313,14.8184489 L5.76256313,-0.818448944 C5.76256313,-1.28243398 6.31940972,-1.66205807 6.99999999,-1.66205807 C7.68059025,-1.66205807 8.23743687,-1.28243396 8.23743687,-0.818448944 L8.23743687,14.8184489 C8.23743687,15.282434 7.68059028,15.6620581 6.99999999,15.6620581 C6.3194097,15.6620581 5.76256313,15.282434 5.76256313,14.8184489 L5.76256313,14.8184489 Z" id="路径" transform="translate(7.000000, 7.000000) rotate(-315.000000) translate(-7.000000, -7.000000) "></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            )}
            <div onClick={addTags} className=' z-10 relative  w-fit flex items-center gap-[10px] p-[10px] bg-[#333333] rounded'>
              <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs>
                  <linearGradient x1="-2.72351586e-14%" y1="48.9795918%" x2="100%" y2="51.0204082%" id="linearGradient-1">
                    <stop stopColor="#00E49F" offset="0%"></stop>
                    <stop stopColor="#54ADFF" offset="100%"></stop>
                  </linearGradient>
                  <linearGradient x1="48.9795918%" y1="-1.94289029e-13%" x2="51.0204082%" y2="100%" id="linearGradient-2">
                    <stop stopColor="#00E49F" offset="0%"></stop>
                    <stop stopColor="#54ADFF" offset="100%"></stop>
                  </linearGradient>
                </defs>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g transform="translate(-122.000000, -840.000000)" fillRule="nonzero">
                    <g transform="translate(112.000000, 829.000000)">
                      <g transform="translate(10.000000, 11.000000)">
                        <path d="M13.3182609,8 L0.681739139,8 C0.306782609,8 0,7.55 0,7 C0,6.44999998 0.306782609,6 0.681739139,6 L13.3182609,6 C13.6932174,6 14,6.45 14,7 C14,7.54999998 13.6932174,8 13.3182609,8 Z" id="路径" fill="url(#linearGradient-1)"></path>
                        <path d="M6,13.3182609 L6,0.681739124 C6,0.306782594 6.45,0 7,0 C7.54999998,0 8,0.30678261 8,0.681739124 L8,13.3182609 C8,13.6932174 7.55,14 7,14 C6.44999998,14 6,13.6932174 6,13.3182609 L6,13.3182609 Z" id="路径" fill="url(#linearGradient-2)"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <span className=' text-style-1 text-[14px] leading-[18px] font-semibold '>New Tag</span>
            </div>
          </div>
        </div>

        <div className="mt-[15px]">
          <p className=" text-[16px] leading-[19px] text-[#fff] font-bold"> Agent’s Opening Line </p>
          <Input.TextArea
            onFocus={handleFocus} onBlur={handleBlur}
            className="rounded-[10px] p-[10px] edit_avatars text-[14px] bg-[#333333] mt-[10px] !text-[#fff] w-full"
            placeholder={avatarPlaceholder}
            value={avatarPlaceholder}
            onChange={(e) => {
              setAvatarPlaceholder(e)
            }}
            autoSize />
        </div>

        <div className="mt-[15px]">
          <p className=" text-[16px] leading-[19px] text-[#fff] font-bold"> Dialogue Examples </p>
          <div className=" bg-[#333333] rounded-[10px] mt-[10px] p-[10px] pt-0 f-full">
            {dialogueExampleList.map((el: any, index: number) =>
              <div key={`${index} Dialogue Examples`}>
                <div className=" pt-[10px] w-full flex justify-end ">
                  <div className='relative w-fit'>
                    <div className=' opacity-0 p-[10px] !max-w-[270px] leading-[18px] text-[14px]'>
                      {el.question ? el.question.split('\n').map((line: any, index: number) => <p style={{ overflowWrap: 'break-word' }} key={`${index}-${line}`} className='!max-w-[270px] text-wrap'>{line}<br /></p>) : 'Click to add user queries.'}
                    </div>
                    <Input.TextArea onFocus={handleFocus} onBlur={handleBlur}
                      className="bg-[#225255] questionExample p-[10px] absolute leading-[18px] top-0 max-w-[270px] text-[14px]" value={el.question}
                      onChange={(e) => {
                        setDialogueExampleList(
                          dialogueExampleList.map((item: any, int: number) => {
                            if (int === index) {
                              const text = e.replace(/(?:\r\n|\r|\n){2,}/g, '\n')
                              return { ...item, question: text }
                            } else {
                              return item
                            }
                          }
                          ))
                      }}
                      rows={1}
                      autoSize
                      placeholder="Click to add user queries." />
                  </div>
                </div>
                <div className=" pt-[10px] w-full flex justify-start ">
                  <div className='relative w-fit'>
                    <div className=' opacity-0 p-[10px] max-w-[270px] leading-[18px] text-[14px]'>{el.answer ? el.answer.split('\n').map((line: any) => <p style={{ overflowWrap: 'break-word' }} className='!max-w-[270px] text-wrap' key={index}>{line}<br /></p>) : 'Click to add AI responses.'}</div>
                    <Input.TextArea onFocus={handleFocus} onBlur={handleBlur}
                      className="answerExample p-[10px] absolute leading-[18px] top-0 max-w-[270px] text-[14px]" value={el.answer}
                      onChange={(e) => {
                        setDialogueExampleList(
                          dialogueExampleList.map((item: any, int: number) => {
                            if (int === index) {
                              const text = e.replace(/(?:\r\n|\r|\n){2,}/g, '\n')
                              return { ...item, answer: text }
                            } else {
                              return item
                            }
                          }
                          ))
                      }}
                      rows={1}
                      autoSize
                      placeholder="Click to add user queries." />
                  </div>
                </div>
              </div>
            )}
            <div className="flex w-full flex-col items-center justify-center" >
              <ButtonComp disabled={dialogueExampleList.length >= 10} title="Increase dialogue rounds." width={204} mt={20} height={36} callBack={addDialogueExampleList} fontSize={14}></ButtonComp>
            </div>
          </div>
        </div>
        <div className=" pt-[15px]">
          <p className=" text-[16px] pb-[10px] leading-[19px] text-[#fff] font-bold"> Agents Types </p>
          <div className=" relative w-full h-10">
            <SelectComp selectItem={avatarTypesItem} value={avatarTypesValue || 1} setValue={setAvatarTypesValue}></SelectComp>
          </div>
        </div>
        {avatarTypesValue == 2 &&
          <div className=" bg-[#333333] rounded-[10px] p-[10px] mt-[15px]">
            <DigitalInputComp coinHeight={20} valueSlider={valueSlider} setValueSlider={setValueSlider} height={30} background={"#444444"}></DigitalInputComp>
            <div className=" flex justify-between mt-1 items-center">
              <p className=" text-[12px] text-white font-semibold leading-4"> Transaction Fee</p>
              <p className=" text-style-6 !text-[12px] !font-semibold !leading-4">$20%</p>
            </div>
          </div>
        }
        <p className=" text-[12px] text-[#a6a6a6] mt-1 leading-4">{avatarTypesValue == 1 ? 'Your agent will be accessible to anyone for free.' : avatarTypesValue == 2 ? 'Set a one-time $STAR payment required for user access.' : 'Your agent will only be accessible to you.'}</p>

        <div className=" flex mt-[15px] justify-between items-center">
          <p className={`text-[16px] leading-[19px]  ${type != 'edit' ? 'text-[#fff]' : 'text-[#A6A6A6]'} font-bold`}> NSFW </p>
          <Switch
            disabled={type == 'edit'}
            onChange={() => {
              setIsNsfw(!isNsfw)
            }} checked={isNsfw} size="22px" activeColor=" #00E49F" inactiveColor="rgb(255, 255, 255,0.3)" />
        </div>
        <p className=" text-[12px] text-[#a6a6a6] mt-1 leading-4"> Set an 18+ age requirement for this agent.</p>
        <ButtonComp title={type != 'edit' ? " Create" : 'Update'} width={174} mt={40} height={36} callBack={() => {
          if (type != 'edit') {
            createFun()
          } else {
            putAvatar()
          }
        }} fontSize={14}></ButtonComp>
        {type != 'edit' && <p className="text-[14px] w-full text-center text-[#a6a6a6] mt-[10px] leading-4"> Create AI agent will cost 50,000 $STAR. </p>}
        {avatarTypesValue !== 3 && <div className=" bg-[#333333] p-[6px] mt-[6px] text-center w-full rounded-[10px]">
          <p className=" text-[14px] leading-[18px] font-semibold text-[#a6a6a6]">Each chat session will contribute 50 $STAR to you.</p>
          <p className=" text-[14px] leading-[18px] mt-[6px] font-semibold text-[#a6a6a6]">Each like will contribute 100 $STAR to you.</p>
        </div>}
      </div>
      <PromptDialog
        title={title}
        dialog={dialog}
        setDialog={setDialog}
        lineBreak={true}
        text={text}
        callback={() => {
          setDialog(false)
          navigate(`/agents?id=${3}`)
        }}
      ></PromptDialog>

      <PromptDialog
        title={title}
        dialog={payDialog}
        setDialog={setPayDialog}
        text={text}
        isClose={true}
        callback={() => {
          if (type == 'edit') {
            regenerate()
          } else {
            createFun()
          }

        }}
      ></PromptDialog>



      {isMessage && <TalkMessage text={textMessage}></TalkMessage>}
      <PublicDialog dialog={dialogSuccess} setDialog={setDialogSuccess} content={dialogContent()}></PublicDialog>
    </div >
  )
}
export default Edit