import selling from '@/assets/images/cards/selling.png'
import mining from '@/assets/images/mining/mining.png'
import selected from '@/assets/images/cards/selected.png'
import miningSelect from '@/assets/images/cards/miningSelect.png'
import displayImg from '@/assets/images/cards/display.png'
import coins from '@/assets/images/redpacket/coins.png'
import battling from '@/assets/images/cards/battling.png'
import WebApp from '@twa-dev/sdk'
import coin from '@/assets/images/coin.png'
import tip from '@/assets/images/attribute/tip.svg'
import loading from '@/assets/images/cards/loading.png'
import { Dialog, Popup, Toast, NumberKeyboard } from 'react-vant'
import '@/styles/index.less'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { postSell, postRetract, checkCardStatus, getEnhanceTips, updateDownLoadCount } from '@/api/url.js'
import { getShopPayConfig } from "@/api/shop.js"
import CardItem from '../../components/CardItem'
import { convertNumber, formatNumber, FormattingNumber, FormattingTwoNumber } from '../../utils'

import BScroll from 'better-scroll'
import PromptDialog from '../../components/PromptDialog'
import usePlaySound from "@/hook/usePlaySound.tsx"
import CardRefiningGuide from '../../components/rafining/CardRefiningGuide'
import { changeRegenerateData } from '../../store/module/search';
import Back from '../../components/common/BackComp'
import Mint from "../../pages/nft/Index"

import RegenerateComponent from "../regenerate/Index"
import ButtonComp from '../../components/common/ButtonComp'
import { useDispatch, useSelector } from 'react-redux'
import PublicDialog from '../../components/PublicDialog'
import EnhancedCardDialog from './EnhancedCardDialog'
import { rulesTextArr } from '../../text/rulesText'
import RulesDialog from '../../components/RulesDialog'
import StrengthenCardResult from './StrengthenCardResult'
import { getShopPayCountHandle } from "@/api/starpay.js"
import { changeShopCount } from '../../store/module/lottery'
import { v4 as uuidv4 } from 'uuid';
import PayDialog from '../../components/common/PayDialog'
import CloseComp from '../../components/common/CloseComp'
import GetCoinAnimationDialog from '../../components/GetCoinAnimationDialog'


new BScroll('.role-box', {
	pullUpLoad: true,
	scrollbar: true,
	pullDownRefresh: true
	// and so on
})



const Main = ({ cardsData, updateHandle, setPage, setCardsData, isPadding = true, cardId, callback, cardArray }: any) => {
	const dispatch = useDispatch()
	const [enhance, setEnhance] = useState("0")
	const coinDomCoordinate = useSelector((state: any) => state.tabbar.coinDomCoordinate)
	const [seq, setSeq] = useState('')
	const [visible, setVisible] = useState(false)
	const [claimPointsDialog, setClaimPointsDialog] = useState(false)
	const [enhanceDialog, setEnhanceDialog] = useState(false)
	const closePlaySound = usePlaySound('close')
	const [state, setState] = useState(false)
	const [valueSlider, setValueSlider] = useState('');

	const [buyPopup, setBuyPopup] = useState(false)
	const [dialog, setDialog] = useState(false)
	const [dialogPublish, setDialogPublish] = useState(false)
	const [reinforceRulesDialog, setReinforceRulesDialog] = useState(false)
	const [refining, setRefining] = useState(false)

	const [promptText, setPromptText] = useState<any>('')
	const popupRef = useRef<any>(null)
	const dialogRef = useRef<any>(null)
	const boxRef = useRef<any>(null)
	const [active, setActive] = useState<number>(0)
	const [enhanceTips, setEnhanceTips] = useState<any>({})
	const { shopCount } = useSelector((state: any) => state.lottery)
	const [promptDialog, setPromptDialog] = useState(false)
	const [regenerateIsShow, setRegenerateIsShow] = useState(false)
	const [actionArr, setActionArr] = useState<any>(null)
	const [reinforcementResult, setReinforcementResult] = useState<any>(false)
	const [payList, setPay] = useState<any>([])
	const [sellCard, setSellCard] = useState<any>({})
	const [showPayDialog, setShowPayDialog] = useState(false)

	const AudioRef = useRef<any>()
	const getCoinAnimation = useCallback((x: any, y: any) => {
		const R = 100; // 扩散半径
		const time = 0.25; //扩散过度时间
		const time2 = 1; // 收集过度时间
		const w = 26; // 金币大小
		const Num = 8 //金币数量
		setlits(x, y)


		if (AudioRef.current) {
			// AudioRef.current.currentTime = 0;
			AudioRef.current.play()
		}

		const total = document.getElementsByClassName('total_1')[0] // 获取金币最终飞向
		function setlits(X: any, Y: any) {
			var species = document.createElement('div');
			species.className = 'species'
			for (let i = 0; i < Num; i++) {
				var img = document.createElement('img');
				img.className = 'speciesstyle'
				img.style.width = w + 'px',
					img.style.height = w + 'px',
					img.style.left = X + 'px',
					img.style.top = Y + 'px',
					img.style.transform = 'translate(0%, 0%)',
					img.style.transition = `${time}s`
				img.src = coin
				species.appendChild(img);
			}
			boxRef.current.appendChild(species)
			setTimeout(() => {
				clickss(X, Y)
			}, 0)
		}
		// 计算金币扩散范围和坐标
		function clickss(X: number, Y: number) {
			const specielist: any = document.getElementsByClassName('speciesstyle')
			const num = specielist.length
			const center = {
				x: X,
				y: Y
			}
			for (let i = 0; i < num; i++) {
				const xz = center.x + Math.floor(Math.random() * ((R * 1.1) - (R * 0.6) + 1)) + (0.6 * R) * Math.sin(2 * Math.PI * i / num)
				const yz = center.y + Math.floor(Math.random() * ((R * 1.1) - (R * 0.6) + 1)) + (0.6 * R) * Math.cos(2 * Math.PI * i / num)
				specielist[i].style.transform = `translate(${xz - (X + w / 2)}px, ${yz - (Y + w / 2)}px)`
			}
			setTimeout(() => {
				for (let i = 0; i < num; i++) {
					specielist[i].style.transition = `${time2}s`
					specielist[i].style.transform = `translate(0,0)`
					specielist[i].style.left = Math.floor(total?.getBoundingClientRect().x) + 'px'
					specielist[i].style.top = Math.floor(total?.getBoundingClientRect().y) + 'px'
				}
				// 结束清除蒙层
				setTimeout(() => {
					remove()
				}, (Number(time2) + 0.5) * 500) //飞行时间结束

			}, (Number(time) + 0.5) * 500) //扩散时间结束
		}
		// 清除dom
		function remove() {
			document.getElementsByClassName('species')[0].remove()
		}
		setDialog(false)
	}, [coinDomCoordinate, dialog])
	// let actionArr = useMemo(() => {
	//     if (cardsData && cardsData.length) {
	//         return cardsData[active]
	//     }
	// }, [active])

	// useEffect(() => {
	//     if (enhance == "2") {
	//         setActionArr(continueEnhanceCard)
	//         console.log("🚀 ~ useEffect ~ continueEnhanceCard:", continueEnhanceCard)
	//         setBuyPopup(true)
	//     }
	//     if (enhance == "1") {
	//         setActionArr(continueEnhanceCard)
	//         setBuyPopup(true)
	//         console.log("🚀 ~ useEffect ~ continueEnhanceCard:", continueEnhanceCard)
	//     }
	// }, [continueEnhanceCard.enhance_level, enhance])

	useEffect(() => {
		if (cardId) {
			getCardInoHandle(cardId)

		}
	}, [cardId])
	const getCardInoHandle = async (id: string) => {
		const toast = Toast.loading({
			message: 'Loading...',
			forbidClick: true,
		});
		const { code, data } = await checkCardStatus(id)
		toast.clear()
		if (code == 0) {
			setActionArr(data)
			setBuyPopup(true)
			getPayConfigHandle()
			dispatch(changeShopCount({ type: 0, count: await getShopPayCountHandle(3) }))
			dispatch(changeShopCount({ type: 2, count: await getShopPayCountHandle(11) }))
			dispatch(changeShopCount({ type: 3, count: await getShopPayCountHandle(10) }))
		}
	}



	const getEnhanceInfo = async (id: string, type = 1) => {
		try {
			const toast = Toast.loading({
				message: 'Loading...',
				forbidClick: true,
				duration: 0,
			});
			const { code, data, msg } = await getEnhanceTips(id)
			toast.clear()
			if (code == 0) {
				setEnhanceTips(data)
				type == 1 && setEnhanceDialog(true)
			} else {
				setPromptText(msg)
				setPromptDialog(true)
			}
		} catch {
			setPromptText('Please try again later.')
			setPromptDialog(true)
		}

	}

	useEffect(() => {
		setTimeout(() => {
			setValueSlider('')
		}, 500)
	}, [buyPopup])
	const [text, setText] = useState('Attention')
	async function Sell(id: string) {
		setBuyPopup(false)
		try {
			const res = await postSell(Number(id), Number(valueSlider))
			setDialogPublish(false)
			if (res.code == '0') {
				setSellCard(res)
				setText('')
				setPromptText("Listed Successfully! 🎉")
				setDialog(true)
				isPadding && updateHandle()
			} else if (res.code == "65541") {
				setText('Attention')
				setPromptText("The card is currently staked for mining and cannot be sold.")
				setDialog(true)
			} else {
				setText('Attention')
				setPromptText(res.msg)
				setDialog(true)
			}
		} catch (err) {
			setText('Attention')
			setPromptText("Network error")
			setDialog(true)
		}

	}

	const displayHandle = async (id: string) => {
		setBuyPopup(false)
		try {
			const res = await postSell(Number(id), 0)
			setDialogPublish(false)
			if (res.code == '0') {
				setText('')
				setPromptText("Listed Successfully! 🎉")
				setDialog(true)
				isPadding && updateHandle()
			} else if (res.code == "65541") {
				setText('Attention')
				setPromptText("The card is currently staked for mining and cannot be displayed.")
				setDialog(true)
			} else {
				setText('Attention')
				setPromptText(res.msg)
				setDialog(true)
			}
		} catch (err) {
			setText('Attention')
			setPromptText("Network error")
			setDialog(true)
		}
	}

	const getPayConfigHandle = async () => {
		const toast = Toast.loading({
			message: "Loading...",
			forbidClick: true,
			duration: 0
		})
		try {
			const { code, data } = await getShopPayConfig(10)
			if (code == 0) {
				let list = data.filter((item: any) => item.count == 1)
				list = list.map((item: any) => {
					return {
						...item,
						title: "Image Download"
					}
				});
				setPay(list)
			}
			toast.clear()
		} catch (e) {
			toast.clear()
		}

	}

	//下载卡牌
	const downLoadHandle = async (item: any) => {
		if (shopCount[3] > 0) {
			const match = item.image.match(/\.([a-zA-Z0-9]+)$/);
			const params = {
				url: item.image,
				file_name: `${uuidv4()}${match[0]}`
			}
			WebApp.downloadFile(params, async (status) => {
				if (status) {
					await updateDownLoadCount(item.id)
					dispatch(changeShopCount({ type: 3, count: await getShopPayCountHandle(10) }))
					console.log("下载成功")
				}
			})
		} else {
			setShowPayDialog(true)
			console.log("单次付费")
		}

	}


	async function cancelSell(id: number) {
		try {
			const res = await postRetract(Number(id))
			if (res.code == 0) {
				updateHandle()
			}
		} catch (err) {
			console.log(err)
		}
	}
	const promptCard = useMemo(() => {
		return promptText == 'Listed Successfully! 🎉' ? actionArr : { seq: '', description: "", image: '', is_official: "", level: '', }
	}, [promptText, actionArr])

	//mint


	useEffect(() => {

	}, [])
	// ------------------------ 滑动 -----------------------
	useEffect(() => {
		document.addEventListener('touchmove', touchStartHandle, false);
		return () => {
			document.removeEventListener('touchmove', touchStartHandle, false);
			// window.scrollTo(0, 0);
		}

	})

	const touchStartHandle = (e: any) => {
		e.stopPropagation()
		window.scrollTo(0, 1);
		ensureDocumentIsScrollable();
	}

	const ensureDocumentIsScrollable = () => {
		const isScrollable = document.documentElement.scrollHeight > window.innerHeight;
		if (!isScrollable) {
			document.documentElement.style.setProperty(
				"height",
				"calc(100vh + 0.5px)",
				"important"
			);
		}
	}
	// ------------------------ 滑动 -----------------------
	const [tips, setTips] = useState([])
	const [tipsData, setTipsData] = useState('')


	const regenerateHandle = (item: any) => {
		// console.log(item)
		dispatch(changeRegenerateData(item))
		setRegenerateIsShow(true)
	}


	const contentPublishDom = () => {
		return (
			<div className='px-[14px] pt-[42px]'>
				<h1 className='text-[20px] text-center text-[#333] leading-[24px] '>Set a price</h1>
				<div className={`w-full mt-[30px] relative `}>
					<div className={` transition-all ${'relative'}`}>
						<input
							max='1000000'
							style={{ background: 'linear-gradient( 135deg, #FFFFFF 100%, #FFFFFF 100%)' }}
							value={formatNumber(valueSlider !== '' ? Number(valueSlider) : '')}
							placeholder={`0-${formatNumber(1000000)}`}
							onClick={() => {
								if (WebApp.platform !== "macos" && WebApp.platform !== "tdesktop") {
									setState(true)
								}
							}}
							onChange={(e: any) => {
								if (WebApp.platform === "macos" || WebApp.platform === "tdesktop") {

									const newValue = e.target.value.replace(/,/g, "");
									const numericRegex = /^[0-9]*$/;
									const newValueIsNumeric = numericRegex.test(newValue);
									if (newValueIsNumeric) {
										if (newValue >= 1000000) {
											setValueSlider('1000000')
										} else {
											setValueSlider(newValue);
										}
									}
								}
							}}
							onFocus={(e: any) => {
								e.preventDefault()
								if (WebApp.platform !== "macos" && WebApp.platform !== "tdesktop") {
									e.target.blur()
								}
							}}
							className={`text-[#333333] publishInput tracking-[1.5px] text-[18px] leading-[16px] w-[100%] h-10 rounded-[10px] p-2 px-[10xp] font-bold`}
						/>
						<img src={coin} className='h-[24px] absolute top-[7px] right-[10px]' alt="" />
					</div>
				</div>
				<ButtonComp width={174} mt={30} title='Publish' callBack={() => {

					Sell(actionArr?.id)
				}}>
				</ButtonComp>
				<p className='mt-2 text-[12px] text-[#A6A6A6] leading-[15px] text-center'>
					Cards traded in the market will reset their enhancement levels. If the sale is not successful or is withdrawn, your card's enhancement level will not be reset.
				</p>
			</div>
		)
	}


	return (
		<div ref={boxRef} className={`role-box ${isPadding ? 'pt-14' : ''}`}>
			{
				cardsData && cardsData.length == 0 ?
					<div className=' flex flex-col items-center h-full justify-center'>
						<img className='w-[185px]' src={loading} alt="" />
						<p className='text-[14px] text-[#FFF] mt-[25px] leading-[18px] font-bold'>Chat to get cards</p>
					</div> :
					<>
						<div className="flex px-4 gap-[10px]  flex-wrap justify-between ">
							{cardsData?.map((el: any, index: number) =>
								<div style={{ width: 'calc(50% - 5px)' }} key={`cardsData${index}`} onClick={() => {

									if (el.state !== 2 && el.state !== 5 && !el.is_nft) {
										if (callback) {
											Array.isArray(cardArray) && !cardArray.some((e: any) => e?.id == el.id) && callback(el)
										} else {
											closePlaySound();
											setActionArr(el)
											setBuyPopup(true);
											getCardInoHandle(el.id)
										}

									}
									if (el.is_nft && el.mint_state != 2) {
										if (el.state !== 2 && el.state !== 5) {
											if (callback) {
												Array.isArray(cardArray) && !cardArray.some((e: any) => e?.id == el.id) && callback(el)
											} else {
												setActionArr(el)
												setBuyPopup(true);
												getCardInoHandle(el.id)
											}
										}
									}
								}} className={`overflow-hidden flex items-center justify-center p-[2px] h-fit rounded-[10px] relative`}>
									<CardItem cardType={el.card_type} bonusPower={el?.bonus_power} power={el.power} property={el.property} enhanceLevel={el.enhance_level} mint_state={el.mint_state} is_nft={el.is_nft} id={el.id} price={el.price} like={el.like_count} view={el.view_count} state={el.state} mine={1} no={`No. ${convertNumber(el.seq)}`} image={el.image} level={el.is_official ? 6 : el.level + 1} text={el.description}>
									</CardItem>
									{(el.state == 2 || el.state == 5 || (cardArray && cardArray.some((e: any) => e?.id == el.id))) && <>
										<div className='absolute top-0 left-0 w-full h-full bg-[#000000] opacity-[0.5] z-[2]'></div>
										{el.state == 2 &&
											<div className='absolute top-[65%]'>
												<ButtonComp width={65} height={30} title="Cancel" callBack={() => {
													cancelSell(el.id)
												}}></ButtonComp>
											</div>
										}
										<img className='absolute  w-[85px] top-[45%] right-[50%] translate-x-[50%] z-[3] translate-y-[-50%]' src={el.state == 2 ? el.card_type == 2 ? displayImg : selling : Array.isArray(cardArray) ? miningSelect : battling} alt="" />
									</>}
									{(el.state == 4) && <>
										<img className=' w-[24px] absolute bottom-[2px] left-[2px]' src={selected} alt="" />
									</>}
									{(el.state == 6) && <>
										<img className=' w-[24px] absolute bottom-[2px] left-[2px]' src={mining} alt="" />
									</>}
									{(el.state == 7) && <>
										<img className=' w-[24px] absolute bottom-[2px] left-[2px]' src={selected} alt="" />
										<img className=' w-[24px] absolute bottom-[2px] left-[32px]' src={mining} alt="" />
									</>}
									{/* {Array.isArray(cardArray) &&  && <>
                                        <img className=' w-[24px] absolute bottom-[2px] left-[2px]' src={selected} alt="" />
                                    </>} */}
								</div>
							)}
						</div>
						<Dialog
							className='PublicDialog overflow-visible'
							visible={visible}
							showConfirmButton={false}
							showCancelButton={false}
							closeOnClickOverlay={true}
							onClose={() => { setVisible(false) }}
							width={'85%'}
						>
							<div
								className="w-[30px] h-[30px] absolute bottom-[-50px] left-[50%] translate-x-[-50%] " >
								<CloseComp position="absolute" top={14} right={16} callBack={() => { setVisible(false) }}></CloseComp>
							</div>
							<div ref={dialogRef} className="w-[100%] items-center flex justify-center p-2 overflow-hidden relative">
								<CardItem no={`No. ${convertNumber(cardsData[active]?.seq)}`} image={cardsData[active]?.image} big={true} text={cardsData[active]?.description} level={cardsData[active]?.is_official ? 6 : cardsData[active]?.level + 1}></CardItem>
							</div>
						</Dialog>
					</>
			}
			<Popup
				style={{ width: '100%', height: '100vh', overflow: 'hidden', backgroundColor: 'rgba(0,0,0,0)' }}
				position='right'
				visible={buyPopup}>
				<div ref={popupRef} className='w-full h-full relative bg-no-repeat bg-cover' style={{ backgroundImage: `url(${actionArr?.image})`, }}>
					<img onClick={() => { setReinforceRulesDialog(true) }} className='z-[99] absolute top-3 right-4 ' width="18px" height="18px" src={tip} alt="" />
					{
						!reinforcementResult && <Back position={true} callback={async () => {
							setBuyPopup(false)
							// updateHandle()
						}}>
						</Back>}
					<div className={`${reinforcementResult ? '' : 'px-10 !pt-10 !pb-[60px]'} w-full !backdrop-blur-[20px] flex justify-center items-center flex-col relative  h-full `}>
						{!reinforcementResult ?
							<>
								<div ref={dialogRef} className="w-[100%] items-center flex justify-center p-2 overflow-hidden relative">
									<CardItem info={actionArr} bonusPower={actionArr?.bonus_power} power={actionArr?.power} property={actionArr?.property} is_nft={actionArr?.is_nft} mint_state={actionArr?.mint_state} enhanceLevel={actionArr?.enhance_level} no={`No. ${convertNumber(actionArr?.seq)}`} image={actionArr?.image} big={true} text={actionArr?.description} level={actionArr?.is_official ? 6 : actionArr?.level + 1}>
									</CardItem>
								</div>
								{/* */}
								{<div className='mt-[12px] flex' onClick={() => { downLoadHandle(actionArr) }}>
									<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
										<g id="页面-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
											<g id="mine卡牌详情自然级" transform="translate(-176.000000, -571.000000)" fill="#FFFFFF" fillRule="nonzero">
												<g id="编组" transform="translate(176.000000, 571.000000)">
													<path d="M22.1073602,1.89263976 C20.8982732,0.676884698 19.2530494,-0.0046175326 17.5384269,2.35501392e-05 L6.46152572,2.35501392e-05 C2.8929236,2.35501392e-05 0,2.89297094 0,6.46157306 L0,17.5384743 C0,21.1070764 2.8929236,24 6.46152572,24 L17.5384269,24 C21.1070291,24 23.9999764,21.1070764 23.9999764,17.5384743 L23.9999764,6.46157306 C24.0046175,4.74695065 23.3231153,3.10172677 22.1073602,1.89263976 Z M17.5384269,18.4615494 C17.5384269,18.9713497 17.1251521,19.3846245 16.6153518,19.3846245 L7.38460082,19.3846245 C6.87480052,19.3846245 6.46152572,18.9713497 6.46152572,18.4615494 C6.46152572,17.9517491 6.87480052,17.5384743 7.38460082,17.5384743 L16.6153518,17.5384743 C17.1251521,17.5384743 17.5384269,17.9517491 17.5384269,18.4615494 Z M17.2822736,12.3571384 L12.5119946,15.5374205 C12.2019395,15.744117 11.7980131,15.744117 11.4879581,15.5374205 L6.71767906,12.3571384 C6.51392605,12.22136 6.37246588,12.0101926 6.32442623,11.7701026 C6.27638658,11.5300126 6.32570375,11.2806725 6.46152572,11.0769486 C6.59730402,10.8731956 6.80847143,10.7317354 7.04856148,10.6836957 C7.28865152,10.6356561 7.53799159,10.6849733 7.7417155,10.8207952 L10.7180558,12.8054067 C10.7888864,12.852664 10.8799847,12.8570776 10.955052,12.8168889 C11.0301193,12.7767001 11.0769566,12.69844 11.0769012,12.6132917 L11.0769012,5.53849796 C11.0769012,5.02869765 11.490176,4.61542285 11.9999763,4.61542285 C12.2447911,4.61542285 12.4795788,4.71267513 12.652689,4.88578529 C12.8257992,5.05889545 12.9230514,5.29368322 12.9230514,5.53849796 L12.9230514,12.6132917 C12.9229961,12.69844 12.9698334,12.7767001 13.0449007,12.8168889 C13.1199679,12.8570776 13.2110663,12.852664 13.2818969,12.8054067 L16.2582372,10.8207952 C16.4619611,10.6849733 16.7113011,10.6356561 16.9513912,10.6836957 C17.1914812,10.7317354 17.4026486,10.8731956 17.5384269,11.0769486 C17.6742489,11.2806725 17.7235661,11.5300126 17.6755264,11.7701026 C17.6274868,12.0101926 17.4860266,12.22136 17.2822736,12.3571384 Z" id="形状"></path>
												</g>
											</g>
										</g>
									</svg>

									{
										shopCount[3] > 0 && <div className='ml-1 text-white'>
											( {shopCount[3]} )
										</div>
									}
								</div>}
								{!(actionArr?.is_official) && <>
									<div className="btn grid grid-cols-2 mt-[12px] gap-2 w-full relative">
										<ButtonComp width={130} title='Display' callBack={async () => {
											await displayHandle(actionArr?.id)
										}}>
										</ButtonComp>
										{
											actionArr?.mint_state == 0 && actionArr?.state != 4 && actionArr?.state != 6 && actionArr?.state != 7 && <ButtonComp width={130} title='Publish' callBack={() => {
												if (actionArr?.card_type == 1) {
													setPromptText('Your card is a gift card. That cannot be sold.')
													setPromptDialog(true)
													return
												}
												if (actionArr?.state == 3) {
													setPromptText('This card has been reported and cannot be put up for sale.')
													setPromptDialog(true)
													return
												}
												setDialogPublish(true)
											}}>
												{!!actionArr?.first_publish_reward && <div style={{
													width: '59px',
													height: '18px',
													background: 'linear-gradient( 125deg, #EF4444 0%, #E88585 100%)',
													borderRadius: '0px 9px 0px 9px'
												}} className=" text-[#fff] text-[12px] leading-[15px] absolute z-10 -right-[2px] flex items-center justify-center -top-2 ">
													{FormattingTwoNumber(Number(actionArr?.first_publish_reward))}
													<img src={coins} className=" w-[14px] ml-[6px] h-[10px]" alt="" />
												</div>}
											</ButtonComp>
										}
										{actionArr?.level != 0 &&
											<>
												{
													!actionArr?.is_nsfw && actionArr?.state != 3 &&
													<>
														{
															actionArr?.mint_state == 3 ?
																<>
																	<ButtonComp width={130} title='Trade' callBack={() => {
																		WebApp.openLink(actionArr?.nfts[0])
																	}}>
																	</ButtonComp>
																	<ButtonComp width={130} title='Check' callBack={() => {
																		WebApp.openLink(actionArr.nfts[1])
																	}}>
																	</ButtonComp>

																</>
																:
																<div className='absolute -bottom-[50px] w-full'>
																	<Mint status={actionArr?.mint_state} cardId={actionArr?.id} cardState={actionArr?.state} callback={(id: string) => {
																		getCardInoHandle(id)
																		updateHandle()
																	}}></Mint>
																</div>
														}

													</>

												}
												{/* //${shopCount[2] > 0 && '(' + shopCount[2] + ')'} */}
												{actionArr?.level != 0 && actionArr?.level != 5 && actionArr?.enhance_level != 5 && <ButtonComp width={130} title={`Enhance`} callBack={() => {
													getEnhanceInfo(actionArr.id, 1)
												}}>
												</ButtonComp>}</>}
										<>
											{
												actionArr?.level == 4 && actionArr?.mint_state == 0 &&
												<ButtonComp width={130} title={`Regenerate ${shopCount[0] > 0 ? '(' + shopCount[0] + ')' : ''}`} callBack={() => {
													if (actionArr?.state != 4 && actionArr?.state != 6) {
														regenerateHandle(actionArr)
													} else {
														setPromptText('Your card is currently in the Mining and temporarily cannot be regenerated. Please finish the Mining before trying again.')
														setPromptDialog(true)
													}
												}}></ButtonComp>
											}


										</>
									</div>
								</>}
							</> :
							<StrengthenCardResult
								cardId={actionArr?.id}
								callbackHandle={async ({ cardInfo, isEnhance, type }) => {
									await getEnhanceInfo(actionArr.id, Number(type))
									dispatch(changeShopCount({ type: 2, count: await getShopPayCountHandle(11) }))
									setActionArr(cardInfo)
									setReinforcementResult(false)
									setEnhanceDialog(isEnhance)
									setEnhance(type)
									setCardsData(cardsData.map((el: any) => {
										if (el.id == cardInfo.id) {
											return cardInfo
										} else {
											return el
										}
									}))
									// updateHandle()
									setPage(2)
								}}
							></StrengthenCardResult>
						}
					</div>

				</div>

				<NumberKeyboard
					visible={state}
					value={valueSlider}
					className='z-[999999]'
					maxlength={7}
					onBlur={() => setState(false)}
					onChange={v => {
						if (Number(v) > 999999) {
							setValueSlider('1000000')
						} else {
							setValueSlider(v)
						}
					}}
				/>
			</Popup >

			<Popup visible={regenerateIsShow} position="right" overlay={false} style={{ height: "100%" }} >
				<RegenerateComponent closePopup={() => { setRegenerateIsShow(false) }}></RegenerateComponent>
			</Popup>
			<RulesDialog textArr={rulesTextArr} title="Card Power Rules" dialog={reinforceRulesDialog} setDialog={setReinforceRulesDialog} teleport={popupRef.current}></RulesDialog>
			<CardRefiningGuide tips={tips} tipsData={tipsData} dialog={refining} setDialog={setRefining}></CardRefiningGuide>
			<PromptDialog power={actionArr?.power - actionArr?.enhance_power} property={actionArr?.property} teleport={document.querySelector("body")}
				callback={() => {
					if (sellCard.data) {
						setClaimPointsDialog(true)
					} else {
						updateHandle()
					}
				}}
				title={text} dialog={dialog} no={`No: ${convertNumber(seq)}`} name={promptCard?.description} image={promptCard?.image} level={promptCard?.is_official ? 6 : promptCard?.level + 1} setDialog={setDialog} className='publish-buy-dialog' text={promptText}></PromptDialog>
			{/* <audio ref={closeAudioRef} src={closeMusic} id="audio"></audio> */}
			<PublicDialog teleport={popupRef.current} closeOnClickOverlay={false} width={'290'} dialog={dialogPublish} setDialog={setDialogPublish} content={contentPublishDom()}> </PublicDialog>
			{enhanceDialog && <EnhancedCardDialog setReinforcementResult={setReinforcementResult} teleport={popupRef.current} cardInfo={actionArr} data={enhanceTips} dialog={enhanceDialog} setDialog={setEnhanceDialog}></EnhancedCardDialog>}

			{
				claimPointsDialog && <GetCoinAnimationDialog dialog={claimPointsDialog} point={sellCard.data} getCoinAnimation={getCoinAnimation} setDialog={() => {
					setClaimPointsDialog(false)
					updateHandle()
				}}></GetCoinAnimationDialog>
			}

			<PromptDialog teleport={popupRef.current} title={'Attention'} dialog={promptDialog} setDialog={setPromptDialog} callback={() => { setPromptDialog(false) }} text={promptText}></PromptDialog>

			{
				showPayDialog && <PayDialog teleport={popupRef.current} isNeedDialogSuccess={false} dataList={payList} title={`Purchase Image Download`} callBack={async (val) => {
					setShowPayDialog(false)
					if (val != 'close') {
						const match = actionArr.image.match(/\.([a-zA-Z0-9]+)$/);
						const params = {
							url: actionArr.image,
							file_name: `${uuidv4()}${match[0]}`
						}
						// await updateDownLoadCount(actionArr.id)
						WebApp.downloadFile(params, async (status) => {
							if (!status) {
								dispatch(changeShopCount({ type: 3, count: await getShopPayCountHandle(10) }))
							}
						})
					}
				}} />
			}


		</div >
	)
}


export default Main;
