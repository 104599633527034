import { useNavigate, useSearchParams } from "react-router-dom";
import up from '@/assets/images/attribute/up.svg'
import refiningFailure from '@/assets/images/refining/refiningFailure.png'
import CardItem from "../../components/CardItem";
import Back from "../../components/common/BackComp";
import { postCardEnhance, checkCardStatus } from "../../api/url.js"
import { useEffect, useRef, useState } from "react";
import useNumberScroll from "../../hook/useNumberScroll.tsx";
import ButtonComp from "../../components/common/ButtonComp.tsx";
import enhance_2 from '@/assets/images/attribute/1-2.png'
import enhance from '@/assets/images/attribute/1-1.png'
import shadow from '@/assets/images/attribute/shadow.png'
import enhance2_2 from '@/assets/images/attribute/2-2.png'
import enhance2_1 from '@/assets/images/attribute/2-1.png'
import enhance3_1 from '@/assets/images/attribute/3-1.png'
import enhance3_2 from '@/assets/images/attribute/3-2.png'
import enhance4_2 from '@/assets/images/attribute/4-2.png'
import enhance4_1 from '@/assets/images/attribute/4-1.png'
import enhance5_1 from '@/assets/images/attribute/5-1.png'
import enhance5_2 from '@/assets/images/attribute/5-2.png'
import { Toast } from "react-vant";
import PromptDialog from "../../components/PromptDialog.tsx";
import { useDispatch, useSelector } from "react-redux";
import { changeContinueEnhanceCard } from "../../store/module/card.ts";




type callbackProp = {
  cardInfo: any,
  isEnhance: boolean,
  type: string
}
interface StrengthenCardResultProps {
  cardId: string
  callbackHandle?: (val: callbackProp) => void
}
function StrengthenCardResult({ cardId, callbackHandle }: StrengthenCardResultProps) {
  const numberScroll = useNumberScroll()
  const navigate = useNavigate();
  const textScrollDom = useRef<any>(null)
  const [searchParams] = useSearchParams()
  const id: any = cardId ?? searchParams.get("id") as string;
  const [enhanceInfo, setEnhanceInfo] = useState<any>({})
  const [cardInfo, setCardInfo] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)
  const [promptDialog, setPromptDialog] = useState(false)
  const [promptText, setPromptText] = useState('')
  const dispatch = useDispatch()
  const { isSaveEnhance, enhanceSaveOrder } = useSelector((state: any) => state.search)


  const getCardInfo = async (val: any) => {
    try {
      const { code, data, msg } = await checkCardStatus(id)
      if (code == '0') {
        setIsLoading(true)
        setCardInfo(data)
        dispatch(changeContinueEnhanceCard(data))
        setTimeout(() => {
          if (textScrollDom.current) {
            console.log("🚀 ~ setTimeout ~ enhanceInfo:", val)
            if (val.result) {
              numberScroll(textScrollDom.current, val.power - val.enhance_power, val.power)
            } else {
              numberScroll(textScrollDom.current, val.power, val.power - val.enhance_power)
            }
          }
        }, 200)
      } else {
        setIsLoading(true)
        setPromptText(msg)
        setPromptDialog(true)
      }
    } catch {
      setIsLoading(true)
      setPromptText('Please try again later.')
      setPromptDialog(true)
    }

  }
  const cardEnhance = async () => {
    const toast = Toast.loading({
      message: 'Loading...',
      duration: 0
    });
    try {
      const params = {
        order_id: enhanceSaveOrder,
        is_protect: isSaveEnhance
      }
      const { code, data, msg } = await postCardEnhance(id, params)
      if (code == '0') {
        getCardInfo(data)
        setEnhanceInfo(data)
      } else {
        setPromptText(msg)
        setPromptDialog(true)
      }
    } catch {
      setPromptText('Please try again later.')
      setPromptDialog(true)
    }
    toast.clear()
  }
  useEffect(() => {
    cardEnhance()
  }, [])

  function borderImage(level: number) {
    switch (level) {
      case 1:
        return [enhance, enhance_2]
      case 2:
        return [enhance2_1, enhance2_2]
      case 3:
        return [enhance3_1, enhance3_2]
      case 4:
        return [enhance4_1, enhance4_2]
      case 5:
        return [enhance5_1, enhance5_2]
      default:
        return [enhance, enhance_2]
    }
  }

  return (
    <div style={{ overflowY: 'auto' }} className={`px-4 z-[995] w-full h-[100vh] RefiningFailure  pt-[60px] ${!enhanceInfo.result ? 'pb-[120px]' : 'pb-[80px]'} overflow-hidden`}>
      <div className="fixed left-0 top-0 z-[1200] h-11 w-full text-[16px] justify-between px-4 font-bold leading-[19px] text-white flex items-center">
        <Back title="Enhance" callback={() => {
          callbackHandle && callbackHandle({
            cardInfo,
            isEnhance: false,
            type: '2'
          });
          // navigate('/cards?id=1')
        }}></Back>
      </div>
      {isLoading && <>
        <div className="w-full flex justify-center">
          <div className={`text-[16px] pb-6 leading-[19px] font-bold w-fit  text-center ${enhanceInfo.result ? 'text-style-1' : 'text-[#a6a6a6]'}`}> {enhanceInfo.result ? 'Congratulations!' : 'Enhance Unsuccessful!'}</div>
        </div>
        <div className="w-full flex justify-center items-center px-6 relative">
          <img className=" absolute refiningAnimation_1 w-[100vw] bottom-4 z-0" src={refiningFailure} alt="" />
          <div className=" w-full rounded-[22px] overflow-hidden relative">
            <CardItem property={cardInfo.property} big={true} height={90} power={cardInfo.power} image={cardInfo.image} is_nft={cardInfo.is_nft} level={cardInfo.level + 1} rounded={10}></CardItem>
            <div className=" absolute h-[50px] w-[50px] bottom-2 z-10 right-1">
              {/* <img src={shadow} className=" absolute w-[80px] top-0 left-0 h-[80px]" /> */}
              <img className={` w-[50px] absolute  top-0 left-0 ${enhanceInfo.result ? '' : 'opacity-0 crackAnimation'}`} src={borderImage(enhanceInfo.enhance_level)[0]} alt="" />
              <img className={` w-[50px] absolute  top-0 left-0 ${enhanceInfo.result ? '' : 'opacity-0 crackAnimation_1'}`} src={borderImage(enhanceInfo.enhance_level)[1]} alt="" />
            </div>
          </div>
        </div>
        <div className="flex justify-center gap-[10px] items-center mt-6">
          {enhanceInfo.result &&
            <div className="h-[24px] flex overflow-hidden">
              <div className="flex flex-col upScroll gap-2">
                <img className="w-[20px]" src={up} alt="" />
                <img className="w-[20px]" src={up} alt="" />
                <img className="w-[20px]" src={up} alt="" />
                <img className="w-[20px]" src={up} alt="" />
                <img className="w-[20px]" src={up} alt="" />
              </div>
            </div>
          }
          <p className={` ${enhanceInfo.result ? 'text-style-1' : ' text-[#a6a6a6]'} font-bold w-[150px] text-center text-[20px]`}>Power :  <span ref={textScrollDom} className={`text-[30px]  ${enhanceInfo.result ? ' text-transparent ' : ' text-[#a6a6a6]'}`}></span></p>
        </div>
      </>}
      <div className="fixed w-[100vw] !z-[2000] left-0 px-4 bottom-8">
        <div className=" relative !z-[2000]">
          <div className=" flex w-full justify-between">
            {enhanceInfo.enhance_level < 5 &&
              <ButtonComp fontSize={14} width={130} title='Continue Enhance' callBack={() => {

                callbackHandle && callbackHandle({
                  cardInfo,
                  isEnhance: true,
                  type: '1'
                })
              }}></ButtonComp>}
            <ButtonComp fontSize={14} width={130} title='OK'
              callBack={() => {
                callbackHandle && callbackHandle({
                  cardInfo,
                  isEnhance: false,
                  type: '2'
                })


              }}></ButtonComp>
          </div>
        </div>
        {!enhanceInfo.result && <p className="text-[12px] text-[#a6a6a6] leading-[14px] text-center mt-[10px]">After a failed enhance, the card will not disappear, but its enhancement level will reset!</p>}
      </div>
      <PromptDialog title={'Attention'} dialog={promptDialog} setDialog={setPromptDialog} callback={() => { navigate('/cards?id=2') }} text={promptText}></PromptDialog>
    </div >
  )
}
export default StrengthenCardResult
