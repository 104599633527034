import { Loading, Popup } from "react-vant"
import newNfted from '@/assets/images/newNfted.png'
import selected from '../../../assets/images/pk/selected.png'
import talk from '../../../assets/images/pk/talk.png'
import like from '../../../assets/images/pk/like.png'
import BackComp from "../../../components/common/BackComp"
import water from '@/assets/images/attribute/water.png'
import electric from '@/assets/images/attribute/electric.png'
import loading from '@/assets/images/cards/loading.png'
import grass from '@/assets/images/attribute/grass.png'
import ground from '@/assets/images/attribute/ground.png'
import fire from '@/assets/images/attribute/fire.png'
import CardItem from "../../../components/CardItem"
import power_bg from '@/assets/images/attribute/power_bg.png'
import { attributeImage, attributeName, formatNumber, levelName } from "../../../utils"
import ButtonComp from "../../../components/common/ButtonComp"
import { useNavigate } from "react-router-dom"
import PromptDialog from "../../../components/PromptDialog"
import { getBattleCards, checkAvatar } from "../../../api/url.js"
import { useEffect, useRef, useState, useImperativeHandle, forwardRef } from "react"
import { useDispatch, useSelector } from "react-redux"

interface SelectCardsProps {
  selectCardsPopup: boolean
  setSelectCardsPopup: Function
  cardData: any
  setAttribute: Function
  attribute: string
  setActive: Function
  level: number
  active: any
  page: number
  setPage: Function
  setLoading: Function
  setCardData: Function
  loadingPage: boolean
  isInit: boolean
  count: number
  type: number
  setType: Function
  currDeck?: any,
  isSaveDeck: boolean,
  callBack?: () => void
  setLevel?: Function

}
const SelectCards = forwardRef(({ type, setType, callBack, isSaveDeck, setCardData, isInit, count, setLoading, loadingPage, setPage, page, selectCardsPopup, setSelectCardsPopup, setAttribute, attribute, cardData, level, setActive, active, currDeck, setLevel }: SelectCardsProps, ref) => {
  const attributeIcon = [water, electric, grass, ground, fire]
  const [dialog, setDialog] = useState(false)
  const [spendCoins, setSpendCoins] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const boxRef = useRef<any>(null)
  const [isShowSaveBtn, setShowSaveBtn] = useState(false)

  const { arenaNavActive, nftDeck } = useSelector((state: any) => state.battle)

  console.log("🚀 ~ level:", level)


  const createHandle = async () => {
    const res = await checkAvatar()
    if (res.code == "0") {
      navigate("/create-agents")
    } else if (res.code == "65547") {
      setDialog(true)
    }
  }

  const pageScroll = async () => {
    let num = Math.ceil(count / 10)
    if (loadingPage && num > page) {
      if (boxRef.current) {
        if (boxRef.current.scrollHeight - boxRef.current.scrollTop <= boxRef.current.clientHeight + 10) {
          setLoading(false)
          setPage(page + 1)
        }
      }
    }
  }

  function scrollToTop() {
    try {
      boxRef.current.scrollTop = 0
      boxRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth' // 可选，平滑滚动效果
      });
      // window.scrollTo(0, 0);
    } catch (e) {

    }
  }

  const callBackHandle = () => {
    if (level == 8) {
      setSpendCoins(true)
    } else {
      if (level == 6) {
        navigate("/cards?id=1&type=myDeck")
      } else {
        navigate("/cards?id=2&type=myDeck")
      }
    }
  }
  useImperativeHandle(ref, () => {
    return {
      callBackHandle
    }
  })

  return (
    <Popup
      style={{ width: '100%', height: '100vh', overflow: 'hidden', backgroundColor: 'rgba(0,0,0)' }}
      position='right'
      overlayStyle={{
        backgroundColor: 'rgba(0,0,0)'
      }}
      visible={selectCardsPopup}>
      <div className="pt-[54px] w-full px-4">
        {!isInit ? <>
          <div className="fixed w-[100%] top-0 left-0">
            <BackComp title={level == 6 ? 'Select NFTs' : `${levelName(level)} Cards`} position={true} callback={() => { setSelectCardsPopup(false) }}></BackComp>
          </div>
          {level == 6 && <div className={`mt-[10px] mb-5 grid grid-cols-2 rounded-[10px] h-[36px] bg-[#333]`}>
            <div onClick={() => {
              setCardData([])
              setType(0)
            }} className={`${type ? 'text-[#a6a6a6]' : 'text-[#fff] bg-gradient-to-r from-[#00E49F] to-[#54ADFF]'} rounded-[10px] justify-center  flex items-center`}>
              Card NFTs
            </div>
            <div onClick={() => {
              setCardData([])
              setType(1)
            }} className={`${type ? 'text-[#fff] bg-gradient-to-r from-[#00E49F] to-[#54ADFF]' : 'text-[#a6a6a6]'} rounded-[10px] flex justify-center items-center`}>
              Agent NFTs
            </div>
          </div>}
          <div className=" flex items-center gap-5">
            <div onClick={() => {
              scrollToTop()
              setAttribute('All')
              setCardData([])
              setPage(1)
            }} style={{ textShadow: '0px 2px 4px rgba(0,0,0,0.5)' }} className={`${attribute == 'All' ? 'bg-gradient-to-r from-[#00E49F] to-[#54ADFF] px-2 py-0.5' : ''}  rounded-[6px] text-[14px] text-[#fff] leading-[18px]`}>All</div>
            {attributeIcon.map((el, index: number) =>
              <div
                key={`${index}-attributeIcon`}
                className={`${attribute == attributeName(index) ? 'bg-gradient-to-r from-[#00E49F] to-[#54ADFF] px-2 py-[1px] rounded-[6px]' : ''}`}
                onClick={() => {
                  scrollToTop()
                  setCardData([])
                  setPage(1)
                  setAttribute(attributeName(index))
                }}>
                <img className=" w-5" src={el} alt="" />
              </div>
            )}
          </div>
          {cardData.length > 0 ?
            <div>
              {level != 8 ?
                <>
                  {type == 0 ? <div ref={boxRef} onScroll={pageScroll} style={{ alignContent: 'flex-start', overflowX: 'hidden' }} className={`mt-2.5 ${level == 6 ? 'h-[calc(100vh_-_166px)]' : 'h-[calc(100vh_-_120px)]'} flex  pb-6 overflow-auto items-start flex-wrap gap-[10px] w-full`}>
                    {cardData.map((el: any) =>
                      <div key={`CardItem-${el.id}`} onClick={() => {
                        setPage(1)
                        if (arenaNavActive == 0) {
                          setActive({ ...active, [levelName(level) as string]: el })
                        } else {
                          if (!nftDeck.includes(el.id)) {
                            setActive({ ...active, [levelName(currDeck) as string]: el })
                          }
                        }
                        setSelectCardsPopup(false)
                      }} className=" w-[calc(50%_-_5px)] h-fit  relative">
                        {
                          arenaNavActive == 0 &&
                          el.id == active[levelName(level) as string]?.id &&
                          <div className=" w-full h-full absolute flex justify-center items-center z-10 bg-[#000]/50">
                            <img className=" w-[115px] " src={selected} alt="" />
                          </div>
                        }
                        {
                          arenaNavActive != 0 &&
                          nftDeck.includes(el.id)
                          &&
                          <div className=" w-full h-full absolute flex justify-center items-center z-10 bg-[#000]/50">
                            <img className=" w-[115px] " src={selected} alt="" />
                          </div>
                        }
                        <CardItem power={el?.power} bonusPower={el?.bonus_power} property={el?.property} is_nft={el?.is_nft} mint_state={el?.mint_state} enhanceLevel={el?.enhance_level} image={el?.image} big={false} text={el?.description} level={el?.level + 1}>
                        </CardItem>
                      </div>
                    )}
                    {
                      !loadingPage &&
                      <div className={'w-[100%]  py-4 flex flex-col items-center'}>
                        <Loading type="spinner" size="20" color="#00E49F">
                          <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF]">Loading...</span>
                        </Loading>
                      </div>
                    }
                  </div> :
                    <>
                      <div ref={boxRef} onScroll={pageScroll} style={{ alignContent: 'flex-start', overflowX: 'hidden' }} className="flex h-[calc(100vh_-_170px)] pb-6 overflow-auto items-start flex-wrap mt-[10px] gap-[10px] w-full">
                        {cardData.map((el: any) =>
                          <div
                            key={`hero CardItem-${el.id}`}
                            onClick={() => {
                              setPage(1)
                              if (arenaNavActive == 0) {
                                setActive({ ...active, [levelName(level) as string]: el })
                              } else {
                                if (!nftDeck.includes(el.id)) {
                                  setActive({ ...active, [levelName(currDeck) as string]: el })
                                }
                              }
                              setSelectCardsPopup(false)
                            }} className="w-[calc(50%_-_5px)] overflow-hidden relative" >
                            <img className=' absolute left-0 top-[0px] w-6 h-6' src={attributeImage(el.property)} alt="" />

                            {el.mint_state == 3 &&
                              <>
                                <img className=' absolute left-0 top-[29px] w-[42px] h-5' src={newNfted} alt="" />
                              </>
                            }
                            <div className={` ${'h-6'} absolute px-[5px] flex items-center justify-center z-10 top-0 right-0`}>
                              <div className=' absolute top-0 left-0 w-full h-full opacity-60' style={{ backgroundImage: `url(${power_bg})`, backgroundSize: '100% 100%' }}></div>
                              <p className={`text-style-7 ${'!text-[12px]'} !font-bold`}>{el.power}</p>
                              <p className={`${el?.bonus_power ? 'text-style-4 pr-[2px]' : 'text-style-7'} !text-[12px] !font-bold`}>{el?.bonus_power ? el?.bonus_power + el?.power : el?.power}</p>

                            </div>
                            {
                              el.id == active[levelName(level) as string]?.id &&
                              <div className=" w-full h-full absolute flex justify-center items-center z-10 bg-[#000]/50">
                                <img className=" w-[115px] " src={selected} alt="" />
                              </div>
                            }
                            <div className=" absolute bottom-[10px] left-[10px] ">
                              <p className=" text-[16px] leading-[19px]  text-[#fff] max-w-[150px] ellipsis font-bold ">{el.name}</p>
                              <div className=" flex mt-1 items-center">
                                <img className="w-3" src={like} alt="" /><p className="text-[12px] ml-[2px] mr-[10px] text-[#E0E0E0]">{formatNumber(el.like_count) || 0}</p> <img className="w-3" src={talk} alt="" /><p className="text-[12px] ml-[2px] text-[#E0E0E0]">{formatNumber(el.chat_count) || 0}</p>
                              </div>
                            </div>
                            <img style={{ objectFit: 'none' }} className=" w-full rounded-[10px]" src={el.url} alt="" />
                          </div>
                        )}
                        {
                          !loadingPage &&
                          <Loading type="spinner" size="20" color="#00E49F">
                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF]">Loading...</span>
                          </Loading>
                        }
                      </div>
                    </>
                  }
                </>
                :
                <div ref={boxRef} onScroll={pageScroll} style={{ alignContent: 'flex-start', overflowX: 'hidden' }} className="flex h-[calc(100vh_-_210px)] mt-[10px] pb-6 overflow-auto items-start flex-wrap gap-[10px] w-full">
                  {cardData.map((el: any) =>
                    <div
                      key={`hero CardItem-${el.id}`}
                      onClick={() => {
                        setPage(1)
                        setActive({ ...active, [levelName(level) as string]: el })
                        setSelectCardsPopup(false)
                      }} className="w-[calc(50%_-_5px)] overflow-hidden relative" >
                      <img className=' absolute left-0 top-[0px] w-6 h-6' src={attributeImage(el.property)} alt="" />
                      <div className={` ${'h-6'} absolute px-[5px] flex items-center justify-center z-10 top-0 right-0`}>
                        <div className=' absolute top-0 left-0 w-full h-full opacity-60' style={{ backgroundImage: `url(${power_bg})`, backgroundSize: '100% 100%' }}></div>
                        <p className={`${el?.bonus_power ? 'text-style-4 pr-[2px]' : 'text-style-7'} !text-[12px] !font-bold`}>{el?.bonus_power ? el?.bonus_power + el?.power : el?.power}</p>
                      </div>
                      {
                        el.id == active[levelName(level) as string]?.id &&
                        <div className=" w-full h-full absolute flex justify-center items-center z-10 bg-[#000]/50">
                          <img className=" w-[115px] " src={selected} alt="" />
                        </div>
                      }
                      <div className=" absolute bottom-[10px] left-[10px] ">
                        <p className=" text-[16px] leading-[19px] max-w-[150px] ellipsis text-[#fff] font-bold ">{el.name}</p>
                        <div className=" flex mt-1 items-center">
                          <img className="w-3" src={like} alt="" /><p className="text-[12px] ml-[2px] mr-[10px] text-[#E0E0E0]">{formatNumber(el.like_count) || 0}</p> <img className="w-3" src={talk} alt="" /><p className="text-[12px] ml-[2px] text-[#E0E0E0]">{formatNumber(el.chat_count) || 0}</p>
                        </div>
                      </div>
                      <img className="w-full rounded-[10px]" src={el.url} alt="" />
                    </div>
                  )}
                  {
                    !loadingPage &&
                    <Loading type="spinner" size="20" color="#00E49F">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF]">Loading...</span>
                    </Loading>
                  }
                </div>
              }

            </div>
            :
            <div className="h-[calc(100vh_-_120px)] w-full">
              <div className=' flex flex-col items-center h-full justify-center'>
                <img className='w-[185px]' src={loading} alt="" />
                <p className='text-[14px] text-[#FFF] mt-[25px] leading-[18px] font-bold'>Not Found</p>
                <ButtonComp width={174} mt={50} title={level == 8 ? 'Create AI Agents' : level == 6 ? 'Mint Card' : 'Buy Cards'} callBack={() => {
                  if (isSaveDeck) {
                    callBackHandle()
                  } else {
                    callBack && callBack()
                    // setShowSaveBtn(true)
                  }

                }}>
                </ButtonComp>
              </div>
            </div>
          }
          {level == 8 && <p style={{ backgroundImage: 'linear-gradient( 135deg, #00E49F 0%, #54ADFF 100%)', textShadow: ' 0px 2px 4px rgba(0,0,0,0.5)' }}
            className="text-[14px] text-center z-20 rounded-[10px] fixed w-[calc(100vw_-_32px)] bottom-8 left-4 text-white leading-[18px] p-[10px]">
            The AI Agent you successfully create will automatically be stored in your hero card pool. You can choose your favorite or most trusted hero cards to enter battle!
          </p>}
        </> :
          <div className=" w-full flex justify-center items-center">
            <Loading type="spinner" size="20" color="#00E49F">
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF]">Loading...</span>
            </Loading>
          </div>
        }
      </div>
      <PromptDialog isClose={true} title={'Attention'} dialog={spendCoins} setDialog={setSpendCoins} callback={() => {
        createHandle()
      }} text={"Create AI Agent will cost 50,000 $STAR."}></PromptDialog>
      <PromptDialog title={'Attention'} dialog={dialog} setDialog={setDialog} callback={() => { setDialog(false) }} text={'Insufficient $STAR'}></PromptDialog>


      <PromptDialog title={'Attention'} dialog={isShowSaveBtn} setDialog={setShowSaveBtn} text={'Do you want to save the deck configuration before leaving the interface?'}>

        <div className="btn flex w-full">
          <ButtonComp width={110} title={'Don\'t save'} callBack={() => { callBackHandle() }}>
          </ButtonComp>
          <ButtonComp width={110} title={'Continue'} callBack={callBack}>
          </ButtonComp>
        </div>

      </PromptDialog>
    </Popup >
  )
}
)
export default SelectCards
