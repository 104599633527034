import { convertNumber, formatNameString, formatNumber } from "../../utils"
import starCard from "@/assets/images/cards/starCard.png"
import pkImg from "@/assets/images/tabbar/battle.png"
import protectImg from "@/assets/images/pk/protect.png"
import progress from "@/assets/images/progress.png"
import bg from "../../assets/images/talk/bg.png"
import CardItem from "../CardItem"
import winImg from "../../assets/images/pk/win.png"
import loseImg from "../../assets/images/pk/lose.png"
import victoryBgImg from "../../assets/images/myInvites/red_bg.png"
import victoryImg from "../../assets/images/pk/victory.png"
import defeatedImg from "../../assets/images/pk/defeated.png"
import defeatedBgImg from "../../assets/images/refining/refiningFailure.png"
import loadingImg from '@/assets/images/cards/loading.png'
import ButtonComp from '../common/ButtonComp'
import Coins from "../redPacket/Coins"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import RedPacket from "../redPacket/RedPacket"
import { type } from '../../contracts/star_ai_NftItem';
import { getBattleCards, getUserInfo } from "@/api/url.js"
import { changeUserInfo } from "../../store/module/tabbar"
import { useDispatch, useSelector } from "react-redux"
import WebApp from "@twa-dev/sdk"
import { changeBattleInfo } from "../../store/module/battle"
import BlurDialog from "../BlurDilaog"

interface PropType {
    recordInfo?: any
    recordResult: any
    closeHandle?: () => void
    type?: string
    back?: string
    loseAudioRef?: any
    winAudioRef?: any
    PK_bgRef?: any
}
const recordDetails = ({ recordInfo, back, PK_bgRef, loseAudioRef, winAudioRef, recordResult, closeHandle, type }: PropType) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const { vibration, settings } = useSelector((state: any) => state.settings)
    const address_params = localStorage.getItem('address_params')
    const [isShowPacket, setShowRedPacket] = useState(false)
    useEffect(() => {
        try {
            // if (PK_bgRef) {
            userInfo()
            // PK_bgRef.pause()
            if (vibration) {
                WebApp.HapticFeedback.impactOccurred('heavy'); // 震动1000毫秒
            }
            recordResult.is_victory ? winAudioRef.play() : loseAudioRef.play()
            // }
        } catch {

        }

    }, [])
    const userInfo = async () => {
        const UserInfo = await getUserInfo(address_params)
        dispatch(changeUserInfo(UserInfo.data))
    }

    return (
        <div className="w-full h-full overflow-auto pb-[50px]">
            {
                recordResult && Object.prototype.toString.call(recordResult) === '[object Object]' ?
                    <>
                        <div className='flex flex-col items-center justify-between rounded-md mt-2 p-[10px] bg-gradient-to-r from-[#00E49F] to-[#54adff]'>
                            <div className="flex justify-between w-full items-center">
                                <div className='flex items-center'>
                                    <img className='w-5 h-5' src={protectImg} alt="" />
                                    <span className='text-white text-base ml-2 text-shadow-lg'>{formatNameString(recordResult.arena_owner_name)}</span>
                                </div>
                                <div className='text-white flex ml-3 text-shadow-lg'>Power:
                                    <p className='ml-1'>
                                        {formatNumber(recordResult.arena_owner_final_power)}
                                    </p>
                                </div>
                            </div>

                            <div className="grid grid-cols-6 gap-2 mt-5">
                                {
                                    recordResult?.arena_decks && recordResult?.arena_decks?.map((el: any, index: number) => {
                                        return (
                                            <div key={index} className="h-[80px] rounded-md overflow-hidden relative">
                                                {el && el.url ?
                                                    <>
                                                        <CardItem is_enhance={true} height={80} width={60} rounded={10} property={el?.property} enhanceLevel={el?.enhance_level} mint_state={el?.mint_state} is_nft={el?.is_nft} id={el?.id} price={el?.price} like={el?.like_count} view={el?.view_count} state={el?.state} mine={1} no={`No. ${convertNumber(el?.seq)}`} image={el?.url} isShowLevel={el?.is_character ? false : true} level={el?.is_official ? 6 : el?.card_level + 1} text={""}>
                                                            <div className="absolute w-full h-full flex justify-center items-center top-0 left-0 z-[10]">
                                                                {el && <img className="w-10 h-10" src={el?.is_victory ? winImg : loseImg} alt="" />}
                                                            </div>
                                                        </CardItem>
                                                        {
                                                            (settings.nsfw_pay_type != 2 || !settings.star_nsfw) && el.is_nsfw && <BlurDialog isShowEyes={false} callback={() => {

                                                            }} eyesIsSmall></BlurDialog>
                                                        }
                                                    </>
                                                    :
                                                    <div className=" relative flex justify-center items-center">
                                                        <img className="w-10 absolute h-10" src={el?.is_victory ? winImg : loseImg} alt="" />
                                                        {/* <div className=" text-[14px] text-[#a6a6a6] z-10 leading-[18px] left-2 absolute bottom-7"> None </div> */}
                                                        <img className=" w-[60px] h-[80px] " src={starCard} alt="" />
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                }

                            </div>

                        </div>


                        <div className="flex justify-center items-center my-4">
                            <div className="w-[90px] h-10 bg-gradient-to-r from-[#00E49F] to-[#54adff] rounded-md flex justify-center items-center">
                                <span className="text-[30px] font-bold">{recordResult?.arena_owner_victory}</span>
                            </div>
                            <span className={`inline-block px-2 mx-5 italic text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF]`}>VS</span>
                            <div className="w-[90px] h-10 rounded-md flex justify-center items-center" style={{ background: 'linear-gradient( 135deg, #65A2FC 0%, #8BBDFC 45%, #609EFF 100%)' }}>
                                <span className="text-[30px] font-bold">{recordResult?.challenger_victory}</span>
                            </div>
                        </div>

                        <div className='flex flex-col items-center justify-between rounded-md mt-2 p-[10px]' style={{ background: 'linear-gradient( 135deg, #65A2FC 0%, #8BBDFC 45%, #609EFF 100%)' }}>
                            <div className="flex justify-between w-full items-center">
                                <div className='flex items-center'>
                                    <img className='w-5 h-5' src={pkImg} alt="" />
                                    <span className='text-white text-base ml-2 text-shadow-lg'>{formatNameString(recordResult.challenger_name)}</span>
                                </div>
                                <div className='text-white flex ml-3 text-shadow-lg'>Power:
                                    <p className='ml-1'>
                                        {formatNumber(recordResult.challenger_final_power)}
                                    </p>
                                </div>
                            </div>

                            <div className="grid grid-cols-6 gap-2 mt-5">
                                {
                                    recordResult?.challenger_decks && recordResult?.challenger_decks?.map((el: any, index: number) => {
                                        return (
                                            <div key={index} className="h-[80px] rounded-md overflow-hidden relative">
                                                {el && el.url ?
                                                    <>
                                                        <CardItem is_enhance={true} height={80} width={60} rounded={10} property={el?.property} enhanceLevel={el?.enhance_level} mint_state={el?.mint_state} is_nft={el?.is_nft} id={el?.id} price={el?.price} like={el?.like_count} view={el?.view_count} state={el?.state} mine={1} no={`No. ${convertNumber(el?.seq)}`} image={el?.url} isShowLevel={el?.is_character ? false : true} level={el?.is_official ? 6 : el?.card_level + 1} text={""}>
                                                            <div className="absolute w-full h-full flex justify-center items-center top-0 left-0 z-[10]">
                                                                {el && <img className="w-10 h-10" src={el?.is_victory ? winImg : loseImg} alt="" />}
                                                            </div>
                                                        </CardItem>
                                                        {
                                                            (settings.nsfw_pay_type != 2 || !settings.star_nsfw) && el.is_nsfw && <BlurDialog isShowEyes={false} callback={() => { }} eyesIsSmall></BlurDialog>
                                                        }
                                                    </>
                                                    :
                                                    <div className=" relative flex justify-center items-center">
                                                        <img className="w-10 absolute h-10" src={el?.is_victory ? winImg : loseImg} alt="" />
                                                        {/* <div className=" text-[14px] text-[#a6a6a6] z-10 leading-[18px] left-2 absolute bottom-7"> None </div> */}
                                                        <img className=" w-[60px] h-[80px] " src={starCard} alt="" />
                                                    </div>
                                                }

                                            </div>
                                        )
                                    })
                                }

                            </div>

                        </div>

                        {
                            recordResult?.is_victory && <>
                                <div className="flex justify-center relative">
                                    <img className="animate-rotate w-[156px]" src={victoryBgImg} alt="" />
                                    <img className="w-[236px] h-[104px] absolute top-3" src={victoryImg} alt="" />
                                </div>
                                <div className="flex flex-col items-center">
                                    <span className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF]">Congratulations!</span>
                                    {back != 'peak' && <span className="text-white my-2">You Win</span>}
                                    {back != 'peak' ? <>
                                        {
                                            recordResult?.reward_type == 1 && recordResult?.reward_card_url &&
                                            <div className="w-[65px] h-[88px] relative">

                                                <CardItem is_enhance={true} height={88} rounded={18} width={65} mine={1} image={recordResult?.reward_card_url} level={recordResult?.reward_card_level + 1}>

                                                </CardItem>

                                            </div>
                                        }
                                        {
                                            recordResult?.reward_type != 1 && <Coins count={recordResult?.reward_coins} type={!recordResult.is_victory ? '-' : '+'} direction="col"></Coins>
                                        }
                                    </> :
                                        <div className=" flex mt-5 justify-center gap-[10px] items-center">
                                            <div style={{ border: '2px solid #00E49F' }} className="bg-[#FFFFFF] rounded-[10px] px-1 flex justify-center items-center h-10 min-w-[90px]">
                                                <p className=" text-style-1 !text-[20px] leading-6 font-bold ">No.{recordResult.before_rank + 1}</p>
                                            </div>
                                            <img className="w-[94px]" src={progress} alt="" />
                                            <div style={{ border: '2px solid #00E49F' }} className="bg-[#FFFFFF] rounded-[10px] px-1 flex justify-center items-center h-10 min-w-[90px]">
                                                <p className=" text-style-1 !text-[20px] leading-6 font-bold ">No.{recordResult.after_rank + 1}</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        }

                        {
                            !recordResult?.is_victory && <>
                                <div className="flex justify-center relative">
                                    <img className="animate-rotate w-[156px]" src={defeatedBgImg} alt="" />
                                    <img className="w-[236px] h-[60px] absolute top-12" src={defeatedImg} alt="" />
                                </div>
                                <div className="flex flex-col items-center">
                                    <span className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF]">Keep up the good work</span>
                                    {back != 'peak' && <span className="text-white my-2">You Lose</span>}
                                    {back != 'peak' ? <>
                                        {
                                            recordResult?.reward_type == 1 && recordResult?.reward_card_url &&
                                            <div className="w-[65px] h-[88px] relative">

                                                <CardItem is_enhance={true} height={88} rounded={18} width={65} mine={1} image={recordResult?.reward_card_url} level={recordResult?.reward_card_level + 1}>

                                                </CardItem>

                                            </div>
                                        }
                                        {
                                            recordResult?.reward_type != 1 && <Coins count={recordResult?.reward_coins} type="-" direction="col"></Coins>
                                        }
                                    </> :
                                        <div className=" flex justify-center gap-[10px] mt-5 items-center">
                                            <div style={{ border: '2px solid #00E49F' }} className="bg-[#FFFFFF] rounded-[10px] px-1 flex justify-center items-center h-10 min-w-[90px]">
                                                <p className=" text-style-1 !text-[20px] leading-6 font-bold ">No.{recordResult.before_rank + 1}</p>
                                            </div>
                                            <img className="w-[94px]" src={progress} alt="" />
                                            <div style={{ border: '2px solid #00E49F' }} className="bg-[#FFFFFF] rounded-[10px] px-1 flex justify-center items-center h-10 min-w-[90px]">
                                                <p className=" text-style-1 !text-[20px] leading-6 font-bold ">No.{recordResult.after_rank + 1}</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        }

                        <div className="btn mt-5 fixed bottom-8 left-[50%] -translate-x-[50%]">
                            {type == "1" ?
                                <ButtonComp title="OK" callBack={() => {
                                    if (back == 'peak') {
                                        navigate("/peaks-battle")
                                        return
                                    }
                                    if (back == 'PKReplay') {
                                        navigate("/pk-records")
                                        return
                                    }
                                    if (recordResult?.reward_type != 1) {
                                        navigate("/pk")
                                    } else {
                                        if (recordResult.is_victory) {
                                            setShowRedPacket(true)
                                        } else {
                                            navigate("/pk")
                                        }
                                    }

                                }}></ButtonComp>
                                :
                                <ButtonComp title="PK Replay"
                                    callBack={() => {
                                        dispatch(changeBattleInfo(recordResult))
                                        navigate('/battle-result?type=PKReplay')
                                    }}>
                                </ButtonComp>
                            }
                        </div>
                    </>
                    :
                    <div className={'w-[100%] h-[calc(100vh_-_120px)] py-4 flex flex-col items-center'}>
                        <img className="w-[225px] mt-10" src={loadingImg}></img>
                        <p className='text-[14px] text-[#FFF] mt-[25px] leading-[18px] font-bold'>
                            Record Retrieval Failed</p>
                        <ButtonComp title="OK" mt={40} callBack={() => {
                            if (back == 'peak') {
                                navigate("/peaks-battle")
                                return
                            } else {
                                closeHandle && closeHandle()
                            }
                        }}></ButtonComp>
                    </div>

            }

            {
                isShowPacket && <RedPacket cardInfo={{
                    level: recordResult?.reward_card_level,
                    url: recordResult?.reward_card_url,
                    power: recordResult?.reward_card_power,
                    property: recordResult?.reward_card_property

                }}></RedPacket>
            }
        </div >
    )
}


export default recordDetails