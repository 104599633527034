
import { useEffect, useRef, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { setCurrCharacterInfo, pushChatListId } from '../../store/module/progress';
import { logsListApi, logsDetailsApi, getAvatarDetails, postLogsLike, postSetting, getSetting, } from '@/api/url.js'
import BScroll from 'better-scroll'
import loading from '@/assets/images/cards/loading.png'
import { List, Toast } from 'react-vant';


import like from "@/assets/images/like.png"
import Chars from "@/components/Chat.tsx"
import ShareLog from '../talk/ShareLog';
import NsfwPay from '../../components/nsfw/nsfwPay';
import { changeSettings } from '../../store/module/settins';
import PromptDialog from '../../components/PromptDialog';
import WebApp from '@twa-dev/sdk';
import { postWarningInformation } from "@/api/url.js"

interface Props {
    searchValue?: string
}

const Logs = ({ searchValue = "", }: Props) => {
    const [finished, setFinished] = useState(false);
    const isInitLoading = useRef(true)
    const [isLoading, setIsLoading] = useState(false)
    const chatMessageList = useSelector((state: any) => state.progress.chatList)
    const currPage = useRef(1)
    const total = useRef(0);
    const [logsLength, setLogsLength] = useState(0)
    const [isShare, setIsShare] = useState(false)
    const [charList, setCharList] = useState<any>([])
    const [shareLogs, setShareLogs] = useState<any>([])
    const [avatarID, setAvatarID] = useState("")
    const [sessionID, setSessionID] = useState("")
    const [ids, setIds] = useState<any>([])
    const [starType, setStateType] = useState<any>("")
    const [coin_NSFW, setCoin_NSFW] = useState(false)
    const [text, setText] = useState<any>('')
    const [promptText, setPromptText] = useState<any>('')
    const [dialog, setDialog] = useState(false)
    const dispatch = useDispatch()
    const { settings, isShowNewUserGiftDialog } = useSelector((state: any) => state.settings)
    const currLogs = useRef<any>(null)
    useEffect(() => {
        // setFinished(false)
        setShareLogs([])
        currPage.current = 1
        getLogsList()
        if (!searchValue) {

        } else {
            setFinished(true)
            setFinished(true)
        }
    }, [searchValue])

    useEffect(() => {
        const ids = shareLogs.length && shareLogs.map((item: any) => item.character_id)
        setIds(ids)
        const listId = chatMessageList.map((item: any) => item.id)

        for (let i = 0; i < ids.length; i++) {
            if (!listId.includes(ids[i])) {
                dispatch(pushChatListId({ id: ids[i], list: [], actionList: [] }))
            }
        }

    }, [shareLogs])


    //get logs
    const getLogsList = async () => {
        const params = {
            limit: 20,
            page: currPage.current,
            query: searchValue
        }

        setIsLoading(true)
        const { code, data } = await logsListApi(params)
        if (code == 0 && data) {
            setIsLoading(false)
            total.current = data.count
            isInitLoading.current = false
            setLogsLength(total.current)
            // setFinished(true)
            setFinished(false)
            data.logs = data.logs.filter((it: any) => it)
            data.logs = data.logs.map((item: any) => {
                return {
                    ...item,
                    msgs: item?.msgs?.map((el: any, index: number) => {
                        return {
                            direction: index == 0 ? "right" : "left",
                            content: el
                        }
                    })
                }
            })
            setShareLogs((prevData: any) => [...prevData, ...data.logs]);
            if (data.logs.length == 0) {
                setFinished(true)
            }
        } else {
            setFinished(true)
            total.current = 0
        }
        if (currPage.current * 20 >= total.current) {
            setFinished(true)
            isInitLoading.current = true
        }
    }
    //run nsfw logic handler
    const nsfwHandle = async () => {
        try {
            let ID = window.Telegram.WebApp.initDataUnsafe?.user?.id || WebApp.initDataUnsafe?.user?.id || window.localStorage.getItem("userId") || "";
            if (!ID) {
                postWarningInformation({
                    platform: JSON.stringify({
                        telegram: window.Telegram.WebApp.platform,
                        webApp: WebApp.platform,
                    }),
                    version: JSON.stringify({
                        telegram: window.Telegram.WebApp.version,
                        webApp: WebApp.version,
                    })
                    ,
                    initData: JSON.stringify({
                        telegram: window.Telegram.WebApp.initData,
                        webApp: WebApp.initData,
                    }),
                    telegram: JSON.stringify(window.Telegram.WebApp.initDataUnsafe),
                    webApp: JSON.stringify(WebApp.initDataUnsafe),
                    localStorage: window.localStorage.getItem("userId"),
                    interfaceName: 'getSetting',
                })
                ID = window.Telegram.WebApp.initDataUnsafe?.user?.id || WebApp.initDataUnsafe?.user?.id || window.localStorage.getItem("userId") || "";
            }
            const { code, data } = await getSetting(ID)
            // console.log(res)
            if (code == 0) {
                if (data.character_nsfw) {

                } else {
                    setStateType({ character_nsfw: true })
                    setCoin_NSFW(true)
                }
            }
        } catch (e) {
            console.log(e)
        }

    }
    //get logs details
    const getLogsDetails = async (item: any) => {
        currLogs.current = item
        if (item.is_nsfw) {
            // await nsfwHandle()
            if (!settings.character_nsfw) {
                setStateType({ character_nsfw: true })
                setCoin_NSFW(true)
                return
            }
            await intoLoadPageHandle(item)
        } else {
            await intoLoadPageHandle(item)
        }

    }

    //into load page
    const intoLoadPageHandle = async (item: any) => {
        setAvatarID(item.character_id)
        setSessionID(item.session_id)
        await getAvatarDetailsHandle(item.character_id)
        Toast.loading({
            message: "Loading...",
            forbidClick: true,
        })
        try {
            const { code, data } = await logsDetailsApi(item.session_id)
            Toast.clear()
            if (code == 0) {
                const list = data.msgs.map((item: any, index: number) => {

                    return {
                        direction: index % 2 == 0 ? "right" : "left",
                        content: item
                    };
                })
                setCharList(list)
            }
            // console.log("🚀 ~ getLogsDetails ~ res:", res)
        } catch (e) {
            Toast.clear()
        }

        setIsShare(true)
    }
    //  get Avatar Details
    const getAvatarDetailsHandle = async (id: string) => {
        const { data, code } = await getAvatarDetails(id)
        if (code == "0") {
            dispatch(setCurrCharacterInfo(data))
        }
    }

    const onLoad = async () => {
        if (finished || isInitLoading.current) return;
        currPage.current++
        await getLogsList()

    }
    const [likeLoading, setLikeLoading] = useState(false)
    const setLogsLike = async (id: any, index: number) => {
        if (!likeLoading) {
            setLikeLoading(true)
            try {
                const { code } = await postLogsLike(id)
                setLikeLoading(false)
                if (code == '0') {
                    setShareLogs(
                        shareLogs.map((item: any, i: number) => {
                            let newItem = item
                            if (i == index) {
                                newItem.like_count++
                            }
                            return newItem
                        })
                    )
                }
            } catch {
                setLikeLoading(false)
            }
        }
    }
    return (
        <div className="role-box character w-full h-full text-whitebox-border flex flex-wrap relative justify-between items-start pb-[0px] overflow-auto pt-[12px]">

            <List className='list w-full flex flex-wrap justify-between' finished={finished} immediateCheck={false} onLoad={onLoad} loadingText="Loading...">
                {
                    shareLogs && shareLogs.length > 0 && shareLogs.map((item: any, index: number) => {
                        return (
                            <div key={item.session_id + index} className='relative rounded-xl w-full h-[160px] mb-[12px] overflow-hidden' onClick={() => { getLogsDetails(item) }}>
                                <div className='h-full w-[50%] bg-cover absolute right-0 top-0' style={{ backgroundImage: `url(${item.image_url})` }}>
                                </div>

                                {/* <div className='h-full w-[50%] bg-cover absolute right-0 top-0' style={{ backgroundImage: `url(${bg})` }}></div> */}
                                <div className='absolute top-0 left-0 w-full h-full' style={{ background: "linear-gradient( 267deg, rgba(238,238,238,0) 0%, rgba(76,115,78,0.97) 50%, rgba(87,255,61,0.5) 100%)" }}></div>
                                <div className="message-like absolute top-2 left-2 flex">
                                    <div className='px-[10px] py-[4px] bg-[#000]/40 text-white rounded-[30px] text-xs flex justify-center items-center'>{item.msgs_count} messages</div>
                                    <div className='px-[10px] py-[4px] bg-[#000]/40 text-white rounded-[30px] text-xs flex justify-center items-center mx-2'>{item.load_count} loads</div>
                                    <div onClick={(e) => {
                                        e.stopPropagation()
                                        setLogsLike(item.session_id, index)
                                    }} className='px-[10px] py-[4px] bg-[#000]/40 rounded-[30px] flex justify-center items-center'>
                                        <img className='w-4 h-4' src={like} alt="" />
                                        <span className='text-white text-xs ml-2'>{item.like_count}</span>
                                    </div>
                                </div>
                                <div className='message flex flex-col justify-around p-4 mt-6'>
                                    {
                                        item.msgs && item.msgs.map((chat: any, index: number) => {
                                            return <Chars info={chat} content={chat.content} isAvatar={true} isShowVoice={false} key={index} main_url={item.main_url}></Chars>
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
                {
                    !isLoading && shareLogs.length == 0 &&
                    <div className={'w-[100%] h-[calc(100vh_-_120px)] py-4 flex flex-col items-center'}>
                        <img className="w-[225px] mt-10" src={loading}></img>
                        <p className='text-[14px] text-[#FFF] mt-[25px] leading-[18px] font-bold'>Share Chat Logs and earn rewards from users!</p>
                    </div>
                }
            </List>


            {
                isShare && <ShareLog id={avatarID} sessionID={sessionID} charList={charList} callback={() => { setIsShare(false) }} type="list" ></ShareLog>
            }

            {
                coin_NSFW && <NsfwPay type={15} closeHandle={async (val) => {
                    setCoin_NSFW(false)
                    if (!val) {
                        return;
                    }
                    try {
                        const result = await postSetting(localStorage.getItem('userId'), { ...settings, ...starType, order_id: val })
                        if (result.code == 0) {
                            dispatch(changeSettings({ ...settings, ...starType }))
                        } else {
                            setText('Attention')
                            setPromptText('Network error')
                            setDialog(true)
                        }
                        let ID = window.Telegram.WebApp.initDataUnsafe?.user?.id || WebApp.initDataUnsafe?.user?.id || window.localStorage.getItem("userId") || "";
                        if (!ID) {
                            postWarningInformation({
                                platform: JSON.stringify({
                                    telegram: window.Telegram.WebApp.platform,
                                    webApp: WebApp.platform,
                                }),
                                version: JSON.stringify({
                                    telegram: window.Telegram.WebApp.version,
                                    webApp: WebApp.version,
                                })
                                ,
                                initData: JSON.stringify({
                                    telegram: window.Telegram.WebApp.initData,
                                    webApp: WebApp.initData,
                                }),
                                telegram: JSON.stringify(window.Telegram.WebApp.initDataUnsafe),
                                webApp: JSON.stringify(WebApp.initDataUnsafe),
                                localStorage: window.localStorage.getItem("userId"),
                                interfaceName: 'getSetting',
                            })
                            ID = window.Telegram.WebApp.initDataUnsafe?.user?.id || WebApp.initDataUnsafe?.user?.id || window.localStorage.getItem("userId") || "";
                        }
                        const setting = await getSetting(ID)
                        if (setting.code == 0) {
                            dispatch(changeSettings(setting.data))
                            await intoLoadPageHandle(currLogs.current)
                        } else {
                            setText('Attention')
                            setPromptText('Network error')
                            setDialog(true)
                        }
                    } catch {
                        setText('Attention')
                        setPromptText('Network error')
                        setDialog(true)
                    }
                }


                }></NsfwPay>
            }
            <PromptDialog title={text} dialog={dialog} setDialog={setDialog} callback={() => { setDialog(false) }} text={promptText}></PromptDialog>

        </div>
    )
}


export default Logs;