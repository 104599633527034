
import request from '@/utils/axios-req'
import { data } from 'autoprefixer'


//  获取矿池信息
export const getPools = () => {
    return request({
        url: `/api/v1/miniapp/mining/pools`,
        method: 'get',
    })
}

//  收益查询
export const getProfits = (type) => {
    return request({
        url: `/api/v1/miniapp/mining/profits`,
        method: 'get',
        data: {
            type: type
        }
    })
}
//  获取挖矿排行
export const getMiningRanks = (type) => {
    return request({
        url: `/api/v1/miniapp/mining/rank`,
        method: 'get',
        data: {
            type: type
        }

    })
}
//  获取加入普通池支付
export const postStandardPay = () => {
    return request({
        url: `/api/v1/miniapp/mining/standard_pay`,
        method: 'post',

    })
}

//  获取加入NFT池支付
export const postNftPoolPay = () => {
    return request({
        url: `/api/v1/miniapp/mining/nft_pool`,
        method: 'post',
    })
}

//  上传挖矿卡牌
export const postMiningCards = (data) => {
    return request({
        url: `/api/v1/miniapp/mining/account`,
        method: 'post',
        data: {
            type: data.type,
            cards: data.cards,
            avatars: data.avatars
        }
    })
}

//  获取挖矿卡牌
export const getMiningCards = (type) => {
    return request({
        url: `/api/v1/miniapp/mining/deck?type=${type}`,
        method: 'get',
    })
}

// 获取vip矿池支付设置
export const getMiningPayLink = () => {
    return request({
        url: `/api/v1/miniapp/mining/pay_link_config`,
        method: 'get',
    })
}

// 获取vip矿池支付校验
export const getMiningPayCheck = (type) => {
    return request({
        url: `/api/v1/miniapp/mining/pay_check${type}`,
        method: 'post',
    })
}

// 获取支付的配置
export const getAcceleratorConfig = (type) => {
    return request({
        url: `/api/v1/miniapp/mining/config`,
        method: 'get',
        data: {
            type
        }
    })
}

// 获取加速包的支付连接
export const getAcceleratorPayLink = (params) => {
    const { title = "Accelerator Card", description = "Do you want to buy Accelerator Card in TestStarAI for 18 stars?", total, type, relevance = "", id } = params
    return request({
        url: `/api/v1/miniapp/mining/accelerator_pay_link?config_id=${id}&title=${title}&description=${description}&total=${total}&type=${type}&relevance=${relevance}`,
        method: 'get',
    })
}

// 加速包的支付校验
export const postAcceleratorPaycheck = (data) => {
    return request({
        url: `/api/v1/miniapp/mining/accelerator_pay_check`,
        method: 'post',
        data: data
    })
}


//
export const getMiningPoolList = (code) => {
    return request({
        url: `/api/v1/miniapp/mining/pools?code=${code}`,
        method: 'get'
    })
}

//矿池未读查询
export const getMiningMiningUnread = () => {
    return request({
        url: `/api/v1/miniapp/mining/mining_unread`,
        method: 'get'
    })
}

//一键质押
export const postMiningAuto = (type,is_avatar) => {
    return request({
        url: `/api/v1/miniapp/mining/auto`,
        method: 'post',
        data: {
            type: type,
            is_avatar: is_avatar
        }

    })
}

