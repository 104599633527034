import { useNavigate, useSearchParams } from 'react-router-dom'
import fireImg from '@/assets/images/mining/fire.png'
import speedwhiteImg from '@/assets/images/mining/speedwhite.png'
import starImg from '@/assets/images/rules/star.png'
import cardImg from '@/assets/images/tabbar/card.png'
import avatarImg from '@/assets/images/tabbar/ai_active.png'
import addImg from '@/assets/images/tabbar/level_logo.svg'
import add from "@/assets/images/deck/add.png"
import del from '@/assets/images/del.svg'
import power_bg from '@/assets/images/mining/mint-card.png'
import Back from '../../components/common/BackComp'
import tipImg from "@/assets/images/attribute/tip.svg"
import RulesDialog from '../../components/RulesDialog'
import { miningRules } from '../../text/rulesText'
import { useEffect, useMemo, useRef, useState } from 'react'
import avatarBgImg from '@/assets/images/mining/mint-avatar.png'
import cardBgImg from '@/assets/images/mining/mint-card.png'
import Search from '../search/Index'
import { Popup, Toast } from 'react-vant'
import CardItem from '../../components/CardItem'
import { attributeImage, convertNumber } from '../../utils'
import ButtonComp from '../../components/common/ButtonComp'
import Character from '../character/Character'
import { postMiningCards, getMiningCards, postMiningAuto } from '@/api/mining'
import PromptDialog from '../../components/PromptDialog'
import { useSelector } from 'react-redux'



const MyMining = () => {
  const navigate = useNavigate()
  const userInfo = useSelector((state: any) => state.tabbar.userInfo)
  const [searchParams] = useSearchParams()
  const typeParams: any = Number(searchParams.get("type") as string)
  const [rulesDialog, setRulesDialog] = useState(false)
  const [promptDialog, setPromptDialog] = useState(false)
  const [visible, setVisible] = useState(false)
  const [isOK, setIsOK] = useState(false)
  const [promptText, setPromptText] = useState('')
  const [type, setType] = useState(0)
  const [cardArray, setCardArray] = useState<any>([])
  const [cardArrayP, setCardArrayP] = useState<any>([])
  const [avatarArray, setAvatarArray] = useState<any>([])
  const [avatarArrayP, setAvatarArrayP] = useState<any>([])

  const [cardArrayOld, setCardArrayOld] = useState<any>([])
  const [avatarArrayOld, setAvatarArrayOld] = useState<any>([])
  const [active, setActive] = useState(0)
  const [cardList, setCardList] = useState<any>(cardArray)
  const [totalPower, setTotalPower] = useState(0)
  const [bonus, setBonus] = useState(0)
  const postCards = async () => {
    const cardArr = cardArray.map((el: any) => el?.id)
    const avatarArr = avatarArray.map((el: any) => el?.id)
    const { msg, code } = await postMiningCards(
      {
        type: typeParams,
        cards: cardArr.filter((item: any) => item != null),
        avatars: avatarArr.filter((item: any) => item != null),
      })
    if (code == '0') {
      setCardArrayOld(cardArray)
      setAvatarArrayOld(avatarArray)
      setPromptText('Cards & Agents Staking Successful!')
      setPromptDialog(true)
      setIsOK(false)
    } else {
      setPromptText('Network Error.')
      setPromptDialog(true)
    }
  }
  const isLoading = useRef(false)
  const getCards = async () => {
    isLoading.current = true
    const toast = Toast.loading({
      message: "Loading",
      duration: 0,
      forbidClick: false
    })
    const { data, msg, code } = await getMiningCards(typeParams)
    isLoading.current = false
    toast.clear()
    if (code) {

      setCardArray([...data.cards.concat(Array(18 - data.cards.length).fill(null))])
      setCardList([...data.cards.concat(Array(18 - data.cards.length).fill(null))])
      setAvatarArray([...data.avatars.concat(Array(18 - data.avatars.length).fill(null))])
      setCardArrayOld([...data.cards.concat(Array(18 - data.cards.length).fill(null))])
      setAvatarArrayOld([...data.avatars.concat(Array(18 - data.avatars.length).fill(null))])
      let power = 0, bonusPower = 0
      let bus = 0

      data.cards.forEach((el: any) => {
        if (el?.nft_sn == 1) {
          el ? power += ((el?.power + el.bonus_power) * 1.2) : 0
          el ? bus += ((el?.power + el.bonus_power) * 1.2 * 3) : 0
        } else {
          if (el && el.is_nft) {
            el ? bus += ((el?.power + el.bonus_power) * 3) : 0
          } else {
            el ? bus += (el?.power + el.bonus_power) : 0
          }
          el ? power += (el?.power + el.bonus_power) : 0
        }
      });
      data.avatars.forEach((el: any) => {
        if (el && el.mint_state == 3) {
          el ? bus += ((el?.power + el.bonus_power) * 3) : 0
        } else {
          el ? bus += (el?.power + el.bonus_power) : 0
        }
        el ? bonusPower += (el?.power + el.bonus_power) : 0
      });
      setBonus(bus)
      power += bonusPower + Math.floor((bonusPower * (characterBonus / 100)))
      setTotalPower(power)
    } else {
    }
  }
  // const getCardsP = async () => {
  //   const { data, code } = await getMiningCards(typeParams ? 0 : 1)
  //   if (code == '0') {
  //     setCardArrayP([...data.cards.concat(Array(18 - data.cards.length).fill(null))])
  //     setAvatarArrayP([...data.avatars.concat(Array(18 - data.avatars.length).fill(null))])
  //   } else {

  //   }
  // }
  useEffect(() => {
    try {
      getCards()
      // getCardsP()
    } catch {
    }
  }, [])

  const [characterBonus] = useState<number>(userInfo.character_staking_bonus / 10)


  const computeTotalPower = () => {
    let power = 0, bonusPower = 0
    let bus = 0
    cardArray.forEach((el: any) => {
      if (el?.nft_sn == 1) {
        el ? power += ((el?.power + el.bonus_power) * 1.2) : 0
        el ? bus += ((el?.power + el.bonus_power) * 1.2 * 3) : 0
      } else {
        if (el && el.is_nft) {
          el ? bus += ((el?.power + el.bonus_power) * 3) : 0
        } else {
          el ? bus += (el?.power + el.bonus_power) : 0
        }
        el ? power += (el?.power + el.bonus_power) : 0
      }
    });
    avatarArray.forEach((el: any) => {
      if (el && el.mint_state == 3) {
        el ? bus += ((el?.power + el.bonus_power) * 3) : 0
      } else {
        el ? bus += (el?.power + el.bonus_power) : 0
      }
      el ? bonusPower += (el?.power + el.bonus_power) : 0

    });
    setBonus(bus)
    power += bonusPower + Math.floor((bonusPower * (characterBonus / 100)))
    setTotalPower(power)
  }


  useEffect(() => {
    computeTotalPower()
  }, [avatarArray, cardArray])
  const miningAuto = async () => {
    try {
      const { data, code } = await postMiningAuto(typeParams, !!type)
      if (code == '0') {
        if (type) {
          setAvatarArray(() => [...data.avatars.concat(Array(18 - data.avatars.length).fill(null))])
          setCardList(() => [...data.avatars.concat(Array(18 - data.avatars.length).fill(null))])
        } else {
          setCardArray(() => [...data.cards.concat(Array(18 - data.cards.length).fill(null))])
          setCardList(() => [...data.cards.concat(Array(18 - data.cards.length).fill(null))])
        }
      }

    } catch (e) {
      console.log("🚀 ~ miningAuto ~ e:", e)

    }

  }
  return (
    <div className='px-4 relative h-[100vh] overflow-x-hidden overflow-y-auto pt-[53px] pb-[90px]'>
      <div className='fixed z-[102]'>
        <Back background='#000' position={true} title={'My Mining'} callback={() => {
          visible ? setVisible(false) : navigate(-1)
        }}></Back>
      </div>
      <img src={tipImg} onClick={() => {
        setRulesDialog(true)
      }} className=' fixed top-3 z-[1001] right-4' />
      <div className="w-full h-[100px] grid grid-cols-2 gap-2">
        <div className='h-full bg-gradient-to-r from-[#00E49F] to-[#54ADFF] rounded-[20px] p-[10px] flex flex-col'>
          <div style={{ textShadow: ' 0px 2px 4px rgba(0,0,0,0.5)' }} className=' text-[14px] leading-[18px] text-[#fff] font-semibold flex items-end'>
            <img className='w-[16px] mr-2' src={fireImg} alt="" />
            Total Power:&nbsp;

          </div>
          <div className='w-full text-center mt-[12px]'>
            <span style={{ textShadow: ' 0px 2px 4px rgba(0,0,0,0)' }} className='text-[30px] leading-[38px] font-bold text-style-3'>
              {Math.floor(Number(totalPower))}
            </span>
          </div>
        </div>

        <div className='h-full bg-gradient-to-r from-[#00E49F] to-[#54ADFF] rounded-[20px] p-[10px] flex flex-col'>
          <div style={{ textShadow: ' 0px 2px 4px rgba(0,0,0,0.5)' }} className=' text-[14px] leading-[18px] text-[#fff] font-semibold flex items-end'>
            <img className='w-[18px] mr-2' src={speedwhiteImg} alt="" />
            Efficiency:&nbsp;

          </div>
          <div className='w-full text-center mt-[12px]'>
            <span style={{ textShadow: ' 0px 2px 4px rgba(0,0,0,0)' }} className='text-[30px] leading-[38px] font-bold text-style-3'>
              {Math.floor(bonus / 150)}%
            </span>
          </div>
        </div>
      </div>
      <div className='flex items-start mt-2'>
        <img className='w-[14px] h-[13px] mt-[2px]' src={starImg} alt="" />
        <p className='text-white text-[14px] flex pl-1'>
          {typeParams == 2 ? 'Each 50 points of power, mining efficiency can be accelerated by 1%. Increasing mining efficiency allows you to earn more $SAI.' : 'Each 50 power points for NFTs and each 150 power points for other Cards and Agents, mining efficiency can be accelerated by 1%. Increasing mining efficiency allows you to earn more $SAI.'}
        </p>
      </div>

      {typeParams == 2 && <div className='flex items-start mt-2'>
        <img className='w-[14px] h-[13px] mt-[2px]' src={starImg} alt="" />
        <p className='text-white text-[14px] pl-1'>Phase One NeonPunk NFT cards will receive a <span className='text-[14px] text-style-1'>20%</span> combat power staking bonus.
        </p>
      </div>}

      <div className={`mt-[30px] grid grid-cols-2 rounded-[10px] h-[36px] bg-[#333]`}>
        <div onClick={() => {
          setCardList(cardArray)
          setType(0)
        }} className={`${type ? 'text-[#a6a6a6]' : 'text-[#fff] bg-gradient-to-r from-[#00E49F] to-[#54ADFF]'} rounded-[10px] justify-center  flex items-center`}>
          <img className='w-[18px] h-[18px] mr-2' src={cardImg} alt="" />
          {typeParams != 2 ? 'Cards' : 'Card NFTs'}
        </div>
        <div onClick={() => {
          if (isLoading.current) {
            return false
          }
          setCardList(avatarArray)
          setType(1)
        }} className={`${type ? 'text-[#fff] bg-gradient-to-r from-[#00E49F] to-[#54ADFF]' : 'text-[#a6a6a6]'} rounded-[10px] flex justify-center items-center`}>
          <img className='w-[18px] h-[18px] mr-2' src={avatarImg} alt="" />
          {typeParams != 2 ? 'Agents' : 'Agent NFTs'}

          <div className={`${characterBonus > 0 ? '' : 'hidden'} ml-3 relative h-full flex items-center`}>
            {!type ? <img className='w-5 h-5' src={addImg} alt="" /> : <div className='iconfont text-[20px]'>&#xe612;</div>}

            <div style={{
              height: '16px',
              padding: "0px 4px",
              background: 'linear-gradient( 135deg, #FFE600 0%, #D18C00 100%)',
              borderRadius: '7px 7px 7px 0px'
            }}
              className={`font-semibold flex items-center text-[12px] z-[99] leading-[12px] absolute -top-[10px] -right-5 text-[#fff]`}>
              +{characterBonus}%
            </div>
          </div>
        </div>
      </div>
      <div className='mt-5 flex justify-between gap-y-5 flex-wrap'>
        {cardList.map((el: any, index: any) =>
          <div key={index} className='w-[28%] relative'>
            <div onClick={() => {
              setActive(index)
              setVisible(true)
            }}>
              {!el ?
                <div>
                  <img src={type == 0 ? cardBgImg : avatarBgImg} alt="" />
                </div> : <>
                  {
                    !type ?
                      <div className='w-[100%] relative'>
                        <CardItem cardType={el.card_type} rounded={10} height={0} bonusPower={el?.bonus_power} power={el.power} property={el.property} enhanceLevel={el.enhance_level} mint_state={el.mint_state} is_nft={el.is_nft} id={el.id} price={el.price} like={el.like_count} view={el.view_count} state={el.state} mine={1} no={`No. ${convertNumber(el.seq)}`} image={el.image} level={el.is_official ? 6 : el.level + 1}>
                        </CardItem>
                        <img onClick={(e) => {
                          e.stopPropagation()
                          setIsOK(true)
                          let arr = cardArray
                          arr[index] = null
                          setCardArray([...arr])
                          setCardList([...arr])
                          computeTotalPower()
                        }} className="w-5 absolute bottom-0 z-10 left-0  h-5" src={del} alt="" />
                      </div> :
                      <div>
                        <div className=" relative ">
                          <img className=" w-[100%] rounded-[10px]" src={el.url} alt="" />
                          <img onClick={(e) => {
                            e.stopPropagation()
                            setIsOK(true)
                            let arr = avatarArray
                            arr[index] = null
                            setAvatarArray([...arr])
                            setCardList([...arr])
                            computeTotalPower()
                          }} className="w-5 absolute bottom-0 z-10 left-0  h-5" src={del} alt="" />
                        </div>
                        <img className='absolute top-0 left-0  w-[23px] h-[23px]' src={attributeImage(el.property)} alt="" />

                        <div className={` ${'h-6'} absolute px-[5px] flex items-center justify-center z-10 top-0 right-0`}>
                          <div className=' absolute top-0 left-0 w-full h-full opacity-60' style={{ backgroundImage: `url(${power_bg})`, backgroundSize: '100% 100%' }}></div>
                          <p className={`${el?.bonus_power ? 'text-style-4 pr-[2px]' : 'text-style-7'} !text-[12px] !font-bold`}>{Number(el?.bonus_power) ? el?.bonus_power + el?.power : el?.power}</p>
                        </div>
                      </div>
                  }
                </>}
            </div>
            <p className='mt-[10px] text-center text-[14px] leading-[18px] text-[#fff]'>{type ? 'Agent' : 'Card'}</p>
          </div>)}
      </div>
      <div className=' fixed bottom-10 z-[101] left-[50%] gap-[32px] -translate-x-[50%]  w-fit  flex justify-center items-center'>
        <ButtonComp width={140} title='One-Click Staking' callBack={() => {
          miningAuto()
        }}>
        </ButtonComp>
        {((JSON.stringify([...cardArray, ...avatarArray].map((el) => el?.id)) != JSON.stringify([...cardArrayOld, ...avatarArrayOld].map((el) => el?.id))) || isOK) &&
          <ButtonComp width={140} title='OK' callBack={() => {
            postCards()
          }}>
          </ButtonComp>
        }
      </div>

      {
        visible && <Popup
          visible={visible}
          position="right"
          style={{ width: '100%', height: '100vh', overflow: 'hidden', backgroundColor: 'rgba(0,0,0)' }}
        >
          {/* {typeParams == 2 && <div className={`mt-[53px] absolute w-[calc(100%_-_32px)] mx-4 z-[10000] grid grid-cols-2 rounded-[10px] h-[36px] bg-[#333]`}>
            <div onClick={() => {
              setCardList(cardArray)
              setType(0)
            }} className={`${type ? 'text-[#a6a6a6]' : 'text-[#fff] bg-gradient-to-r from-[#00E49F] to-[#54ADFF]'} rounded-[10px] justify-center  flex items-center`}>
              <img className='w-[18px] h-[18px] mr-2' src={cardImg} alt="" />
              {typeParams != 2 ? 'Cards' : 'Card NFTs'}
            </div>

            <div onClick={() => {
              if (isLoading.current) {
                return false
              }
              setCardList(avatarArray)
              setType(1)
            }} className={`${type ? 'text-[#fff] bg-gradient-to-r from-[#00E49F] to-[#54ADFF]' : 'text-[#a6a6a6]'} rounded-[10px] flex justify-center items-center`}>
              <img className='w-[18px] h-[18px] mr-2' src={avatarImg} alt="" />
              {typeParams != 2 ? 'Agents' : 'Agent NFTs'}
              <div className={`${characterBonus > 0 ? '' : 'hidden'} ml-3 relative h-full flex items-center`}>
                {!type ? <img className='w-5 h-5' src={addImg} alt="" /> : <div className='iconfont text-[20px]'>&#xe612;</div>}
                <div style={{
                  height: '16px',
                  padding: "0px 4px",
                  background: 'linear-gradient( 135deg, #FFE600 0%, #D18C00 100%)',
                  borderRadius: '7px 7px 7px 0px'
                }}
                  className={`font-semibold flex items-center text-[12px] z-[99] leading-[12px] absolute -top-[10px] -right-5 text-[#fff]`}>
                  +{characterBonus}%
                </div>
              </div>
            </div>
          </div>} */}
          {type ?
            <Character
              callback={(val: any) => {
                if (val) {
                  setIsOK(true)
                  let arr = avatarArray
                  arr[active] = val
                  setAvatarArray(arr)
                  setCardList(arr)
                  computeTotalPower()
                }
                setVisible(false)
              }}
              isNft={typeParams == 2 ? true : false}
              cardArray={[...avatarArray]}
              type={'mining'}>
            </Character>
            :
            <Search callback={(val: any) => {
              if (val) {
                setIsOK(true)
                let arr = cardArray
                arr[active] = val
                setCardArray(arr)
                setCardList(arr)
                computeTotalPower()
              }
              setVisible(false)
            }}
              isNft={typeParams == 2 ? true : false}
              cardArray={[...cardArray]}
              type={'mining'}
            >
            </Search>
          }
        </Popup>
      }
      <RulesDialog textArr={miningRules} type='mining' title="Mining Rules" dialog={rulesDialog} setDialog={setRulesDialog}></RulesDialog>
      <PromptDialog
        title={'Attention'}
        dialog={promptDialog}
        setDialog={setPromptDialog}
        isClose={true}
        callback={async () => {
        }}
        text={promptText}>
      </PromptDialog>
    </div >
  )

}
export default MyMining