import { Locales, useTonConnectUI, useTonAddress, useTonWallet } from '@tonconnect/ui-react'
import TonConnectUI from '@tonconnect/ui'

import { startTask } from '@/api/url.js'
import { changeSettings } from "../../store/module/settins";
import { changeIsGate } from "../../store/module/tasks"
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
export default function () {
    const wallet = useTonWallet()
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const userFriendlyAddress = useTonAddress();
    const dispatch = useDispatch()
    const settings = useSelector((state: any) => state.settings.settings)

    const useConnectWallet = async (el?: any, callback?: (el: any) => void) => {
        if (wallet) {
            await tonConnectUI.disconnect();
        }
        try {

            await tonConnectUI.connectWallet();

            dispatch(changeSettings({ ...settings, connect_wallet: true }))
            if (el && !el.state) {
                dispatch(changeIsGate(""))
                try {
                    callback && callback(el)
                    const { code } = await startTask({ code: el.code })
                    if (code == '0') {
                        callback && callback(el)
                    }
                } catch (err) {
                    console.log(err)
                    dispatch(changeSettings({ ...settings, connect_wallet: false }))
                }

            }
        } catch (e) {

            console.log(e)
        }
    }

    const handleGateConnectClick = async (el?: any, callback?: (el: any) => void) => {

        try {
            if (wallet) {
                await useDisconnectWallet()
            }
            await tonConnectUI.openSingleWalletModal("GateWallet");
            dispatch(changeSettings({ ...settings, connect_wallet: true }))

            if (el && !el.state) {
                try {

                    dispatch(changeIsGate("gate"))
                    // setTimeout(() => {
                    //     callback && callback(el)
                    // }, 2000)
                } catch (err) {
                    console.log(err)
                    dispatch(changeSettings({ ...settings, connect_wallet: false }))
                }

            }

        } catch (error) {
            console.error("Error connecting wallet:", error);
        }
    };

    const handleOkexConnectClick = async () => {

        try {
            if (wallet) {
                await useDisconnectWallet()
            }
            await tonConnectUI.openSingleWalletModal("okxTonWallet");
            // dispatch(changeSettings({ ...settings, connect_wallet: true }))


        } catch (error) {
            console.error("Error connecting wallet:", error);
        }
    };

    const useDisconnectWallet = async () => {
        await tonConnectUI.disconnect();
        dispatch(changeSettings({ ...settings, connect_wallet: false }))
        // unsubscribe()
    }

    const useGetWalletAddress = () => {
        return userFriendlyAddress;
    }


    // useEffect(() => {
    //     const unsubscribe = tonConnectUI.onSingleWalletModalStateChange((status) => {

    //     });
    //     return () => {
    //         unsubscribe()
    //     }
    // }, [userFriendlyAddress, tonConnectUI])






    return {
        useConnectWallet,
        useDisconnectWallet,
        useGetWalletAddress,
        handleGateConnectClick,
        handleOkexConnectClick
    }
}



