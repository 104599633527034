import axios, { AxiosRequestConfig } from 'axios'
import WebApp from '@twa-dev/sdk'
import { login } from '@/api/url'
const service = axios.create()
service.interceptors.request.use((req) => {
  let token = localStorage.getItem('token')
  const axiosPromiseArr = []
  req.cancelToken = new axios.CancelToken((cancel) => {
    axiosPromiseArr.push({
      url: req.url,
      cancel
    })
  })
  // 设置token到header
  // @ts-ignore
  req.headers.Authorization = `Bearer ${token}`
  req.headers['User-Source'] = WebApp.platform == 'unknown' ? 'H5' : 'TgMiniApp'
  //  req.headers.Authorization = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVSUQiOiI5UnByV2Z5bnFOTWY2Q05uUEVOM01QQ1hwNEtQRktnOEJQZEp6OTJqTWtBUSIsImV4cCI6MTcyNzE2ODUwMH0.-5K4pu-WEIHK-gDrNfLiI3TW9TTMeFkAMXMMJHWXCHg`
  if ('get'.includes(req.method?.toLowerCase() as string)) req.params = req.data

  return req
}, (err) => {
  console.log(err)
  Promise.reject(err)
})
// 请求后拦截
service.interceptors.response.use(
  (res) => {
    // debugger
    const { code } = res.data
    const { status, headers } = res
    const successCode = '0,200,20000'
    const noAuthCode = '401,403'
    // loading.close()
    if (res.data.code !== "0") {
    }
    if (successCode.includes(code) || successCode.includes(status)) {
      // 下载文件
      if (res.headers['content-disposition']) {
        return res
      } else {
        return res.data
      }
    } else {
      // if (noAuthCode.includes(code) && !location.href.includes('/login')) {
      // ElMessageBox.confirm('请重新登录', {
      //   confirmButtonText: '重新登录',
      //   closeOnClickModal: false,
      //   showCancelButton: false,
      //   showClose: false,
      //   type: 'warning'
      // }).then(() => {
      //   useBasicStore().resetStateAndToLogin()
      // })
      // }
      return Promise.reject(res.data)
    }

  },
  // 响应报错
  async (err) => {
    if (err.response.status == 401) {
      await getToken()
    }
    // if (err.response.data.code == 100) {
    //   // ElMessage.error({
    //   //   message: err.response.data.msg,
    //   //   duration: 2 * 1000
    //   // })
    // }

    return Promise.reject(err)
  }
)

// 导出service实例给页面调用 , config->页面的配置
export default function axiosReq(config: AxiosRequestConfig<any>) {
  return service({
    baseURL: import.meta.env.VITE_APP_BASE_URL,
    timeout: 3 * 60 * 1000,
    ...config,

  })
}


const getToken = async () => {
  const res = await login({
    id: WebApp.initDataUnsafe?.user?.id.toString() || window.localStorage.getItem("userId") || "",
    invitation_code: WebApp.initDataUnsafe.start_param || '',
    init_data: WebApp.initData || localStorage.getItem('init_data') || import.meta.env.VITE_INIT_DATA,
    chat_id: WebApp.initDataUnsafe?.chat_instance || window.localStorage.getItem("chatInstance") || "",
    user_name: WebApp.initDataUnsafe?.user?.username || window.localStorage.getItem("userName") || "",
    first_name: WebApp.initDataUnsafe?.user?.first_name || window.localStorage.getItem("firstName") || "",
    last_name: WebApp.initDataUnsafe?.user?.last_name || window.localStorage.getItem("lastName") || "",
    is_primer: WebApp.initDataUnsafe.user?.is_premium ? true : false,
  })
  if (res.code == 0) {
    localStorage.setItem('token', res.data)
  }
}

