import { Dialog, Toast, Uploader } from "react-vant"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import Crop from 'xcrop'
import { getAccountProfile, postAccountProfile, postShareProfile, setShareProfileCount, setShareProfile } from "@/api/account"
import { getUserInfo, postSetting, getSetting, getAvatarCommercial, postAvatarCommercial, getCharacterInfo } from "@/api/url"
import coin from '@/assets/images/coin.png'
import x from '@/assets/images/start/x.png'
import ButtonComp from "./common/ButtonComp"
import test from '@/assets/images/homePage/test.png'
import like from '@/assets/images/homePage/like.png'
import editIcon from '@/assets/images/homePage/edit.png'
import rocket from '@/assets/images/homePage/rocket.png'
import message from '@/assets/images/homePage/message.png'
import coins from '@/assets/images/redpacket/coins.png'
import show from '@/assets/images/cards/show.svg'
import title_icon from '@/assets/images/homePage/title_icon.png'
import mining_icon from '@/assets/images/mining/mining_icon.png'
import TalkMessage from "./message/TalkMessageToast"
import CloseComp from "./common/CloseComp"
import { formatIdString, FormattingNumber, FormattingTwoNumber } from "../utils"
import { useNavigate } from "react-router-dom"
import { changeAddCount, changeHomePage, changeUserInfo } from "../store/module/tabbar"
import { useDispatch, useSelector } from "react-redux"
import html2canvas from 'html2canvas-pro';
import '@/styles/homePage.less'
import Screenshot from "./common/Screenshot"
import WebApp from "@twa-dev/sdk"
import PromptDialog from "./PromptDialog"
import NsfwPay from "./nsfw/nsfwPay"
import { changeSettings } from "../store/module/settins"
import { pushChatListId, setCurrAvatarId, setCurrCharacterInfo } from "../store/module/progress"
import GetCoinAnimationDialog from "./GetCoinAnimationDialog"
import { changeIsGate } from "../store/module/tasks"
import { TwitterShareButton } from "react-share"
// import CoinProgressBar from "./CoinProgressBar"
import { postWarningInformation } from "@/api/url.js"

interface HomePageProps {
  dialog: boolean,
  userInfo: any
}

const timeout = (ms: any) => {
  return new Promise((_, reject) => {
    setTimeout(() => {
      reject(new Error("Operation timeout"));
    }, ms);
  });
};

const HomePage = ({ dialog, userInfo }: HomePageProps) => {
  const [invitation_code] = useState<any>(localStorage.getItem('inviteCode'))
  const [payDialog, setPayDialog] = useState(false)
  const [payData, setPayData] = useState<any>(null)
  const [promptDialog, setPromptDialog] = useState(false)
  const [promptText, setPromptText] = useState('')
  const [edit, setEdit] = useState(false)
  const address_params = localStorage.getItem('address_params')
  const homePageType = useSelector((state: any) => state.tabbar.homePage)
  const addCount = useSelector((state: any) => state.tabbar.addCount)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [claimPointsDialog, setClaimPointsDialog] = useState(false)
  const settings = useSelector((state: any) => state.settings.settings)
  const id: any = localStorage.getItem('userId')
  const [isMessage, setIsMessage] = useState(false);
  const [textMessage, setTextMessage] = useState("");
  const [valueName, setValueName] = useState("");
  const [image, setImage] = useState('')
  const [isImage, setIsImage] = useState('')
  const [accountProfile, setAccountProfile] = useState<any>({})
  const teleport: any = useRef(null)
  const captureDom: any = useRef(null)
  const [coin_NSFW, setCoin_NSFW] = useState(false)
  const [nsfwType, setNsfwType] = useState(14)
  const [shareCount, setShareCount] = useState(14)

  // const [storyImage, setStoryImage] = useState('')

  const setUserInfo = useCallback(async () => {
    const UserInfo = await getUserInfo(address_params)
    if (UserInfo.code == "0") {
      dispatch(changeUserInfo(UserInfo.data))
    }
  }, [])


  const shareProfileCount = useCallback(async () => {
    const { code, data } = await setShareProfileCount(id)
    if (code == "0") {
      setShareCount(data)
    }
  }, [])

  useEffect(() => {
    shareProfileCount()
  }, [])

  const handleCapture = async () => {
    let is_nsfw = false
    let toast: any = null
    accountProfile.avatar?.cards?.forEach((el: any) => {
      if (el && el.is_nsfw) {
        setPromptDialog(true)
        setPromptText('The photo contains NSFW content and cannot be shared.')
        is_nsfw = true
        new Error("The photo contains NSFW content and cannot be shared.")
      }
    });
    accountProfile.decks?.cards?.forEach((el: any) => {
      if (el && el.is_nsfw) {
        setPromptDialog(true)
        setPromptText('The photo contains NSFW content and cannot be shared.')
        is_nsfw = true
        new Error("The photo contains NSFW content and cannot be shared.")
      }
    });
    if (!is_nsfw) {
      toast = Toast.loading({
        message: "Loading...",
        forbidClick: true,
        duration: 0
      })
      timeout(10000)
      await html2canvas(captureDom.current).then((canvas: any) => {
        canvas.willReadFrequently = true;
        postShareProfile(id, canvas.toDataURL('image/jpeg').replace('data:image/jpeg;base64,', '')).then((result: any) => {
          WebApp.shareToStory(result.data, {
            text: 'StarAI, The best AI Agent platform for transforming your AI creations into blockchain NFTs and Tokens!'
          })
        })
        setTimeout(async () => {
          if (shareCount == 0) {
            const { code } = await setShareProfile(id)
            if (code == 0) {
              setClaimPointsDialog(true)
            }
          }
          toast.clear()
        }, 5000)
      });
      setTimeout(async () => {
        toast.clear()
      }, 3000)
    } else {
      toast.clear()
    }
  };

  const levelPer = useMemo(() => {
    if (userInfo.next_level_points == "0") {
      return 95
    } else {
      const per = (userInfo.level_points - userInfo.now_level_points) / (userInfo.next_level_points - userInfo.now_level_points)
      if (Math.floor(100 - per * 100) > 100) {
        return 100
      } else if (Math.floor(100 - per * 100) < 0) {
        return 0
      } else {
        return Math.floor(100 - per * 100)
      }
    }
  }, [userInfo.next_level_points, userInfo.now_level_points, userInfo.points])

  const options = {}
  const crop = new Crop(options)
  crop.options.circle = true
  crop.setBorder({ width: 300, height: 300 })
  crop.show()
  crop.on('cancel', function (crop: any) {
    crop.hide()
  })
    .on('confirm', function (crop: any) {
      setImage(crop.get())
      setIsImage(crop.get())
      crop.hide()
    })

  // 实际业务中需要自己实现对应上传逻辑
  const getData = async () => {
    const toast = Toast.loading({
      message: "Loading...",
      forbidClick: true,
      duration: 0
    })
    let profileId = ''
    try {
      timeout(5000)
      if (homePageType.id) {
        profileId = homePageType.id
      } else {
        profileId = id
      }
      const { data, code } = await getAccountProfile(profileId)
      if (code == '0') {
        setAccountProfile(data)
        setValueName(data.nick_name)
        setIsImage(data.avatar_url)
        setImage(`${data.avatar_url}?id=${Date.now()}`)
      }
      toast.clear()
    } catch (error) {
      toast.clear()
      setPromptDialog(true)
      setPromptText('Network Error.')
    }
  }

  const upload: any = async (file: File) => {
    if (!homePageType.id) {
      try {
        let base64 = ''
        if (file) {
          // 创建FileReader对象
          const reader = new FileReader();

          reader.onload = function (e: any) {
            // 读取文件并转换为Base64
            const content = e.target.result;

            // 使用btoa()转换Base64
            base64 = btoa(content);
            crop.load(atob(base64))
            // 输出或处理Base64字符串
          };

          // 以DataURL的形式读取文件
          reader.readAsDataURL(file);
        }
        return ''
      } catch (error) {
        return ''
      }
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setIsMessage(false)
    }, 3000)
  }, [isMessage])

  const postCommercial = async () => {
    try {
      const { code, msg } = await postAvatarCommercial(payData.id)
      if (code == '0') {
        await goToTalkPage(payData)
      } else {
        setPayDialog(false)
        setPromptText(msg)
        setPromptDialog(true)
      }
    } catch {
      setPayDialog(false)
    }
  }

  const goToTalkPage = async (el: any) => {
    if (el.avatar_type == '2') {
      const { data, code } = await getAvatarCommercial(el.id)
      if (code == '0' && data.ok) {
        if (code == '0') {
          dispatch(setCurrCharacterInfo(el))
          window.localStorage.setItem("currCharacterInfo", JSON.stringify(el));
          dispatch(setCurrAvatarId(el.id))
          dispatch(changeHomePage({ showHomePage: false, id: '', }))
          dispatch(pushChatListId({ id: el.id, list: [], actionList: [] }))
          setTimeout(() => {
            navigate(`/talk?id=${el.id}`);
          }, 200);
        }
      } else {
        setPayDialog(true)
      }
    } else {
      dispatch(setCurrCharacterInfo(el))
      window.localStorage.setItem("currCharacterInfo", JSON.stringify(el));
      dispatch(setCurrAvatarId(el.id))
      dispatch(changeHomePage({ showHomePage: false, id: '', }))
      dispatch(pushChatListId({ id: el.id, list: [], actionList: [] }))
      setTimeout(() => {
        navigate(`/talk?id=${el.id}`);
      }, 200);
    }
  }


  const AudioRef = useRef<any>()

  const getCoinAnimation = (x: any, y: any) => {
    const R = 100; // 扩散半径
    const time = 0.25; //扩散过度时间
    const time2 = 1; // 收集过度时间
    const w = 26; // 金币大小
    const Num = 8 //金币数量

    const screenWidth = window.innerWidth;
    const pcX = screenWidth > 768 ? (screenWidth / 2) - 187.5 : 0
    setlits((x - pcX), y)

    if (AudioRef.current) {
      // AudioRef.current.currentTime = 0;
      AudioRef.current.play()
    }
    const total = document.getElementsByClassName('total_1')[0] // 获取金币最终飞向

    function setlits(X: any, Y: any) {
      var species = document.createElement('div');
      species.className = 'species'
      for (let i = 0; i < Num; i++) {
        var img = document.createElement('img');
        img.className = 'speciesstyle'
        img.style.width = w + 'px',
          img.style.height = w + 'px',
          img.style.left = X + 'px',
          img.style.top = Y + 'px',
          img.style.transform = 'translate(0%, 0%)',
          img.style.transition = `${time}s`
        img.src = coin
        species.appendChild(img);
      }
      document.querySelector("body").current.appendChild(species)
      setTimeout(() => {
        clickss(X, Y)
      }, 0)
    }
    // 计算金币扩散范围和坐标
    function clickss(X: number, Y: number) {
      const specielist: any = document.getElementsByClassName('speciesstyle')
      const num = specielist.length
      const center = {
        x: X,
        y: Y
      }
      for (let i = 0; i < num; i++) {
        const xz = center.x + Math.floor(Math.random() * ((R * 1.1) - (R * 0.6) + 1)) + (0.6 * R) * Math.sin(2 * Math.PI * i / num)
        const yz = center.y + Math.floor(Math.random() * ((R * 1.1) - (R * 0.6) + 1)) + (0.6 * R) * Math.cos(2 * Math.PI * i / num)
        specielist[i].style.transform = `translate(${xz - (X + w / 2)}px, ${yz - (Y + w / 2)}px)`
      }
      setTimeout(() => {
        for (let i = 0; i < num; i++) {
          specielist[i].style.transition = `${time2}s`
          specielist[i].style.transform = `translate(0,0)`
          specielist[i].style.left = Math.floor(total?.getBoundingClientRect().x) - pcX + 'px'
          specielist[i].style.top = Math.floor(total?.getBoundingClientRect().y) + 'px'
        }
        // 结束清除蒙层
        setTimeout(() => {
          remove()
        }, (Number(time2) + 0.5) * 500) //飞行时间结束

      }, (Number(time) + 0.5) * 500) //扩散时间结束
    }
    // 清除dom
    function remove() {
      document.getElementsByClassName('species')[0].remove()
    }
    setUserInfo()
  }


  return (
    <Dialog
      className={'homePageDialog'}
      teleport={document.querySelector("body")}
      visible={dialog}
      showConfirmButton={false}
      showCancelButton={false}
      closeOnClickOverlay={false}
      onClose={() => {
        dispatch(changeHomePage(
          {
            showHomePage: false,
            id: '',
          }
        ))
      }}
      width={343}
    >
      <div ref={teleport} className="w-full homePage rounded-[20px] relative md:py-[6px] flex justify-center items-center max-md:h-[610px]" style={{ background: 'linear-gradient( 90deg, #00E49F 0%, #54ADFF 100%)' }}>
        {/* <img className=" w-[22px] h-[22px] right-[10px] absolute top-[10px] " onClick={() => { setDialog(false) }} src={close} alt="" /> */}
        <CloseComp top={10} position="absolute" right={10} callBack={() => {
          setTimeout(() => {
            dispatch(changeHomePage(
              {
                showHomePage: false,
                id: '',
              }
            ))
          }, 200)

        }}></CloseComp>
        <div className="rounded-[18px] p-[11px] pt-[16px] md:pb-[70px] pb-[60px] overflow-auto w-[calc(100%_-_8px)] max-md:h-[602px]" style={{ background: 'linear-gradient( 135deg, #CFFFAF 0%, #FFFFFF 100%)' }}>
          <div className=" flex items-center">
            <div className=" relative w-[60px] bg-transparent h-[60px]">
              {!homePageType.id ? <Uploader
                upload={upload}
                previewImage={false}
                previewSize={60} // 自定义视图尺寸
                maxSize={5 * 1024 * 1024}
                onOversize={() => {
                  setTextMessage(`Upload images with a maximum size of 5Mb.`)
                  setIsMessage(true)
                }}
                uploadIcon={
                  <div>
                    {
                      isImage ?
                        <img className="w-[60px] h-[60px] rounded-full" src={image} alt="" />
                        :
                        <img className="w-[60px] h-[60px] rounded-full" src={test} alt="" />
                    }
                    <img className="w-4 h-4 absolute right-0 bottom-0" src={editIcon} alt="" />
                  </div>
                } // 自定义上传图标
              /> :
                <>
                  {
                    isImage ?
                      <img className="w-[60px] h-[60px] rounded-full" src={image} alt="" />
                      :
                      <img className="w-[60px] h-[60px] rounded-full" src={test} alt="" />
                  }
                </>
              }
            </div>
            <div className={`ml-[6px]`}> <p className={`text-[16px] flex gap-1 leading-[19px] ${accountProfile.nick_name ? 'text-[#333]' : accountProfile.name ? 'text-[#333]' : 'text-[#A6A6A6]'}`}>{accountProfile.nick_name ? accountProfile.nick_name : accountProfile.name ? accountProfile.name : 'Username Not Set'}
              {!homePageType.id && <img
                onClick={() => {
                  setEdit(true)
                }} className="w-4 cursor-pointer h-4 left-1" src={editIcon} alt="" />}</p>
              <p className="text-[12px] mt-[6px] text-[#a6a6a6] leading-[15px">ID: {formatIdString(accountProfile.id)}</p>
            </div>
          </div>
          {!homePageType.id && <>
            <div className=" flex items-center mt-2 justify-between " >
              <p className=" text-[16px] font-[19px] text-style-6">Lv {accountProfile.level}</p>
              <div style={{ background: 'linear-gradient( 90deg, #00E49F 0%, #54ADFF 100%)' }} className=" flex h-[18px] w-[203px] rounded-[10px] items-center">
                <div className="h-[14px] rounded-[10px] ml-[2px] w-[calc(100%_-_4px)]" style={{ background: '#ffffff' }}>
                  <div className="h-[14px] rounded-[10px]" style={{ background: 'linear-gradient( 90deg, #00E49F 0%, #54ADFF 100%)', width: `${levelPer || '0'}%` }}></div>
                </div>
              </div>
              <img src={rocket} className=" w-5 h-5 " alt="" />
              <div className=" text-[#ffffff] text-[12px] font-semibold leading-[15px] px-[2px] py-[1px]" style={{ background: 'linear-gradient( 135deg, #FFE600 0%, #D18C00 100%)', borderRadius: '8px 8px 8px 0px' }}>
                +{(accountProfile.ponits_boost / 10) || 0}%
              </div>
            </div>
            <div className=" flex justify-between mt-[14px] items-center">
              <div>
                <div className=" rounded-[10px] flex items-center justify-center w-[146px] h-10" style={{ background: 'linear-gradient( 90deg, #00E49F 0%, #54ADFF 100%)' }}>
                  <div style={{ background: '#FFFFFF' }} className="flex items-center justify-center rounded-[10px] h-[calc(100%_-_4px)] w-[calc(100%_-_4px)]">
                    <img className="w-5 h-5" src={coin} alt="" />
                    <p className="ml-[10px] text-style-6 text-[16px] leading-[19px] font-bold">{FormattingTwoNumber(accountProfile.balance) || '0'}</p>
                  </div>
                </div>
                <div onClick={() => navigate('/starRecord?type=0')} className=" w-fit cursor-pointer m-auto mt-1">
                  <p className=" text-[14px] underline leading-[18px] font-semibold text-style-8">$STAR Records &gt;&gt;</p>
                  <div className="h-[1px]" style={{ background: 'linear-gradient( 90deg, #00E49F 0%, #54ADFF 100%)' }}></div>
                </div>
              </div>
              <div>
                <div className=" rounded-[10px] flex items-center justify-center w-[146px] h-10" style={{ background: 'linear-gradient( 90deg, #00E49F 0%, #54ADFF 100%)' }}>
                  <div style={{ background: '#FFFFFF' }} className="flex items-center justify-center rounded-[10px] h-[calc(100%_-_4px)] w-[calc(100%_-_4px)]">
                    <img className="w-5 h-5" src={mining_icon} alt="" />
                    <p className="ml-[10px] text-style-6 text-[16px] leading-[19px] font-bold">{FormattingTwoNumber(accountProfile.sai) || '0'}</p>
                  </div>
                </div>
                <div onClick={() => navigate('/starRecord?type=1')} className=" w-fit cursor-pointer m-auto mt-1">
                  <p className=" text-[14px] underline leading-[18px] font-semibold text-style-8">$SAI Records &gt;&gt;</p>
                  <div className="h-[1px]" style={{ background: 'linear-gradient( 90deg, #00E49F 0%, #54ADFF 100%)' }}></div>
                </div>
              </div>
            </div>
          </>}

          <p className=" text-[16px] flex gap-[6px] justify-center items-center leading-[19px] mt-5 text-center font-semibold text-[#01825D]">
            <img className="h-[3px]" src={title_icon} alt="" />
            Most Popular AI Agents
            <img className="h-[3px] rotate-180" src={title_icon} alt="" />
          </p>

          {accountProfile.avatar?.cards?.length < 1 ?
            <p className="text-[14px] leading-[18px] py-[15px] font-semibold text-center text-[#333333]">{homePageType.id ? 'He has not created a AI Agent yet.' : "You have not created a AI Agent yet."}</p>
            :
            <div className=" flex justify-center items-center mt-[10px] gap-[36px] w-full">
              {accountProfile.avatar?.cards?.map((el: any, index: number) =>
                <div onClick={async () => {
                  const { data, code } = await getCharacterInfo(el.id)
                  if (code == '0') {
                    dispatch(changeAddCount(addCount + 1))
                    setPayData(data)
                    goToTalkPage(data)
                  } else {
                    setPromptText('Network error')
                    setPromptDialog(true)
                  }

                }} key={`accountProfile_avatar_${index}`} className="w-20 rounded-[10px] h-[120px] relative">
                  {el.is_nsfw && homePageType.id && !settings.character_nsfw && !settings.star_nsfw &&
                    <div onClick={(e) => {
                      e.stopPropagation()
                      setNsfwType(15)
                      setCoin_NSFW(true)
                    }}
                      className=" !backdrop-blur-[20px] z-[2] flex justify-center items-center w-full h-full rounded-[10px] absolute top-0 left-0">
                      <img className=" w-[30px] h-[20px]" src={show} alt="" />
                    </div>
                  }
                  <img className=" relative z-[1] rounded-[10px]" src={el.url} alt="" />

                  <div className=" p-1 z-[3] absolute bottom-0 left-0">
                    <p className=" text-[12px] leading-[15px] font-bold text-[#fff]" >{el.name}</p>
                    <div className="flex mt-1 gap-[2px]">
                      <div className=" flex rounded-[3px] p-[2px] items-center bg-[#7f7f7f]/50 ">
                        <img className="w-2 mr-[2px]" src={like} alt="" />
                        <p className=" text-[12px] text-[#fff] leading-3 font-[400]">{FormattingTwoNumber(el.like_count)}</p>
                      </div>
                      <div>
                        <div className=" flex rounded-[3px] p-[2px] items-center bg-[#7f7f7f]/50 ">
                          <img className="w-2 mr-[2px]" src={message} alt="" />
                          <p className=" text-[12px] text-[#fff] leading-3 font-[400]">{FormattingTwoNumber(el.chat_count)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          }
          <div className="p-5 rounded-[10px] mt-[6px] justify-between flex items-center py-[6px]" style={{ background: 'linear-gradient( 90deg, rgba(0, 228, 159,0.3) 0%, rgba(84, 173, 255,0.3) 100%)' }}>
            <p className="text-[14px] leading-[18px] text-[#333333] font-semibold">Own: {accountProfile.avatar?.own || '0'}</p>
            <p className="text-[14px] leading-[18px] text-[#333333] font-semibold">Chats: {accountProfile.avatar?.chat || '0'}</p>
            <p className="text-[14px] leading-[18px] text-[#333333] font-semibold">Logs: {accountProfile.avatar?.logs || '0'}</p>
          </div>
          <p className=" text-[16px] flex gap-[6px] justify-center items-center leading-[19px] mt-5 text-center font-semibold text-[#01825D]">
            <img className="h-[3px]" src={title_icon} alt="" />
            Card PK Deck
            <img className="h-[3px] rotate-180" src={title_icon} alt="" />
          </p>
          {accountProfile.decks?.cards?.every((item: any) => item == null) ?
            <p className=" text-[14px] leading-[18px] py-[15px] font-semibold text-center text-[#333333]">{homePageType.id ? 'He has not configured a deck yet.' : "You have not configured a deck yet."}</p>
            :
            <div className=" flex items-center justify-center mt-[10px] gap-2">
              {accountProfile.decks?.cards.map((el: any) => el &&
                <div key={el.url} className={` relative rounded-[6px] w-[46px] ${el ? ' ' : 'hidden'}`}>
                  {el.is_nsfw && homePageType.id && !settings.nsfw && !settings.star_nsfw &&
                    <div
                      onClick={() => {
                        setNsfwType(14)
                        setCoin_NSFW(true)
                      }} className=" !backdrop-blur-[20px] z-[2] flex justify-center items-center w-full h-full rounded-[6px] absolute top-0 left-0">
                      <img className=" w-[18px] h-[12px]" src={show} alt="" />
                    </div>
                  }
                  <img className={`rounded-[6px] relative z-[1] w-[46px] ${el ? ' ' : 'hidden'}`} src={el.url} alt="" />
                </div>
              )}
            </div>
          }
          <div className="p-5 rounded-[10px] mt-[6px] flex justify-between items-center py-[6px]" style={{ background: 'linear-gradient( 90deg, rgba(0, 228, 159,0.3) 0%, rgba(84, 173, 255,0.3) 100%)' }}>
            <p className="text-[14px] leading-[18px] text-[#333333] font-semibold">PK: {accountProfile.decks?.pk || '0'}</p>
            <p className="text-[14px] leading-[18px] text-[#333333] font-semibold">Wins: {accountProfile.decks?.wins_count || '0'}</p>
            <p className="text-[14px] leading-[18px] text-[#333333] font-semibold">Wins%: {accountProfile.decks?.wins_rate || '0'}{accountProfile.decks?.wins_rate ? '%' : ''}</p>
          </div>
        </div>
        <div className=" absolute bottom-[22px] gap-4 flex justify-between">
          {!homePageType.id && WebApp.version >= "7.8" &&
            <div className="relative h-fit">
              {shareCount == 0 && <div style={{
                width: '67px',
                height: '18px',
                background: 'linear-gradient( 125deg, #EF4444 0%, #E88585 100%)',
                borderRadius: '0px 9px 0px 9px'
              }} className=" text-[#fff] text-[12px] leading-[15px] absolute z-10 right-[2px] flex items-center justify-center -top-[6px] ">
                +50K
                <img src={coins} className=" w-[14px] ml-1 h-[10px]" alt="" />
              </div>}
              <ButtonComp width={130} title='Share' callBack={() => {
                handleCapture()
              }}></ButtonComp>
            </div>

          }
          <ButtonComp width={homePageType.id ? 174 : 130} title='OK' callBack={async () => {
            if (homePageType.id) {
              dispatch(changeHomePage(
                {
                  showHomePage: false,
                  id: '',
                }
              ))
            } else {
              const toast = Toast.loading({
                message: "Loading...",
                forbidClick: true,
                duration: 0
              })
              const { data, code } = await postAccountProfile(id, { name: valueName, image: isImage.replace('data:image/jpeg;base64,', '') })
              toast.clear()
              if (code == '0') {
                setUserInfo()
                dispatch(changeHomePage(
                  {
                    showHomePage: false,
                    id: '',
                  }
                ))
              }
              getData()
            }
          }}></ButtonComp>
        </div>
      </div>
      {
        edit && <Dialog
          visible={edit}
          showConfirmButton={false}
          showCancelButton={false}
          closeOnClickOverlay={false}
          onClose={() => {
            setEdit(false)
          }}
          width={290}
          teleport={teleport.current}
        >
          <div className="bg-[url(@/assets/images/dialog_bg.png)] relative px-[14px] !bg-[length:100%_100%] w-full pb-[40px] pt-[42px]">
            <CloseComp top={10} position="absolute" right={10} callBack={() => { setEdit(false) }}></CloseComp>

            <p className="text-[20px] text-[#333] text-center leading-[24px]" >Username</p>
            <input className=" bg-[#fff] w-full h-10 p-[10px] text-[#333] rounded-[10px] username mt-5"
              value={valueName}
              onChange={(el: any) => {
                if (el.target.value.length >= 20) {
                  setValueName(valueName)
                } else {
                  setValueName(el.target.value)
                }
              }}
              placeholder="Please enter"
              type="text" />
            <div className=" w-fit mx-auto">
              <ButtonComp mt={30} title='OK' callBack={
                async () => {
                  if (valueName) {
                    const { data, code } = await postAccountProfile(id, { name: valueName, image: isImage.replace('data:image/jpeg;base64,', '') })
                    if (code == '0') {
                      setUserInfo()
                      setEdit(false)
                      getData()
                    }
                  } else {
                    setEdit(false)
                  }
                }
              }></ButtonComp>
            </div>
          </div>
        </Dialog>
      }
      {isMessage && <TalkMessage text={textMessage}></TalkMessage>}
      <Screenshot captureDom={captureDom} accountProfile={accountProfile}></Screenshot>
      {
        coin_NSFW && <NsfwPay
          teleport={teleport.current}
          type={nsfwType} closeHandle={async (val) => {
            let starType = {}
            if (nsfwType == 15) {
              starType = { character_nsfw: true }
            } else {
              starType = { nsfw: true }
            }
            setCoin_NSFW(false)
            if (!val) {
              return;
            }
            try {
              const result = await postSetting(localStorage.getItem('userId'), { ...settings, ...starType, order_id: val })
              if (result.code == 0) {
                dispatch(changeSettings({ ...settings, ...starType }))
                setUserInfo()
              } else {
                setPromptText('Network error')
                setPromptDialog(true)
              }
              let ID = window.Telegram.WebApp.initDataUnsafe?.user?.id || WebApp.initDataUnsafe?.user?.id || window.localStorage.getItem("userId") || "";
              if (!ID) {
                postWarningInformation({
                  platform: JSON.stringify({
                    telegram: window.Telegram.WebApp.platform,
                    webApp: WebApp.platform,
                  }),
                  version: JSON.stringify({
                    telegram: window.Telegram.WebApp.version,
                    webApp: WebApp.version,
                  })
                  ,
                  initData: JSON.stringify({
                    telegram: window.Telegram.WebApp.initData,
                    webApp: WebApp.initData,
                  }),
                  telegram: JSON.stringify(window.Telegram.WebApp.initDataUnsafe),
                  webApp: JSON.stringify(WebApp.initDataUnsafe),
                  localStorage: window.localStorage.getItem("userId"),
                  interfaceName: 'getSetting',
                })
                ID = window.Telegram.WebApp.initDataUnsafe?.user?.id || WebApp.initDataUnsafe?.user?.id || window.localStorage.getItem("userId") || "";
              }
              const setting = await getSetting(ID)
              if (setting.code == 0) {
                dispatch(changeSettings(setting.data))
              } else {
                setPromptText('Network error')
                setPromptDialog(true)
              }
            } catch {
              setPromptText('Network error')
              setPromptDialog(true)
            }
          }

          }></NsfwPay>
      }
      {
        payData &&
        <PromptDialog
          teleport={teleport.current}
          title={'Attention'}
          dialog={payDialog}
          setDialog={setPayDialog}
          text={`This is a paid task created by @${payData?.account_name ? payData?.account_name : payData?.account_id}. A one-time fee of  ${FormattingNumber(payData?.price.toString())} $STAR is required. Would you like to proceed?`}
          isClose={true}
          callback={
            postCommercial
          }
        ></PromptDialog>
      }
      <GetCoinAnimationDialog dialog={claimPointsDialog} point={"50000"} getCoinAnimation={getCoinAnimation} setDialog={() => {
        dispatch(changeIsGate(""))
        setClaimPointsDialog(false)
      }}></GetCoinAnimationDialog>
      <PromptDialog teleport={teleport.current} title={'Attention'} dialog={promptDialog} setDialog={setPromptDialog} callback={() => { setPromptDialog(false) }} text={promptText}></PromptDialog>
    </Dialog >
  )
}
export default HomePage

