
import React, { useRef, useEffect } from "react";


interface PropType {
    imageUrl: string,
    x: number,
    y: number,
    width: number,
    height: number,
    blurRadius?: number,
    zIndex?: number,
    index?: number
}
const GlassmorphismCanvas = ({ imageUrl, x, y, width, height, blurRadius = 10, zIndex = 10, index = 0 }: PropType) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas: any = canvasRef.current;

        const ctx = canvas.getContext("2d");

        // 加载背景图像
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => {
            // ctx.filter = 'blur(20px)';
            // 绘制背景图像
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            // 获取毛玻璃区域的像素数据
            const imageData = ctx.getImageData(x, y, width, height);

            // 应用高斯模糊
            const blurredImageData = gaussianBlur(imageData, blurRadius);

            // 绘制模糊效果
            ctx.putImageData(blurredImageData, x, y);

            // 添加半透明遮罩
            ctx.globalAlpha = 1;
            ctx.fillStyle = "rgba(255, 255, 255, 0.1)";
            ctx.fillRect(x, y, width, height);
            ctx.globalAlpha = 1.0; // 重置透明度
        };
    }, [imageUrl, x, y, width, height, blurRadius, index]);

    // 高斯模糊函数
    const gaussianBlur = (imageData: any, radius: number) => {
        const pixels = imageData.data;
        const width = imageData.width;
        const height = imageData.height;

        const gaussianKernel = createGaussianKernel(radius);

        const tempPixels = new Uint8ClampedArray(pixels.length);

        // 水平模糊
        for (let y = 0; y < height; y++) {
            for (let x = 0; x < width; x++) {
                let r = 0,
                    g = 0,
                    b = 0,
                    a = 0;
                for (let i = -radius; i <= radius; i++) {
                    const pixelIndex = (y * width + (x + i)) * 4;
                    if (x + i >= 0 && x + i < width) {
                        const weight = gaussianKernel[i + radius];
                        r += pixels[pixelIndex] * weight;
                        g += pixels[pixelIndex + 1] * weight;
                        b += pixels[pixelIndex + 2] * weight;
                        a += pixels[pixelIndex + 3] * weight;
                    }
                }
                const outputIndex = (y * width + x) * 4;
                tempPixels[outputIndex] = r;
                tempPixels[outputIndex + 1] = g;
                tempPixels[outputIndex + 2] = b;
                tempPixels[outputIndex + 3] = a;
            }
        }

        // 垂直模糊
        for (let x = 0; x < width; x++) {
            for (let y = 0; y < height; y++) {
                let r = 0,
                    g = 0,
                    b = 0,
                    a = 0;
                for (let i = -radius; i <= radius; i++) {
                    const pixelIndex = ((y + i) * width + x) * 4;
                    if (y + i >= 0 && y + i < height) {
                        const weight = gaussianKernel[i + radius];
                        r += tempPixels[pixelIndex] * weight;
                        g += tempPixels[pixelIndex + 1] * weight;
                        b += tempPixels[pixelIndex + 2] * weight;
                        a += tempPixels[pixelIndex + 3] * weight;
                    }
                }
                const outputIndex = (y * width + x) * 4;
                pixels[outputIndex] = r;
                pixels[outputIndex + 1] = g;
                pixels[outputIndex + 2] = b;
                pixels[outputIndex + 3] = a;
            }
        }

        return imageData;
    };

    // 创建高斯核
    const createGaussianKernel = (radius: number) => {
        const sigma = radius / 3;
        const kernel = [];
        let sum = 0;
        for (let i = -radius; i <= radius; i++) {
            const weight = Math.exp(-(i * i) / (2 * sigma * sigma)) / (Math.sqrt(2 * Math.PI) * sigma);
            kernel.push(weight);
            sum += weight;
        }
        for (let i = 0; i < kernel.length; i++) {
            kernel[i] /= sum;
        }
        return kernel;
    };

    return (
        <canvas style={{ zIndex: zIndex }} className={`absolute top-0 left-0 z-[10]`} ref={canvasRef} width={width} height={height}></canvas>
    )
};

export default GlassmorphismCanvas;