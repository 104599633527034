import { useNavigate } from "react-router-dom"
import BackComp from "../../components/common/BackComp"
import ButtonComp from "../../components/common/ButtonComp"
import { attributeImage, formatIdString, timestampToBeijingTime } from "../../utils"
import selectActive from "@/assets/images/selectActive.png"
import battle from "@/assets/images/tabbar/battle.png"
import { getShopPayConfig } from "@/api/shop.js"
import { getApexRank, postApexReminder } from "@/api/pk_url.js"
import { useEffect, useState } from "react"
import { Battle_RULES } from '../../text/rulesText'
import RulesDialog from "../../components/RulesDialog"
import tip from "@/assets/images/attribute/tip.svg"
import top_1 from "@/assets/images/pk/top_1.png"
import top_2 from "@/assets/images/pk/top_2.png"
import top_3 from "@/assets/images/pk/top_3.png"
import time from "@/assets/images/pk/time.png"
import lw from "@/assets/images/pk/lw.png"
import fire from "@/assets/images/pk/fire.png"
import sea from "@/assets/images/pk/sea.png"
import { useDispatch } from "react-redux"
import { changeBattleCardData } from "../../store/module/card"
import PublicDialog from "../../components/PublicDialog"
import PayDialog from "../../components/common/PayDialog"
import { Toast } from "react-vant"
import PromptDialog from "../../components/PromptDialog"

const BattleOfThePeaks = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [datalist, setDatalist] = useState<any>({})
  const [checkRewardsDialog, setCheckRewardsDialog] = useState<any>(false)
  const [showPayDialog, setShowPayDialog] = useState<any>(false)
  const [checkValue, setCheckValue] = useState<any>(false)
  const [pkList, setPkList] = useState<any>({})
  const [payLink, setPayLink] = useState<any>({})
  const [promptDialog, setPromptDialog] = useState<any>(false)
  const [promptText, setPromptText] = useState<any>('')
  const [rulesDialog, setRulesDialog] = useState(false)
  const getRanksList = async () => {
    try {
      const { data, code } = await getApexRank(0, 50, 0)
      if (code == '0') {
        setDatalist(data)
      }

      if (data?.my?.rank == -1) {
        const res = await getApexRank(4999, 20, 1)
        if (res.code == '0') {
          setPkList(res.data)
        }
      } else if (data?.my?.rank > 49) {
        const res = await getApexRank(data?.my?.rank - 1, 20, 1)
        if (res.code == '0') {
          setPkList(res.data)
        }
      }
    } catch {
    }
  }

  const getPayLink = async () => {
    const toast = Toast.loading({
      message: "Loading...",
      forbidClick: true,
      duration: 0
    })
    try {
      const { code, data } = await getShopPayConfig(16)
      if (code == 0) {
        setPayLink(data.map((el: any) => { return { ...el, title: "Payment for Peaks" } }))
      } else {
        setPromptDialog(true)
        setPromptText('Network Error.')
      }
      toast.clear()
    } catch (e) {
      toast.clear()
    }
  }


  const checkRewards = () => {
    return (
      <div className="py-10 px-[10px]">
        <p className=" text-[20px] leading-6 text-[#333333] text-center font-bold">Attention</p>
        <p className=" text-[14px] my-5 leading-[18px] text-[#333333] text-center font-semibold">The first 10 initiations of the Peak Battle PK each day will require the expenditure of 10K $STAR per attempt.</p>
        <div className=" text-[14px] my-5 leading-[18px] text-[#333333] text-center font-semibold flex justify-center gap-[6px] items-center ">
          {!checkValue ?
            <div onClick={() => {
              setCheckValue(!checkValue)
            }} style={{ border: '1px solid #333333' }} className=" w-[14px] h-[14px] rounded-full "></div>
            :
            <img onClick={() => {
              setCheckValue(!checkValue)
            }} className=" w-[14px]" src={selectActive} alt="" />
          }
          Do not prompt again within a week.
        </div>
        <ButtonComp width={174} height={36} mt={30} title='OK' callBack={async () => {
          if (checkValue) {
            const res = await postApexReminder(604800)
            console.log(res)
          }
          setCheckRewardsDialog(false)
          navigate('/battle-result?type=peak')
        }}>
        </ButtonComp>
      </div>
    )
  }
  const PK = (el: any) => {
    if ((datalist?.today_pk_count < datalist?.daily_pk_limit)) {
      dispatch(changeBattleCardData(el))
      if (datalist.need_remind) {
        setCheckRewardsDialog(true)
      } else {
        navigate('/battle-result?type=peak')
      }
    } else {
      dispatch(changeBattleCardData(el))
      setShowPayDialog(true)
    }
  }

  useEffect(() => {
    getRanksList()
    getPayLink()
  }, [])
  return (
    <div className="px-4 h-[100vh] relative pb-[70px] pt-[183px]">
      <div className=" absolute top-[54px] left-4 battleOfThePeaksList !w-[calc(100%_-_32px)] px-5 py-4">
        <div className=" flex justify-between items-center ">
          <p className=" text-[14px] leading-[18px] text-[#fff] font-semibold gap-1 flex items-center">
            <img className=" w-4 h-4" src={sea} alt="" />
            {datalist?.season_info?.name} Season
          </p>
          <p className=" text-[14px] leading-[18px] text-[#fff] font-semibold gap-1 flex items-center">
            <img className=" w-4 h-4" src={time} alt="" />
            {timestampToBeijingTime(datalist?.season_info?.begin_time, '/')} - {timestampToBeijingTime(datalist?.season_info?.end_time, '/')}
          </p>
        </div>
        <p className="flex gap-1 items-center justify-between mt-[14px]">
          <div className="flex gap-1 items-center">
            <img className=" w-4 h-4" src={fire} alt="" />
            <span className="text-[14px] leading-[18px] ellipsis1 text-[#fff] font-semibold">Seasonal Bonus:</span>
          </div>
          <div className=" flex gap-[5px]">
            {Array.isArray(datalist?.season_info?.bonus_property) && datalist?.season_info?.bonus_property.map((el: any) => <img className="w-5 h-5" src={attributeImage(el)} alt="" />)}
            <span className="text-style-1 leading-5 font-semibold text-[14px]">{datalist?.season_info?.bonus_value}%up</span>
          </div>
        </p>
        <div className="flex justify-between mt-[14px] items-center">
          <div className="flex text-[14px] gap-1 leading-[18px] text-[#fff] font-semibold items-center">
            <img className=" w-4 h-4" src={lw} alt="" />
            Seasonal Rewards:
          </div>
          <div onClick={() => {
            navigate(`/eventAnnouncement?type=pk`)
          }
          } style={{ borderBottom: ' 1px solid', borderImage: 'linear-gradient(90deg, rgba(0, 228, 159, 1), rgba(84, 173, 255, 1)) 1 1' }} className="text-style-1 cursor-pointer leading-5 font-semibold text-[14px]">
            Click to view &gt;&gt;
          </div>
        </div>
      </div>
      <div className="overflow-auto h-[calc(100vh_-_258px)] ">
        <BackComp background="#000" title="Battle of the Peaks" position={true} callback={() => {
          navigate('/pk')
        }}></BackComp>
        <img onClick={() => { setRulesDialog(true) }} className=" max-md:fixed md:absolute  top-[13px] z-30 right-4  w-[18px] h-[18px]" src={tip} alt="" />
        <div className=" flex flex-col gap-y-[10px]">
          {
            Array.isArray(datalist.list) && datalist.list.slice(0, 3).map((el: any) =>
              <div key={el.account_id} className=" battleOfThePeaksList px-5 pt-[10px] pb-[13px]">
                <p className="text-[12px] leading-[15px] text-[#fff] font-semibold gap-1 flex items-center">
                  {formatIdString(el.account_id)}
                </p>
                <div className=" flex gap-1 justify-between items-center">
                  {/* <span className="text-style-1 leading-[18px] font-semibold  text-left text-[14px]">No. 1</span> */}
                  <img className={`${el.rank ? el.rank == 1 ? "w-[22px] mt-[8px] h-[22px] " : "mt-[8px] w-[22] h-[20px] " : 'mt-[6px] w-5 h-[26px]'}`} src={el.rank ? el.rank == 1 ? top_2 : top_3 : top_1} alt="" />
                  <span className="text-style-1 leading-[22px] mt-[10px] text-left font-bold text-[18px] px-1">Power: {el.power}</span>
                  <div className="flex items-center">
                    {el.is_my ?
                      <>
                        <div style={{ border: '0.5px dashed #00E49F' }} className=" -mt-3 w-[1px] h-[45px]"></div>
                        <div className=" w-[92px] -mt-[6px] text-center text-[14px] leading-[18px] text-[#fff] font-semibold">Mine</div>
                      </> :
                      <ButtonComp width={92} disabled={el.rank > datalist?.my?.rank && datalist?.my?.rank == -1 || (datalist?.my?.rank - 21) >= el.rank} height={36} title='PK' callBack={() => {
                        PK(el)
                      }}>
                        <img className="w-[14px] h-[14px]" src={battle} alt="" />
                      </ButtonComp>
                    }
                  </div>
                </div>
              </div>
            )}
          {
            datalist?.my?.rank <= 49 && datalist?.my?.rank != -1 && Array.isArray(datalist.list) && datalist.list.slice(3).map((el: any) =>
              <div key={el.account_id} className=" battleOfThePeaksList px-5 pt-[10px] pb-3">
                <p className="text-[12px] leading-[15px] text-[#fff] font-semibold gap-1 flex items-center">
                  {formatIdString(el.account_id)}
                </p>
                <div className=" flex gap-1 justify-between items-center">
                  <span className="text-style-1 leading-[18px] font-semibold mt-[10px] text-left text-[14px]">No.{el.rank + 1}</span>
                  <span className="text-style-1 leading-[22px] mt-[8px] text-left font-bold text-[18px] px-1">Power: {el.power}</span>
                  <div className="flex items-center">
                    {el.is_my ?
                      <>
                        <div style={{ border: '0.5px dashed #00E49F' }} className=" -mt-3 w-[1px] h-[45px]"></div>
                        <div className=" w-[92px] -mt-[6px] text-center text-[14px] leading-[18px] text-[#fff] font-semibold">Mine</div>
                      </> :
                      <ButtonComp width={92} disabled={el.rank > datalist?.my?.rank && datalist?.my?.rank == -1 || (datalist?.my?.rank - 21) >= el.rank} height={36} title='PK' callBack={() => {
                        PK(el)
                      }}>
                        <img className="w-[14px] h-[14px]" src={battle} alt="" />
                      </ButtonComp>
                    }
                  </div>
                </div>
              </div>
            )}
          {(datalist?.my?.rank > 49 || datalist?.my?.rank == -1) && <div className=" w-full flex justify-center items-center gap-[10px]">
            {[1, 2, 3, 4, 5, 6].map((el: number) =>
              <div key={`${el}-battleOfThePeaksList`} className="h-[6px] w-[6px] rounded-full bg-[#A6A6A6]"></div>
            )}
          </div>}
          {
            Array.isArray(pkList.list) && pkList.list.length > 0 && pkList.list.reverse().map((el: any) =>
              <div key={el.account_id} className=" battleOfThePeaksList px-5 pt-[10px] pb-3">
                <p className="text-[12px] leading-[15px] text-[#fff] font-semibold gap-1 flex items-center">
                  {formatIdString(el.account_id)}
                </p>
                <div className=" flex gap-1 justify-between items-center">
                  <span className="text-style-1 leading-[18px] font-semibold mt-[10px] text-left text-[14px]">No.{el.rank + 1}</span>
                  <span className="text-style-1 leading-[22px] mt-[8px] text-left font-bold text-[18px] px-1">Power: {el.power}</span>
                  <div className="flex items-center">
                    {el.is_my ?
                      <>
                        <div style={{ border: '0.5px dashed #00E49F' }} className=" -mt-3 w-[1px] h-[45px]"></div>
                        <div className=" w-[92px] -mt-[6px] text-center text-[14px] leading-[18px] text-[#fff] font-semibold">Mine</div>
                      </> :
                      <ButtonComp width={92} disabled={(datalist?.my?.rank == 0 || (datalist?.my?.rank - 21) >= el.rank) && datalist?.my?.rank != -1} height={36} title='PK' callBack={() => {
                        PK(el)
                      }}>
                        <img className="w-[14px] h-[14px]" src={battle} alt="" />
                      </ButtonComp>
                    }
                  </div>
                </div>
              </div>
            )}
        </div>
        {
          showPayDialog &&
          <PayDialog isNeedDialogSuccess={false} dataList={payLink}
            title={`Attention`}
            text={'You have exhausted the number of times you can use $STAR for Peak Battle PK today. Would you like to pay the following fee to continue with the PK?'}
            text_1={'   '}
            callBack={(val) => {
              console.log("🚀 ~ BattleOfThePeaks ~ val:", val)
              
              if (val != 'close') {
                navigate('/battle-result?type=peak')
                setShowPayDialog(false)
              } else {
                setShowPayDialog(false)
              }
            }} />
        }
        <RulesDialog textArr={Battle_RULES} title="Rules for Battle of the Peaks" dialog={rulesDialog} setDialog={setRulesDialog} teleport={undefined} type={""}></RulesDialog>
        <PublicDialog closeOnClickOverlay={false} width={"320px"} isPadding={false} dialog={checkRewardsDialog} setDialog={setCheckRewardsDialog} content={checkRewards()}></PublicDialog>
        <PromptDialog title={'Attention'} dialog={promptDialog} setDialog={setPromptDialog} text={promptText}></PromptDialog>
      </div>
      <div style={{ background: 'linear-gradient(90deg, #00E49F 0%, #54ADFF 100%)' }} className="px-5 py-[9px] flex w-[calc(100%_-_32px)] justify-between fixed bottom-[25px] left-4 z-10 items-center rounded-[10px] h-10">
        <p className="text-[#01825D] text-[14px] leading-[18px] font-semibold">{datalist?.my?.account_id}</p>
        <p className="text-[#fff] text-[18px] leading-[22px] font-bold">{datalist?.my?.power}</p>
        <p style={{ textShadow: '0px 2px 4px rgba(0,0,0,0.5)' }} className="text-[#fff] text-[14px] leading-[18px] font-semibold">{datalist?.my?.rank == -1 ? '…' : (datalist?.my?.rank + 1) || '…'}</p>
      </div>
    </div >
  )
}
export default BattleOfThePeaks