import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFakeProgress } from "react-use-fakeprogress";
import RefinedLoading_bg from "@/assets/images/refining/RefinedLoading.jpg"
import { useDispatch, useSelector } from "react-redux";
import { cardMerge, postPayWithStarDetails, getUserInfo } from "../../api/url.js"
import PublicDialog from "../PublicDialog";
import { setRefiningValue } from "../../store/module/progress.js";
import { HighlightPercentages } from "../../utils/index.js";
import Back from "../common/BackComp.js";
import WebApp from "@twa-dev/sdk";
import { changeUserInfo } from "../../store/module/tabbar.js";
import ButtonComp from "../common/ButtonComp.js";
const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

function RefinedLoading() {
  const [searchParams] = useSearchParams()
  const [dialog, setDialog] = useState(false)
  const [text, setText] = useState('')
  const address_params = localStorage.getItem('address_params')
  const refiningCardList = useSelector((state: any) => state.search.refiningCardList)
  const isSaveCard = useSelector((state: any) => state.search.isSaveCard)
  const refining_loading_text = useSelector((state: any) => state.progress.refining_loading_text)
  const { shopCount } = useSelector((state: any) => state.lottery)
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [progress, start, done, stop] = useFakeProgress(
    15000 /* timeconstant value */
  );
  const ids = refiningCardList.map((item: any) => item.id)

  const setUserInfo = useCallback(async () => {
    const UserInfo = await getUserInfo(address_params)
    if (UserInfo.code == "0") {
      dispatch(changeUserInfo(UserInfo.data))

    }
  }, [])

  const params = {
    ids,
    prompt: searchParams.get("message"),
    is_protect: isSaveCard
  }
  useEffect(() => {
    if (isSaveCard) {
      payStarMergeCardHandle(params)
    } else {
      payCostMergeCardHandle(params)
    }

  }, [])


  const payCostMergeCardHandle = async (params: any) => {
    start()
    try {
      const { code, data } = await cardMerge(params)
      if (code == "0") {
        setUserInfo()
        dispatch(setRefiningValue(data))
        if (data.is_ok) {
          navigate(`/refining-failure`)
        } else {
          navigate(`/refining-failure`)
        }
      } else if (code == "65547") {
        setDialog(true)
        setText('Insufficient $STAR.')
      } else {
        setText('Something went wrong, please try again, it will take a few seconds.')
        setDialog(true)
      }
      done()
    } catch (e) {
      setText('Something went wrong, please try again, it will take a few seconds.')
      setDialog(true)
      console.log(e)
    }
  }


  const payStarMergeCardHandle = async (params: any) => {
    start()
    if (refiningCardList && refiningCardList.length > 0 && refiningCardList[0]?.level >= 2) {
      await loopOrder()
    } else {
      try {
        const { code, data } = await cardMerge(params)
        if (code == "0") {
          if (data.pay) {
          } else {
            dispatch(setRefiningValue(data))
            navigate(`/refining-failure`)
          }

        } else if (code == "65547") {
          setDialog(true)
          setText('Insufficient $STAR.')
        } else {
          setText('Something went wrong, please try again, it will take a few seconds.')
          setDialog(true)
        }
      } catch (e) {

        // setText('Something went wrong, please try again, it will take a few seconds.')
        // setDialog(true)
        // console.log(e)
      }
    }



  }

  const loopOrder = async () => {
    try {
      let timeCount = 0;
      let code_ = null;
      let data_ = null;
      while (code_ != "0" && timeCount < 8) {
        await sleep(2000)
        const { code, data } = await postPayWithStarDetails({ order_id: searchParams.get("order"), cards: ids })
        if (code == "0") {
          code_ = code
          data_ = data
          done()
        }
        timeCount++
      }
      if (data_) {
        dispatch(setRefiningValue(data_))
        navigate(`/refining-failure`)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const attention = () => {
    return (
      <div className=" flex flex-col items-center pt-[42px] px-4 justify-center">
        <p className="font-bold text-center text-[20px] leading-[24px] text-[#333]">Attention</p>
        <p className=" font-medium text-center text-[14px] leading-[18px] my-5 text-[#333]">{text}</p>
        <ButtonComp title="OK" callBack={() => {
          if (text == 'Insufficient $STAR.') {
            setDialog(false)
            navigate('/cards')
          } else {
            payCostMergeCardHandle(params)
            setDialog(false)
          }
        }}></ButtonComp>
      </div>
    )
  }
  return (
    <div className="pt-[60px] RefiningLoading overflow-auto h-[100vh] pb-8 px-4">
      <div className="fixed left-0 top-0 h-11 w-full z-[1200] text-[16px] justify-between px-4 font-bold leading-[19px] text-white flex items-center">
        <Back title="Refining" callback={() => { navigate('/refining') }}></Back>
      </div>
      <div className="card mx-auto">
        <div className="bg">
          <img className=" rounded-[24px]" src={RefinedLoading_bg} alt="" />
        </div>
        <div className="blob"></div>
      </div>
      <div className="flex justify-center w-full items-center mb-12 px-2">
        <div style={{ border: '1px solid #6A6A6A' }} className="h-4 mt-6 relative bg-[#343434] rounded-lg w-full">
          <div style={{ background: 'linear-gradient( 135deg, #00E49F 0%, #54ADFF 100%)', width: `${Math.floor(progress * 100)}%` }} className="h-[14px] rounded-lg  w-0"> </div>
          <span style={{ left: `${Math.floor(progress * 100)}%` }} className="text-transparent absolute  left-[0%] translate-x-[-50%] bottom-[-24px] bg-clip-text bg-gradient-to-r text-[14px] leading-[18px] from-[#00E49F] to-[#54ADFF]">{Math.floor(progress * 1000) / 10}%</span>
        </div>
      </div>
      {refining_loading_text.map((e: string, index: number) =>
        <div key={`${index}-refining_loading_text`} className="bg-gradient-to-r from-[#00E49F]  to-[#54ADFF] inline-block mb-[10px] rounded-[10px] text-[14px] leading-[23px] font-semibold p-[10px] text-[#333]"
          dangerouslySetInnerHTML={{ __html: HighlightPercentages(e) }}>
        </div>)}
      <PublicDialog closeOnClickOverlay={false} RefinedLoading={false} dialog={dialog} width="290" setDialog={setDialog} content={attention()}></PublicDialog>
    </div>
  )
}
export default RefinedLoading