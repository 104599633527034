
import { createBrowserRouter } from "react-router-dom"
import App from "@/App.tsx"
import Talk from "@/pages/talk/Talk.tsx"
import Card from "@/pages/cards/Card.tsx"
import Task from "@/pages/tasks/Task.tsx"
import Inviting from "@/pages/inviting/Inviting.tsx"
import Start from "@/pages/start/Start.tsx"
import Ranks from "@/pages/ranks/Ranks.tsx"
import ChangeBackground from "../pages/changeBackground/Index"
import Character from "../pages/character/Character"
import Search from "../pages/search/Index"
import NftComponent from "../pages/nft/Index"
import RefiningComponent from "../pages/refin/Index"
import RefinedLoading from "../components/rafining/RefiningLoading"
import RefiningFailure from "../components/rafining/RefiningFailure"
import MyInvites from "../pages/myInvites/index.tsx"
import RegenerateLoading from "../pages/regenerate/loading"
import Home from "../pages/home/home.tsx"
import CreateAvatarComponent from "../pages/character/createAvatar/create.tsx"
import EditAvatarComponent from "../pages/character/createAvatar/edit.tsx"
import AvatarSearchComponent from "../pages/character/createAvatar/search.tsx"
import StrengthenCardResult from "../pages/cards/StrengthenCardResult.tsx"
import Maintenance from "../pages/maintenance/index.tsx"
// import StrengthenCardResult from "../pages/cards/StrengthenCardResult.tsx"
import Battle from "../pages/battle/battle.tsx"
import pkRoute from "./pk/index.tsx"
import BattleResult from "../pages/battle/battleResult/index.tsx"
import CardMyDeck from "../pages/battle/myDeck/CardMyDeck.tsx"
import shopRouter from "./shop/index.tsx"
import Records from "../pages/character/Records.tsx"
import starRecord from "./starRecord/records.tsx"
import Mining from "../pages/mining/index.tsx"
import Pool from "../pages/mining/pool.tsx"
import PastPool from "../pages/mining/pastpool.tsx"

import MyMining from "../pages/mining/myMining.tsx"
import SelectCards from "../pages/mining/selectCards.tsx"
import NewStart from "../pages/start/NewStart.tsx"
import NewTask from "../pages/tasks/NewTask.tsx"
import EventAnnouncement from "../pages/tasks/EventAnnouncement.tsx"


const router = createBrowserRouter([
    {
        path: "/",
        element: <App></App>,
        children: [

            {

                index: true,
                element: <Start></Start>
            },
            {
                path: "/pk",
                element: <Battle></Battle>
            },

            {
                path: "/agents",
                element: <Character></Character>
            },
            {
                path: "/agents-records",
                element: <Records></Records>
            },
            {
                path: "/talk",
                element: <Talk></Talk>
            },
            {
                path: "/oldTasks",
                element: <Task></Task>
            },
            {
                path: "/tasks",
                element: <NewTask></NewTask>
            },
            {
                path: "/inviting",
                element: <Inviting></Inviting>
            },
            {
                path: "/cards",
                element: <Card></Card>
            },
            {
                path: "/ranks",
                element: <Ranks></Ranks>
            },
            {
                path: "/change-backgroud",
                element: <ChangeBackground></ChangeBackground>
            },
            {
                path: "/search",
                element: <Search></Search>
            },
            {
                path: "/nft",
                element: <NftComponent></NftComponent>
            },
            {
                path: "/refining",
                element: <RefiningComponent></RefiningComponent>
            },
            {
                path: "/loading-refining",
                element: <RefinedLoading></RefinedLoading>
            },
            {
                path: "/refining-failure",
                element: <RefiningFailure></RefiningFailure>
            },
            {
                path: "/loading-regenerate",
                element: <RegenerateLoading></RegenerateLoading>
            },
            {
                path: "/my-invites",
                element: <MyInvites></MyInvites>
            },
            {
                path: "/home",
                element: <Home></Home>
            },
            {
                path: "/create-agents",
                element: <CreateAvatarComponent></CreateAvatarComponent>
            },
            {
                path: "/edit-agents",
                element: <EditAvatarComponent></EditAvatarComponent>
            },
            {
                path: "/search-agents",
                element: <AvatarSearchComponent></AvatarSearchComponent>
            },
            {
                path: "/my-deck",
                element: <CardMyDeck></CardMyDeck>
            },
            {
                path: "/battle-result",
                element: <BattleResult></BattleResult>
            }, {
                path: "/maintenance",
                element: <Maintenance></Maintenance>
            },
            {
                path: "/mining",
                element: <Mining></Mining>
            },
            {
                path: "/pool",
                element: <Pool></Pool>
            },
            {
                path: "/my-mining",
                element: <MyMining></MyMining>
            },
            {
                path: "/post-pool",
                element:<PastPool></PastPool>
            },
            {
                path: "/select-cards",
                element: <SelectCards></SelectCards>
            },
            {
                path: "/eventAnnouncement",
                element: <EventAnnouncement></EventAnnouncement>
            }
            ,
            ...pkRoute(),
            ...shopRouter(),
            ...starRecord(),

        ]
    },

])

export default router;