import { useEffect, useLayoutEffect, useState } from 'react';
import { NftCollection } from '../../../contracts/star_ai_NftCollection';
import { NftItem } from "../../../contracts/star_ai_NftItem"
import { useTonClient } from '../useTonClient';
import { useAsyncInitialize } from '../useAsyncInitialize';
import { useTonConnect } from '../useTonConnect';
import { Address, OpenedContract, toNano } from '@ton/core';

export function useNftContract(type: string) {
    const client = useTonClient();
    const { mintSender } = useTonConnect();
    const nftContract = useAsyncInitialize(async () => {
        if (!client) return;
        const contract = NftCollection.fromAddress(
            // import.meta.env.VITE_MINT_CONTRACT_ADDRESS

            Address.parse(type ? import.meta.env.VITE_AGENT_MINT_CONTRACT_ADDRESS : import.meta.env.VITE_MINT_CONTRACT_ADDRESS) // replace with your address from tutorial 2 step 8
        );
        return client.open(contract) as OpenedContract<NftCollection>;
    }, [client]);


    return {
        address: nftContract?.address.toString(),
        latestIndex: async () => await nftContract?.getGetCollectionData(),
        mintingNftAddress: async (id: any) => await nftContract?.getGetNftAddressByIndex(id),
        mint: async () => {
            return nftContract?.send(
                mintSender,
                {
                    value: toNano('0.1'),
                    bounce: true,
                },
                `Mint`
            );

        },
        getGetNftDatas: async (address: any) => {
            const contract = await NftItem.fromAddress(address);
            const abx = await client?.open(contract);
            return abx
        }

    };
}
