

interface backProps {
	title?: string,
	children?: any,
	callback?: () => void,
	position?: boolean,
	isHiddenBtn?: boolean,
	isHiddenIcon?: boolean,
	background?: string,
	isLeft?: boolean
	isMl?: boolean


}

import { useRef } from "react";
import backMusic from "../../assets/mp3/back.mp3"
import { useSelector } from "react-redux";
import WebApp from "@twa-dev/sdk";


const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

const Back = (props: backProps) => {
	const isSoundPlay = useSelector((state: any) => state.settings.settings.sound)
	const { settings } = useSelector((state: any) => state.settings)

	const { title, callback, position, isHiddenIcon, isHiddenBtn, children, background, isLeft = true, isMl = true } = props

	const AudioRef = useRef<any>()
	const clickHandle = async () => {
		if (isHiddenIcon) {
			return false
		}

		if (AudioRef.current) {
			// AudioRef.current.currentTime = 0;
			AudioRef.current.play()
		}
		if (settings.vibration) {
			WebApp.HapticFeedback.impactOccurred('heavy'); // 震动1000毫秒
		}

		await sleep(200)

		callback && callback()
	}
	return (
		<div style={{ background: background ? background : "" }} className={`w-full h-11 cursor-pointer flex items-center ${position ? 'fixed top-0 left-0' : 'items-center'} z-10`}>
			{!isHiddenIcon &&
				<svg onClick={() => { clickHandle() }} className={`cursor-pointer absolute ${isLeft ? 'left-[13px]' : ''}  ${isHiddenIcon ? 'opacity-0' : ''}`} width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
					<g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
						<g id="10-cards-搜索4" transform="translate(-16.000000, -57.000000)" fillRule="nonzero">
							<g id="编组-66" transform="translate(16.000000, 54.237154)">
								<g id="left-arrow备份-2" transform="translate(0.000000, 2.762846)">
									<rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="24" height="24"></rect>
									<polygon id="路径" fill="#FFFFFF" points="10.9382812 20.0811328 4.06971094 13.2125625 2.85714844 12 4.06914844 10.788 10.9382812 3.91886719 12.1502812 5.13086719 6.13945312 11.1428672 21.1451719 11.1428672 21.1451719 12.8571328 6.13942969 12.8571328 12.1508672 18.8685937"></polygon>
								</g>
							</g>
						</g>
					</g>
				</svg>
			}
			{
				!children && <div className={`${isHiddenBtn ? 'hidden' : ''} w-full flex items-center text-white justify-center`}>{title}</div>
			}

			{
				children && <div className={`${isMl ? 'ml-7' : ''} w-full flex justify-center items-center`}>
					{children}
				</div>
			}

			{
				isSoundPlay && <audio ref={AudioRef} src={backMusic} id="audio"></audio>
			}

		</div>
	)
}




export default Back;