import { useCallback, useEffect, useRef, useState } from "react"
import { useNavigate } from 'react-router-dom'
import ClipPathBorderRadius from "../../components/ClipPathBorderRadius";
import { getCards, getSaleCards } from '@/api/url.js'
import Mine from "./Mine";
import { useDispatch, useSelector } from "react-redux";
import Sale from "../cards/Sale"
import BScroll from 'better-scroll'
import "./index.less"
import { changeCardData, changeSaleCardData } from "../../store/module/tasks";
import usePlaySound from "@/hook/usePlaySound.tsx"

import { List } from 'react-vant';
import { changeActive } from "../../store/module/search";
import Back from "../../components/common/BackComp";

new BScroll('.changeBackground-box', {
    pullUpLoad: true,
    scrollbar: true,
    pullDownRefresh: true
    // and so on
})

const pageSize = 10;

const changeBackground = () => {

    const navigator = useNavigate();
    const dispatch = useDispatch()
    const currCharacterInfo = useSelector((state: any) => state.progress.currCharacterInfo)
    // const [saleCardsData, setSaleCardsData] = useState<any>(useSelector((state: any) => state.tasks.resSale))
    const [saleCardsData, setSaleCardsData] = useState<any>([])
    const [buttonActive, setButtonActive] = useState(1);
    const [finished, setFinished] = useState(false);
    // const [isLoading, setIsLoading] = useState(false)
    const isLoading = useRef(false)
    // const [isInitLoading, setIsInitLoading] = useState(true)
    const isInitLoading = useRef(true)

    const currPage = useRef(1)
    const total = useRef(0);
    const getCardsData = useCallback(async () => {

        // console.log(isLoading.current)
        if (isLoading.current) { return false }

        await getDataList()
    }, [])

    useEffect(() => {
        setSaleCardsData([])

        isInitLoading.current = true
        setFinished(false)
        currPage.current = 1;
    }, [buttonActive])

    const getDataList = async () => {

        isLoading.current = true
        try {

            const { code, data: { data, count } } = await getSaleCards(currPage.current, pageSize)
            if (code === '0') {
                setSaleCardsData((prevData: any) => [...prevData, ...data]);
                // setTotal(count)
                total.current = count;
                // setIsLoading(false)
                isLoading.current = false
                // setIsInitLoading(false)
                isInitLoading.current = false
                if (data.length == 0) {
                    setFinished(true)
                }
            } else {
                setFinished(true)
                total.current = 0
            }

        } catch (e) {
            console.log(e)
            // setIsLoading(false)
            isLoading.current = false
            setFinished(true)
            total.current = 0
        }

        if (currPage.current * pageSize >= total.current) {
            setFinished(true)
            // return false;
        }




    }

    const onLoad = async () => {
        if (finished || isInitLoading.current) return;
        currPage.current++
        await getDataList()
    }
    const goToTalk = () => {
        sessionStorage.setItem("isSendMessage", "1")
        navigator("/talk")
    }

    // ------------------------ 滑动 -----------------------
    useEffect(() => {
        document.addEventListener('touchstart', touchStartHandle);
        return () => {
            document.removeEventListener('touchstart', touchStartHandle);
            // window.scrollTo(0, 0);
        }

    })

    const touchStartHandle = () => {
        window.scrollTo(0, 1);
        ensureDocumentIsScrollable();
    }

    const ensureDocumentIsScrollable = () => {
        const isScrollable = document.documentElement.scrollHeight > window.innerHeight;
        if (!isScrollable) {
            document.documentElement.style.setProperty(
                "height",
                "calc(100vh + 0.5px)",
                "important"
            );
        }
    }

    return (
        <div className={`${buttonActive == 1 ? '' : 'changeBackground-box'} w-full h-full overflow-auto text-white bg-no-repeat bg-cover bg-center relative pt-14 pb-5`} style={{ backgroundImage: `url(${currCharacterInfo.bg_image})` }}>
            <div className="nav-box fixed top-0 left-0 w-full h-[134px] z-[999] pt-4 px-4 box-border bg-gradient-to-b from-[#000000] to-[#fff]/[0]">
                <div className="back h-[44px] w-full relative flex justify-center items-center">
                    <Back title={currCharacterInfo.name} callback={() => { goToTalk() }}></Back>
                </div>
                <div className="toggle h-[74px] flex items-center justify-center">
                    <div className="h-[44px] bg-[#000]/50 rounded-full w-[80%] grid grid-cols-2 gap-0 justify-center items-center">
                        <ClipPathBorderRadius setButtonActive={() => setButtonActive(1)} buttonActive={buttonActive} borderRadius={22} height={44} width={125} flag={1} text={'Mine'}></ClipPathBorderRadius>
                        <ClipPathBorderRadius setButtonActive={() => { setSaleCardsData([]); isInitLoading.current = true; setButtonActive(2); currPage.current = 1 }} getCardsData={getCardsData} loading={isInitLoading.current && isLoading.current} Refresh={true} buttonActive={buttonActive} borderRadius={22} height={44} width={125} flag={2} text={`Sale ${total.current ? total.current : ""}`} ></ClipPathBorderRadius>
                        {/* {
                            buttonActive === 2 && <div className='search absolute right-8' onClick={() => { dispatch(changeActive("-1")); navigator("/search"); }}>
                                <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                    
                                    <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g id="10-cards-sale" transform="translate(-341.000000, -56.000000)" fill="#FFFFFF" fillRule="nonzero">
                                            <g id="编组" transform="translate(341.000000, 56.000000)">
                                                <path d="M17.7932931,16.780932 L14.4487269,13.4363657 C15.6420385,12.0147435 16.3617488,10.1821212 16.3617488,8.18082326 C16.3617488,3.66268725 12.6990615,0 8.18092555,0 C3.66268725,0 0,3.66258496 0,8.18072097 C0,12.698857 3.66268725,16.3615442 8.18082326,16.3615442 C10.1821212,16.3615442 12.0147435,15.6419362 13.4363657,14.4486246 L16.780932,17.7931908 C17.0609287,18.0699859 17.5119001,18.0687007 17.7903146,17.7903143 C18.0687292,17.5119279 18.0700599,17.0609566 17.7932931,16.780932 L17.7932931,16.780932 Z M1.43164663,8.18072097 C1.43164663,4.45921714 4.45931943,1.43154434 8.18082326,1.43154434 C11.9023271,1.43154434 14.9299999,4.45921714 14.9299999,8.18072097 C14.9299999,11.9022248 11.9023271,14.9298976 8.18082326,14.9298976 C4.45931943,14.9298976 1.43164663,11.9022248 1.43164663,8.18072097 Z" id="形状"></path>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        } */}
                    </div>

                </div>
            </div>

            <>
                {buttonActive == 1 && <Mine id={currCharacterInfo.id}></Mine>}
                {
                    buttonActive == 2 &&
                    <List className="pt-8" finished={finished} loading={isLoading.current} immediateCheck={false} onLoad={onLoad} loadingText="Loading...">
                        {
                            saleCardsData.length ? <Sale saleCardsData={saleCardsData} setSaleCardsData={setSaleCardsData}></Sale> : null
                        }
                    </List>
                }
            </>
        </div>
    )
}

export default changeBackground;