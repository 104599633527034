import { Dialog } from "react-vant"
import close from "@/assets/images/task/close.png"
import selectActive from "@/assets/images/selectActive.png"
import { useEffect, useRef, useState } from "react"
import './index.less'
import { useNavigate } from "react-router-dom"
import { getRefiningTips } from "@/api/url.js"
import { HighlightPercentages } from "../../utils"
import CloseComp from "../common/CloseComp"
import ButtonComp from "../common/ButtonComp"

interface DialogProps {
  dialog: boolean
  setDialog: Function
  tips: Array<string>
  tipsData: string
}
const CardRefiningGuide = ({ tipsData, dialog, setDialog, tips }: DialogProps) => {

  const dialogRef = useRef<any>(null)
  const [checkValue, setCheckValue] = useState(false)
  const navigate = useNavigate()
  var mo = function (e: any) { e.preventDefault(); };
  useEffect(() => {

  }, [dialogRef.current])
  return (
    <Dialog
      className={`overflow-visible`}
      visible={dialog}
      showConfirmButton={false}
      showCancelButton={false}
      onClose={() => { setDialog(false) }}
      width={'100%'}
    >
      <div ref={dialogRef} style={{ backgroundSize: "100% 100% !important" }} className={`refining mx-[16px] !bg-[length:100%_100%] overflow-visible px-[10px] pt-10 relative pb-[32px] bg-[url(@/assets/images/refining/card_refining_guide.png)] `}>

 

        <CloseComp position="absolute" top={14} right={16} callBack={() => {
          getRefiningTips(checkValue)
          setTimeout(() => {
            navigate("/refining")
            // navigate("/refining-failure")
          }, 500)
          setDialog(false)
        }}></CloseComp>
        <p className=" font-bold text-[20px] text-[#333] mb-5 leading-[24px] text-center">Card Refining Guide</p>
        {tips.map((e, index) =>
          <div key={`${index}-Card Refining Guide`} className="bg-gradient-to-r items-center inline-block from-[#00E49F] to-[#54ADFF] mb-[10px] rounded-[10px] text-[14px] leading-[23px] font-semibold p-[10px] text-[#333]"
            dangerouslySetInnerHTML={{ __html: HighlightPercentages(e) }}>
          </div>
        )}
        <div className="flex gap-1 justify-center mt-5 items-center">
          {!checkValue ?
            <div onClick={() => {
              setCheckValue(!checkValue)
            }} style={{ border: '1px solid #333333' }} className=" w-[14px] h-[14px] rounded-full "></div>
            :
            <img onClick={() => {
              setCheckValue(!checkValue)
            }} className=" w-[14px]" src={selectActive} alt="" />
          }
          <p onClick={() => {
            setCheckValue(!checkValue)
          }} className="text-[14px] leading-[18px] font-semibold text-[#333]">{tipsData}</p>
        </div>
        <div className=" w-full flex justify-center">
          <ButtonComp title="OK" callBack={()=>{ getRefiningTips(checkValue)
              setTimeout(() => {
                navigate("/refining")
                // navigate("/refining-failure")
              }, 500)
              setDialog(false)}}></ButtonComp>
        </div>
      </div>
    </Dialog>
  )
}
export default CardRefiningGuide
