import BattleOfThePeaks from "../../pages/battle/battleOfThePeaks";
import Ranks from "../../pages/battle/ranks";
import Records from "../../pages/battle/records"

const pkRouter = () => {
    return [
        {
            path: "/pk-records",
            element: <Records></Records>
        },
        {
            path: "/pk-ranks",
            element: <Ranks></Ranks>
        },
        {
            path: "/peaks-battle",
            element: <BattleOfThePeaks></BattleOfThePeaks>
        }
    ]
}

export default pkRouter;



