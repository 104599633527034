import { useNavigate, useSearchParams } from "react-router-dom"
import BackComp from "../../components/common/BackComp"
import task_mining from '@/assets/images/task/task_mining.png'
import star from "@/assets/images/rules/star.png"
import { getTaskBannerMsg } from "@/api/url.js"
import { useEffect, useState } from "react"
import { Toast } from "react-vant"

const EventAnnouncement = () => {
  const [searchParams] = useSearchParams()
  const [bannerMsg, setBannerMsg] = useState<any>({})
  const navigate = useNavigate()
  const type: any = searchParams.get("type");

  function HighlightPercentages(text: string) {
    let highlightedText = text.replace(/{([^}]+)}/g, "<span style='background: linear-gradient(0deg, #00E49F 0%, #54ADFF 100%);background-clip: text;color: transparent;font-weight:800;font-size: 14px;line-height:18px;'>$1</span>");
    highlightedText = highlightedText.replace(/\[([^\]]+)\]/g, "<span style='background: linear-gradient(45deg, #FFE600 0%, #FFAB00 100%);;background-clip: text;color: transparent;font-weight:800;font-size: 14px;line-height:18px;'>$1</span>");
    return highlightedText
  }
  const getBannerMsg = async () => {
    const toast = Toast.loading({
      message: "Loading...",
      forbidClick: true,
      duration: 0
    })
    const { data, code } = await getTaskBannerMsg(type)
    if (code == '0') {
      setBannerMsg(data)
    }
    toast.clear()
  }
  useEffect(() => {
    getBannerMsg()
  }, [])
  return (
    <div className="px-4 h-[100vh] py-[53px] overflow-auto">
      <BackComp background="#000" position={true} title="Event Announcement" callback={() => { navigate(-1) }}>
      </BackComp>

      <img onClick={() => {
        navigate('/eventAnnouncement')
      }} className='w-[100%]' src={bannerMsg.image_url} alt="" />
      <p className=" text-style-1 my-[10px] text-[16px] leading-[19px] font-bold">{bannerMsg.title}</p>
      <div className=" bg-[#333333] rounded-[10px] w-full p-[10px] pt-0">
        {
          Array.isArray(bannerMsg.items) && bannerMsg.items[0].map((el: any, index: number) => (
            <div key={`${index}-eventAnnouncementText-0`} className="">
              <div className="flex pt-[10px] gap-1"> <img className="w-[14px] h-[14px]" src={el.icon} alt="" /><p className=" text-style-1 text-[16px] leading-[19px] w-fit font-bold">{el.title}</p></div>
              {el.text.map((el: any, index: number) => (
                <div className="flex mt-[10px]" key={`text-${index}-0-1`}>
                  <img className="w-[14px] mr-1 mt-[1px] h-[14px]" src={star} alt="" />
                  <p className="text-[14px] leading-[18px] font-semibold text-[#ffffff]" dangerouslySetInnerHTML={{ __html: HighlightPercentages(el) }}></p>
                </div>
              ))}
            </div>
          ))
        }
      </div>
      <div className=" bg-[#333333] mt-[10px] rounded-[10px] w-full p-[10px] pt-0">
        {
          Array.isArray(bannerMsg.items) && bannerMsg.items[1].map((el: any, index: number) => (
            <div key={`${index}-eventAnnouncementText-1`} className="">
              <p className="pt-[10px] text-style-1 text-[14px] leading-[18px] w-fit font-bold">{el.title}</p>
              {el.text.map((el: any, index: number) => (
                <div className="flex mt-[4px] w-[305px]" key={`text-${index}-1-1`}>
                  <img className="w-[14px] mr-1 mt-[1px] h-[14px]" src={star} alt="" />
                  <span className="text-[14px] w-[250px] break-words leading-[18px] font-semibold text-[#ffffff]">{el}</span>
                </div>
              ))}
            </div>
          ))
        }
      </div>
    </div>
  )
}
export default EventAnnouncement