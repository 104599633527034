import { Dialog } from "react-vant"
import coins from '@/assets/images/task/coins.png'
import { getLevelImg } from "../utils/levelList"
import { useDispatch, useSelector } from "react-redux"
import { changeUserInfo } from "../store/module/tabbar"
import { useEffect, useRef, useState } from "react"
import usePlaySound from "@/hook/usePlaySound.tsx"
import { formatNumber } from "../utils"
import ButtonComp from "./common/ButtonComp"

interface UpLevelDialogProps {
  dialog?: boolean
  getCoinAnimation: Function
}
const UpLevelDialog = ({ getCoinAnimation }: UpLevelDialogProps) => {
  const dispatch = useDispatch()
  const coinsPlaySound = usePlaySound('coins')
  const coinsRef = useRef<any>(null)
  const [show, setShow] = useState(true)
  const [up, setUp] = useState(true)
  const userInfo = useSelector((state: any) => state.tabbar.userInfo)
  function closeDialog() {
    const newUserInfo = { ...userInfo }
    newUserInfo.up = false
    setUp(!up)
    dispatch(changeUserInfo(userInfo))
  }
  const img = getLevelImg(userInfo.level)
  function getCoordinate() {
    if (userInfo.rewards) {
      coinsPlaySound()
      getCoinAnimation(coinsRef.current.getBoundingClientRect().x, coinsRef.current.getBoundingClientRect().y)
    }
    setShow(false)
    setTimeout(() => {
      setShow(true)
    }, 250)
  }
  const dialogRef = useRef<any>(null)
  var mo = function (e: any) { e.preventDefault(); };
  useEffect(() => {

  }, [dialogRef.current])
  return (
    <Dialog
      visible={userInfo.up && up}
      showConfirmButton={false}
      showCancelButton={false}
      closeOnClickOverlay={true}
      onClose={() => closeDialog()}
      width={262}
    >
      <div ref={dialogRef} className='text-center relative'>
        <div className="relative mb flex justify-center items-center">
          <img src={img} className="w-[158px] h-[179px]" alt="" />
          <div className="text-style-2 text-[transparent] absolute bottom-[19px]">Lv{userInfo.level}</div>
        </div>
        {!!userInfo.rewards && <div className="flex justify-center items-center gap-[10px] text-[20px] mt-[22px] mb-[57px] leading-[24px] font-bold text-style-6">
          +{formatNumber(userInfo.rewards)} {show && <img ref={coinsRef} className="w-[33px] h-[22px]" src={coins} alt="" />}
        </div>}
        <div className=" flex justify-center items-center w-full">
          <ButtonComp title="OK" callBack={() => {
            closeDialog()
            getCoordinate()
          }}></ButtonComp>
        </div>
      </div>
    </Dialog>
  )
}
export default UpLevelDialog
