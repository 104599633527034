
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ConnectWallet from '../common/ConnectWallet';
import nft from "../../assets/images/nftIcon.png"
import wallet from "../../hook/wallet/contentWallet"
import WebApp from '@twa-dev/sdk';
import PromptDialog from '../PromptDialog';
import ButtonComp from '../common/ButtonComp';


const NftMint = () => {

    const { useConnectWallet, useGetWalletAddress } = wallet();
    const address = useGetWalletAddress()

    const isShowWallet = useRef(false)
    const navigate = useNavigate()
    const [nftLink, setNftLink] = useState(false)

    useEffect(() => {
        if (address) {
            // setIsShowWallet(false)
            // const url = import.meta.env.VITE_TRADE_URL + address
            // setTimeout(() => {
            //     WebApp.openLink(url)
            // }, 2000)

        }
    }, [address])


    const connectWalletHandle = () => {
        // navigate('/cards?id=1')
        // if (!address) {
        //     useConnectWallet()
        //     // isShowWallet.current = true
        // } else {
        //     const url = import.meta.env.VITE_TRADE_URL + import.meta.env.VITE_MINT_CONTRACT_ADDRESS
        //     WebApp.openLink(url)
        // }
        setNftLink(true)




    }

    return (
        <>
            <img className={`fixed z-[800] w-[52px] right-[16px] top-[180px]`} onClick={(event) => {
                event.stopPropagation();
                connectWalletHandle()
            }} src={nft} alt="" />
            {/* {
                isShowWallet.current && <ConnectWallet></ConnectWallet>
            } */}
            <PromptDialog isClose={true} title={'Attention'} dialog={nftLink} setDialog={setNftLink} callback={() => {

            }} text={"Please select the NFT collection you want to go to."}>
                <ButtonComp title='StarAI Cards' mt={30} callBack={() => {
                    const url = import.meta.env.VITE_TRADE_URL + import.meta.env.VITE_MINT_CONTRACT_ADDRESS
                    WebApp.openLink(url)
                }}></ButtonComp>
                <ButtonComp title='StarAI Agents' mt={20} callBack={()=>{
                    const url = import.meta.env.VITE_TRADE_URL + import.meta.env.VITE_AGENT_MINT_CONTRACT_ADDRESS
                    WebApp.openLink(url)
                }}></ButtonComp>
            </PromptDialog>

        </>

    )
}


export default NftMint;
