import { memo, useEffect, useLayoutEffect, useMemo, useState } from "react";
import PayDialog from "../common/PayDialog";
import { Toast } from "react-vant";
import { getShopPayConfig } from "@/api/shop.js"


interface PropType {
    type: number
    closeHandle: (orderID?: string) => void
    teleport?: any
}
const nsfwPay = ({ closeHandle, type, teleport }: PropType) => {
    const [pay, setPay] = useState<any>([])

    const text = useMemo(() => {
        if (type === 15) {
            return "You must be at least 18 years old to enable NSFW content of the AI Agent. You can make payments through the following three methods to unlock it. The validity period is six months."
        }
        return "You must be at least 18 years old to enable NSFW content of the Card. You can make payments through the following three methods to unlock it. The validity period is six months."
    }, [type])

    useLayoutEffect(() => {
        getPayConfigHandle()
    }, [])
    const getPayConfigHandle = async () => {
        const toast = Toast.loading({
            message: "Loading...",
            forbidClick: true,
            duration: 0
        })
        try {
            const { code, data } = await getShopPayConfig(type)
            if (code == "0") {
                let list = data.map((item: any) => {
                    return {
                        ...item,
                        title: type == 15 ? "Agent NSFW" : "Card NSFW"
                    }
                })
                setPay(list)
            }
            toast.clear()
        } catch (e) {
            toast.clear()
        }

    }
    return (
        <PayDialog teleport={teleport||document.querySelector("body")} dataList={pay} title={`Warning`} text={text} text_1="Do you want to proceed?" callBack={(val) => {

            if (val != 'close') {
                console.log(val)
                closeHandle(val)
                // console.log(val)
            }else{
                closeHandle()
            }
        }} />
    )
}


export default nsfwPay