import { useEffect, useState } from 'react'
import WebApp from "@twa-dev/sdk"
import { useDispatch, useSelector } from 'react-redux';
import { changeShowRedPacketStatus, changeAwardData } from '../../store/module/lottery'
import { getCardLottery, getUserInfo } from '@/api/url.js'
import { getShopPayConfig } from "@/api/shop.js"
import pointer from "@/assets/images/myInvites/pointer.png"
import ball from "@/assets/images/myInvites/ball.png"
import finger from "@/assets/images/myInvites/finger.png"

import './index.less'
import PromptDialog from '../PromptDialog'
import { Toast } from 'react-vant';
import { changeUserInfo } from '../../store/module/tabbar';
import { formatNumber } from '../../utils';
import PayDialog from '../common/PayDialog';




interface turntableProps {
  lottery: Array<any>
  number_of_draws?: number
  setActivity_list: Function
  setUserPddInfo: Function
  prizeListDom: Array<any>
  itemNum: number
  offset: number
  getLottery?: any
  isStar?: boolean
  type?: string
  orderId?: string
  dataLottery?: any

}
const turntable = ({ lottery, number_of_draws, setActivity_list, setUserPddInfo, prizeListDom, itemNum, offset, getLottery, isStar, orderId, type = 'pdd', dataLottery }: turntableProps) => {
  const dispatch = useDispatch();
  const isShowRedPacket = useSelector((state: any) => state.lottery.isShowRedPacket)
  const [payList, setPayList] = useState([])
  const [promptText, setPromptText] = useState('')
  const [dialog, setDialog] = useState(false)
  const address_params = localStorage.getItem('address_params')
  const [coinTurntable, setCoinTurntable] = useState(false)
  const [turntableData, setTurntableData] = useState<any>({
    arr: [1, 2, 3, 4],
    lightNum: 18, // 转盘上的灯
    light: null, // 转盘旋转灯容器
    turntable: null, // 转盘
    bg: null, // 转盘背景
    gift: null, // 转盘上的中奖结果图
    pointer: null, // 转盘指针
    lottery: [], // 中奖数据
    typeMap: { 1: '¥', 2: '谢谢参与' },
    typeClassMap: { 1: '', 2: 'no-gift' },
    isGoing: false, // 游戏是否开始
  })
  function init() {
    if (!turntableData.lottery.length) {
      turntableData.pointer.style.display = 'none';
    }
  }
  const [lotteryDraw, setLotteryDraw] = useState(false)

  const pddLottery = async () => {
    if (lotteryDraw) return
    if (type == 'card' && !orderId && dataLottery.lottery == 0) {
      setCoinTurntable(true)
    } else {
      await lotteryHandleCallback()
    }
    // setUserPddInfo()
  }

  const lotteryHandleCallback = async () => {
    try {
      setLotteryDraw(true)
      const { code, data, msg } = await getLottery()
      if (code == "0") {
        gameStart(data.code)
        dispatch(changeAwardData(data))
      } else {
        if (code != '65541') {
          setPromptText(msg)
          setDialog(true)
        }
        setLotteryDraw(false)
      }
    } catch (e) {
      setLotteryDraw(false)
      setPromptText("There’s a network issue. Please try again.")
      setDialog(true)
    }
  }
  const callbackTurntable = async () => {
    if (lotteryDraw) return
    try {
      setLotteryDraw(true)
      const { code, data, msg } = await getLottery()
      if (code == "0") {
        gameStart(data.code)
        dispatch(changeAwardData(data))
      } else {
        setPromptText(msg)
        setDialog(true)
        setLotteryDraw(false)
      }
    } catch (e) {
      console.log("🚀 ~ callbackTurntable ~ e:", e)
      setLotteryDraw(false)
      setPromptText("There’s a network issue. Please try again.")
      setDialog(true)
    }
    setLotteryDraw(false)
    setUserPddInfo()
  }

  function getRandomInRange() {
    let min = -((360 / 2 / itemNum) - 5);
    let max = (360 / 2 / itemNum) - 5;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function gameStart(val: string) {
    if (turntableData.isGoing) {
      return
    }
    turntableData.isGoing = true;
    let res = turntableData.lottery.filter((item: any) => {
      return item.code == val
    })[0];
    let rotateItemDeg = (res.type - 1) * (360 / turntableData.lottery.length);
    let rotate = (rotateItemDeg + getRandomInRange() + 5 * 360)
    let rotateSpeed: any = (rotateItemDeg / 360 * 1 + 5).toFixed(2);
    turntableData.turntable.removeAttribute('style');
    setTimeout(() => {
      turntableData.turntable.style.transform = `rotate(${rotate}deg)`;
      turntableData.turntable.style.transition = `transform ${rotateSpeed}s ease-out`;
    }, 30)
    setTimeout(async () => {
      turntableData.isGoing = false;
      dispatch(changeShowRedPacketStatus(true))
      setActivity_list()
      setUserPddInfo()
      setLotteryDraw(false)
      const UserInfo = await getUserInfo(address_params)
      if (UserInfo.code == "0") {
        dispatch(changeUserInfo(UserInfo.data))
      }
    }, rotateSpeed * 1000);

  }

  const cardDrawPayList = async () => {
    const res = await getShopPayConfig(8)
    if (res.code == "0") {
      const list = res?.data.filter((item: any) => item.count == 1).map((item: any) => {
        return {
          title: "Card Draw",
          ...item
        }
      })
      console.log(list)
      setPayList(list)
    }
  }
  useEffect(() => {
    cardDrawPayList()
  }, [])

  useEffect(() => {
    turntableData.turntable = document.querySelector('#turntable')
    turntableData.light = document.querySelector('#turntable_light')
    turntableData.bg = document.querySelector('#turntable_bg')
    turntableData.gift = document.querySelector('#turntable_gift')
    turntableData.pointer = document.querySelector('#turntable_pointer')
    turntableData.lottery = lottery
    init();
  })
  let ArrLight = [1, 2, 3, 4, 5, 6, 1, 2, 3, 4, 5, 6, 1, 2, 3, 4, 5, 6]
  return (
    <div className="turntable-box">
      <div className="turntable-wrap">
        <div className="light" id="turntable_light">
          {ArrLight.map((el: any, index: number) => <span key={`${index}-turntable_light`} className='z-[1]' style={{ transform: `rotate(${(360 / ArrLight.length * index)}deg)` }}></span>)}
        </div>
        <div className="turntable" id="turntable">
          <div style={{ border: "2px solid #fff" }} className=' w-[calc(100%)] h-[calc(100%)] z-30 absolute rounded-full'></div>
          <div style={{ border: "2px solid #FDDD83" }} className=' w-[calc(100%_-_4px)] h-[calc(100%_-_4px)] z-30 top-[2px] left-[2px] absolute rounded-full'></div>
          <ul className="bg" id="turntable_bg">
            {prizeListDom.map((el: any, index: number) => <li key={`${index}-turntable_bg`} style={{ transform: `rotate(${360 / prizeListDom.length * index + offset}deg)` }} className={''}></li>)}
          </ul>

          <ul className="gift" id="turntable_gift">{
            prizeListDom.map((el: any, index: any) => (
              <li key={`turntable_gift-${index}`} style={{ transform: `rotate(${el.rotate}deg)`, height: `${el.Width}`, width: `${el.Width}`, backgroundColor: `${el.bgColor}` }}>
                <div>
                  <p className={`text-[14px] leading-[18px] text-[#C08010] font-semibold ${el.text}`}>{el.text}</p>
                  <img style={{ transform: `rotate(${el.imgRotate}deg)`, width: `${el.imgWidth}` }} src={el.img} alt="" />
                </div>
              </li>
            ))}
          </ul>

        </div>
        <div className='relative z-40 h-20 w-20'>
          <div className='px-[6px] text-[#fff] leading-[14px] absolute rounded-lg top-3 z-10 right-[-14px] font-bold h-4' style={{ background: 'linear-gradient( 135deg, #00E49F 0%, #54ADFF 100%)', border: '1px solid #FDDD83' }}>{number_of_draws}</div>
          <img className={` absolute -top-[18px] w-[30px] h-[26px] left-[50%] translate-x-[-50%]`} src={pointer} alt="" />
          <div style={{ border: '3px solid #FDF8E2' }} className="pointer box-border flex items-center justify-center disabled bg-[#F6DF98] h-20 w-20 " onClick={() => {
            if (!isShowRedPacket && !turntableData.isGoing) {
              pddLottery()
            }
          }} id="turntable_pointer">
            <img className='w-[72px] h-[72px] absolute' src={ball} alt="" />
            <img className='zoom absolute z-10 w-9 h-9 -right-3 bottom-0' src={finger} alt="" />
            <p className='spin text-[22px] z-[1]'>SPIN</p>
          </div>
        </div>
      </div>
      <PromptDialog title={'Attention'} dialog={dialog} setDialog={setDialog} text={promptText}></PromptDialog>

      {isStar && coinTurntable && type == 'card' && <PayDialog isNeedDialogSuccess={false} teleport={document.querySelector("body")} dataList={payList} title={`Card Draw`} text={""} text_1="" callBack={async (val) => {
        setCoinTurntable(false)
        if (val != 'close') {
          await lotteryHandleCallback()
          // closeHandle(val)
          // console.log(val)
        }
      }} />}
      {
        !isStar && type == 'card' && <PromptDialog
          title='Card Draw'
          isClose={true}
          lineBreak={true}
          dialog={coinTurntable}
          is_Stars={'coin'}
          price={formatNumber(dataLottery.price)}
          setDialog={setCoinTurntable}
          text_mt={10}
          text_1={' '}
          text={`Each draw consumes ${formatNumber(dataLottery.price)} $STAR, with a maximum of ${dataLottery.star_price} draws per day.`}
          callback={callbackTurntable}
        ></PromptDialog>
      }

    </div>
  )
}
export default turntable