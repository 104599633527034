
import { useCallback, useEffect, useRef, useState } from 'react'
import done from '@/assets/images/task/done.png'
import { useNavigate, useSearchParams } from 'react-router-dom';
import WebApp from '@twa-dev/sdk'
import lottery from '@/assets/images/home/lottery.png'
import up from '@/assets/images/home/up.png'
import card from '@/assets/images/home/card.png'
import task_check_in from '@/assets/images/task/task_check_in.png'
import right from '@/assets/images/task/right.png'
import task_mining from '@/assets/images/task/task_mining.png'
import coins from '@/assets/images/task/coins.png'
import banna_h5 from '@/assets/images/task/banna-h5.png'
import banna_tg from '@/assets/images/task/banna-tg.png'
import TWITTER from '@/assets/images/task/TWITTER.png'
import NFTTrading from '@/assets/images/task/NFTTrading.png'
import HOWTOPLAY from '@/assets/images/task/HOWTOPLAY.png'
import CROUPCHAT from '@/assets/images/task/CROUP&CHAT.png'
import CHANNEL from '@/assets/images/task/CHANNEL.png'
import day from '@/assets/images/task/day.png'
import quarterly from '@/assets/images/task/quarterly.png'
import weekly from '@/assets/images/task/weekly.png'
import tip from '@/assets/images/task/tip.png'
import coin from '@/assets/images/coin.png'

import ton_ai from '@/assets/images/ton_ai/ton_ai.png'
import ton_ai_1 from '@/assets/images/ton_ai/ton_ai_1.png'
import ton_ai_2 from '@/assets/images/ton_ai/ton_ai_2.png'
import ton_ai_3 from '@/assets/images/ton_ai/ton_ai_3.png'
import ton_ai_4 from '@/assets/images/ton_ai/ton_ai_4.png'
import ton_ai_5 from '@/assets/images/ton_ai/ton_ai_5.png'
import ton_ai_6 from '@/assets/images/ton_ai/ton_ai_6.png'
import ton_ai_7 from '@/assets/images/ton_ai/ton_ai_7.png'
import ton_ai_8 from '@/assets/images/ton_ai/ton_ai_8.png'
import ton_ai_9 from '@/assets/images/ton_ai/ton_ai_9.png'

import done_green from '@/assets/images/task/done-green.png'
import PublicDialog from '../../components/PublicDialog.tsx'
import { getTask, postCheck_in, getCheck_in, getClaimPoints, chainCheckIn, getUserInfo, getPddMarquee, getTaskBannerMsg } from '@/api/url.js'
import wallet from "../../hook/wallet/contentWallet.tsx"
import { useCheckInContract } from '@/hook/contract/useCheckInContract';
import { Address } from '@ton/core';
import { formatIdString, formatNumber } from '../../utils/index.ts'
import { useSelector, useDispatch } from 'react-redux'
import GetCoinAnimationDialog from '../../components/GetCoinAnimationDialog.tsx'
import { changeCheckDialog, changeUserInfo, setAddressParams } from '../../store/module/tabbar.ts'
import { changeCheck_in_loading, changeIsGate, changeTasks } from '../../store/module/tasks.ts'
import { changeTransactionStatus } from '../../store/module/tontransaction.ts'
import usePlaySound from "@/hook/usePlaySound.tsx"
import BScroll from 'better-scroll'
import { create } from "ad-pull";
import DynamicScript from '../../components/Script.tsx';
import { Circle, CountDown, Swiper, Toast } from 'react-vant';
import "./task.less"
import { judgeClient } from '../../utils/judgeClient.ts';
import ButtonComp from '../../components/common/ButtonComp.tsx';
import coinsMusic from "../../assets/mp3/coins.mp3"
import TonNetworkError from '../../hook/useTonNetworkError.tsx';
import walletHandle from "../../hook/wallet/connectOkWallet.tsx"
import { useTonWallet } from '@tonconnect/ui-react';
import Marquee from "react-fast-marquee";

import { Swiper as _Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css'; // 引入 Swiper 样式

// 如果需要使用 Swiper 的其他功能（如导航、分页等），可以按需引入
import { Navigation, Pagination, Autoplay } from 'swiper';
import './index.less'
import { getRandomNumber } from '../../utils/Number.ts';

new BScroll('.role-box', {
	pullUpLoad: true,
	scrollbar: true,
	pullDownRefresh: true
	// and so on
})

const tabArr = [
	{
		title: 'Basic Tasks',
		id: 3
	},
	{
		title: 'Daily Tasks',
		id: 2
	},
	{
		title: 'Partner Tasks',
		id: 1
	},
]
const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));
const Task = () => {

	const { useConnectWallet, useGetWalletAddress, useDisconnectWallet, handleGateConnectClick } = wallet();
	const address = useGetWalletAddress()
	const [params] = useSearchParams();
	const closePlaySound = usePlaySound('close')
	const [loading, setLoading] = useState(false)
	const [point, setPoint] = useState('')
	const isClaim = useRef(false)
	const coinDomCoordinate = useSelector((state: any) => state.tabbar.coinDomCoordinate)
	const okWalletAddress = useSelector((state: any) => state.ton.okWalletAddress)
	const isSendSuccess = useSelector((state: any) => state.ton.isSendSuccess)
	// const check_in_loading = useSelector((state: any) => state.tasks.check_in_loading)
	const address_params = localStorage.getItem('address_params')
	const dispatch = useDispatch()
	const [data, setData] = useState<any>(useSelector((state: any) => state.tasks.tasksData))
	const boxRef = useRef<any>(null)
	const [dialog, setDialog] = useState(false)
	const timeRef = useRef<any>(null)
	const [timeCount, setTimeCount] = useState();
	const [rate, setRate] = useState(100);
	const [claiming, setClaiming] = useState("");
	const [claimPointsDialog, setClaimPointsDialog] = useState(false)
	const [adContainer, setAdContainer] = useState(true)
	const [checkInData, setCheckInData] = useState<any>({})
	const [tonAi, setTonAi] = useState<any>([])
	const [active, setActive] = useState<any>(3)
	const [tabCountArray, setTabCountArray] = useState<any>(1)
	const [bannerMsg, setBannerMsg] = useState<any>([])
	const StarLogo = [ton_ai, ton_ai_1, ton_ai_2, ton_ai_3, ton_ai_4, ton_ai_5, ton_ai_6, ton_ai_7, ton_ai_8, ton_ai_9]
	const navigate = useNavigate()

	const [marqueeArray, setMarqueeArray] = useState<any>([])
	const getDataMarquee = async () => {
		const { code, data } = await getPddMarquee()
		if (code == '0') {
			setMarqueeArray(data.data)
		}
	}
	useEffect(() => {
		getDataMarquee()
	}, [])
	const getBannerMsg = async () => {
		const { data, code } = await getTaskBannerMsg()
		if (code == '0') {
			setBannerMsg(data)
		}
	}
	useEffect(() => {
		getBannerMsg()
	}, [])
	const getCheckInData = useCallback(async () => {
		const resGetCheck_in = await getCheck_in()
		setCheckInData(resGetCheck_in.data)
	}, [dialog])
	useEffect(() => {
		if (params.get('check_in')) {
			setDialog(true)
		}
	}, [])

	// useEffect(() => {
	//     TonAdInit()
	// }, [])

	const getTaskData = useCallback(async () => {
		const res = await getTask(0, 0)
		if (res.code == '0') {
			setData(res.data.task)
			dispatch(changeTasks(res.data.task))
			setTabCountArray(res.data.unfinished)
		}
		// setUserInfo()
	}, [])
	useEffect(() => {
		try {
			const ad = create({
				userId: localStorage.getItem('userId'),
				platform: WebApp.platform == 'unknown' ? 'web' : WebApp.platform,
				isPremium: WebApp.initDataUnsafe.user?.is_premium ? true : false

			});
			ad.addDid([
				{
					html: "#ad-container",
					placeCode: "1839150296874487808",
					onClick: () => {
						if (!data.filter((el: { code: string; }) => el.code == 'teleads')[0].state) {
							setTimeout(() => {
								getClaimPoints({ code: 'teleads', init_data: WebApp.initData || localStorage.getItem('init_data') }).then((res: any) => {
									setClaimPointsDialog(true)
									setPoint(res.data.points)
								})
							}, 5000)

						}
					},
					onClose: () => {
						setAdContainer(false)
					}
				},
			]);
			getTaskData()
		} catch {

		}

	}, [])

	const setCheck_in = useCallback(async () => {
		await postCheck_in({ init_data: WebApp.initData || localStorage.getItem('init_data') || import.meta.env.VITE_INIT_DATA })
		const resGetCheck_in = await getCheck_in()
		setUserInfo()
		setCheckInData(resGetCheck_in.data)
	}, [])
	useEffect(() => {
		if (Object.keys(checkInData).length === 0) {
			getCheckInData()
		}
	}, [getCheckInData])



	//签到时链接钱包弹窗的显示隐藏
	const [checkInWallet, setCheckInWallet] = useState(false)
	//已经签到的提示语弹窗
	const [readyCheck, setReadyCheckIn] = useState(false)
	const [walletReadyCheck, setWalletReadyCheck] = useState(false)
	const [tonNetworkVisible, setTonNetworkVisible] = useState(false)
	//链上交互
	//链上交互
	const { checkIn } = useCheckInContract()
	useEffect(() => {
		if (isSendSuccess == "1") {
			setTimeout(async () => {
				await checkInHandler()
			}, 2000)

		} else if (isSendSuccess == "-1") {
			setTimeout(async () => {
				setLoading(false)
			}, 1000)

		}
	}, [isSendSuccess])
	const checkInHandle = async () => {
		// if (checkInData.is_chain_check_in) {
		//     setReadyCheckIn(true);
		//     return false;
		// }
		if (loading) {
			return false;
		}
		if (!address) {
			setCheckInWallet(true)
			setDialog(false)
			return
		}

		setLoading(true)
		const { code } = await chainCheckIn({ check_in_addr: Address.parse(address).toString() })
		if (code == "0") {
			await checkIn()
		} else {
			setWalletReadyCheck(true)
		}
	}
	//签到函数
	const checkInHandler = async () => {
		setLoading(false);
		const address_params = Address.parse(address);
		dispatch(setAddressParams(address_params.toString()))

		try {
			const res = await chainCheckIn({ check_in_addr: address_params.toString(), is_ok: true })
			if (res.code == '0') {

				dispatch(changeCheck_in_loading({
					loading: true,
					msg: '',
					point: res.data.toString()
					// point: (Math.floor(Number(checkInData.check_in_rewards[checkInData.check_in_data - 1]) * 5 * (1 + (userInfo.ponits_boost / 1000)))).toString()

				}))
				getCheckInData()
				setUserInfo()
			}
			dispatch(changeTransactionStatus(null))
		} catch (e) {
		}

	}

	const walletInfo: any = useTonWallet()
	const isGateAddress = useSelector((state: any) => state.tasks.isGateAddress)
	useEffect(() => {
		if (walletInfo && walletInfo.name == "GateWallet" && isGateAddress == 'gate') {
			callback({
				code: "GateConnect",
				points: 5000
			})
		}
	}, [walletInfo])


	//钱包相关
	const [isConnect, setIsConnect] = useState(false)
	//链接钱包
	const connectWallet = async (el?: any) => {
		if (address) {
			return false
		}
		if (el.code == 'GateConnect') {
			handleGateConnectClick(el, callback)
		} else {
			useConnectWallet(el, callback);
		}

	}


	useEffect(() => {
		setIsConnect(false);
		if (address) {
			// setDialog(true)
			setCheckInWallet(false)
		}
	}, [address])

	//钱包已签到弹窗
	const walletReadyCheckIn = () => {
		return (
			<div className='flex flex-col items-center justify-around mx-auto h-[216px] py-5'>
				<div className='text-center w-full'>
					<span className='block text-sm text-[#333]'>This wallet have already checked in today.
					</span>
					<span className='text-sm text-[#333]'>Please try again one.</span>
				</div>
				<ButtonComp title="OK" callBack={() => {
					setWalletReadyCheck(false)
				}}></ButtonComp>

			</div>
		)
	}
	//用户钱包已签到弹窗
	const readyCheckIn = () => {
		return (
			<div className='flex flex-col items-center justify-around mx-auto h-[216px] py-5'>
				<div className='text-center w-full'>
					<span className='block text-sm text-[#333]'>You have already checked in today.
					</span>
					<span className='text-sm text-[#333]'> Please try again tomorrow.</span>
				</div>
				<ButtonComp title="OK" callBack={() => {
					setReadyCheckIn(false)
				}}></ButtonComp>

			</div>
		)
	}
	//签到链接钱包的弹窗
	const connect_wallet_button = () => {
		return (
			<div className='flex flex-col items-center justify-around mx-auto h-[216px] py-5'>
				<p className='text-sm text-[#333]'>Please connect your TON wallet.</p>
				<ButtonComp callBack={() => { connectWallet() }} title="Connect"></ButtonComp>
			</div>
		)
	}
	const callback = async (el: any) => {
		const { code } = await getClaimPoints({ code: el.code, init_data: WebApp.initData || localStorage.getItem('init_data') || import.meta.env.VITE_INIT_DATA })
		if (code == '0') {
			setPoint(el.points)
			setClaimPointsDialog(true)
			await getClaimPoints({ code: el.code, init_data: WebApp.initData || localStorage.getItem('init_data') || import.meta.env.VITE_INIT_DATA }) //领取奖励
			await getTaskData() //重置任务列表
		}
	}

	// ---------------------------金币掉落音效--------------------
	const isSoundPlay = useSelector((state: any) => state.settings.settings.sound)
	const AudioRef = useRef<any>()
	const getCoinAnimation = useCallback((x: any, y: any) => {
		const R = 100; // 扩散半径
		const time = 0.25; //扩散过度时间
		const time2 = 1; // 收集过度时间
		const w = 26; // 金币大小
		const Num = 8 //金币数量
		setlits(x, y)


		if (AudioRef.current) {
			// AudioRef.current.currentTime = 0;
			AudioRef.current.play()
		}

		const total = document.getElementsByClassName('total_1')[0] // 获取金币最终飞向
		function setlits(X: any, Y: any) {
			var species = document.createElement('div');
			species.className = 'species'
			for (let i = 0; i < Num; i++) {
				var img = document.createElement('img');
				img.className = 'speciesstyle'
				img.style.width = w + 'px',
					img.style.height = w + 'px',
					img.style.left = X + 'px',
					img.style.top = Y + 'px',
					img.style.transform = 'translate(0%, 0%)',
					img.style.transition = `${time}s`
				img.src = coin
				species.appendChild(img);
			}
			boxRef.current.appendChild(species)
			setTimeout(() => {
				clickss(X, Y)
			}, 0)
		}
		// 计算金币扩散范围和坐标
		function clickss(X: number, Y: number) {
			const specielist: any = document.getElementsByClassName('speciesstyle')
			const num = specielist.length
			const center = {
				x: X,
				y: Y
			}
			for (let i = 0; i < num; i++) {
				const xz = center.x + Math.floor(Math.random() * ((R * 1.1) - (R * 0.6) + 1)) + (0.6 * R) * Math.sin(2 * Math.PI * i / num)
				const yz = center.y + Math.floor(Math.random() * ((R * 1.1) - (R * 0.6) + 1)) + (0.6 * R) * Math.cos(2 * Math.PI * i / num)
				specielist[i].style.transform = `translate(${xz - (X + w / 2)}px, ${yz - (Y + w / 2)}px)`
			}
			setTimeout(() => {
				for (let i = 0; i < num; i++) {
					specielist[i].style.transition = `${time2}s`
					specielist[i].style.transform = `translate(0,0)`
					specielist[i].style.left = Math.floor(total?.getBoundingClientRect().x) + 'px'
					specielist[i].style.top = Math.floor(total?.getBoundingClientRect().y) + 'px'
				}
				// 结束清除蒙层
				setTimeout(() => {
					remove()
				}, (Number(time2) + 0.5) * 500) //飞行时间结束

			}, (Number(time) + 0.5) * 500) //扩散时间结束
		}
		// 清除dom
		function remove() {
			document.getElementsByClassName('species')[0].remove()
		}
		setDialog(false)
		getTaskData()
		setUserInfo()
	}, [coinDomCoordinate, dialog])

	const setUserInfo = useCallback(async () => {
		const UserInfo = await getUserInfo(address_params)
		if (UserInfo.code == "0") {
			dispatch(changeUserInfo(UserInfo.data))

		}
	}, [])

	const dialogContent = () => {
		return (
			<div className=' px-[14px] text-center'>
				<div className='text-center w-full pt-[42px] font-bold leading-6 text-[20px]'>Daily Check-in Rewards</div>
				<div className='flex  flex-wrap justify-center gap-[6px] mb-[30px] mt-5'>
					{checkInData.check_in_rewards?.map((el: any, index: number) => {
						return (
							<div key={`Check-in${index}`} className={`bg-[#fff] ${checkInData.check_in_data > index ? 'opacity-50' : ''} rounded-[10px]  ${index == 6 ? 'w-[158px]  h-[70px] ' : ' w-[76px]  h-[70px]'} flex flex-col items-center py-[6px] text-center text-[12px] leading-[15px]`}>
								Day {index + 1}

								{el.rewardType == 'star' ?
									<>
										<img className={`w-[21px] h-[14px] my-[7px] ${checkInData.check_in_data > index ? 'hidden' : ''}`} src={coins} alt="" />
										<img className={`w-[18px] h-[18px] my-[5px] ${checkInData.check_in_data > index ? '' : 'hidden'}`} src={done_green} alt="" />
									</> :
									el.rewardType == 'card' ?
										<div className={`text-center  text-[12px] flex items-center gap-1 leading-[15px] ${checkInData.check_in_data > index ? '' : 'text-style-6'}`}> <img className='w-[16px] h-6' src={card} alt="" /> x1</div>
										:
										<div className={`text-center text-[12px] flex items-center gap-1 leading-[15px] ${checkInData.check_in_data > index ? '' : 'text-style-6'}`}> <img className='w-[30px] h-[30px]' src={lottery} alt="" /> x1</div>
								}

								<span className={`text-center text-[12px] leading-[15px] ${checkInData.check_in_data > index ? '' : 'text-style-6'}`}>
									{el.rewardType == 'star'
										? `+${formatNumber(el.reward)}` : el.rewardType == "card" ? 'Card' : 'Invite Lottery'
									}
								</span>

							</div >
						)
					})}
					<div className='bg-gradient-to-r mt-[10px] w-full text-[14px] px-5 leading-[18px flex items-center justify-between text-[#333333] relative h-[60px] rounded-[10px] from-[#00E49F] to-[#54ADFF]'>
						{checkInData?.is_chain_check_in &&
							<div style={{
								width: ' 24px',
								height: ' 24px',
								background: 'linear-gradient( 125deg, #EF4444 0%, #E88585 100%)',
								borderRadius: '0px 10px 0px 10px '
							}} className=' absolute flex justify-center items-center right-0 top-0'>
								<img className=' w-[18px] ' src={done} alt="" />
							</div>
						}
						<p>Today of TON Check-in</p>
						<div className='flex items-center gap-1'>
							{Array.isArray(checkInData?.check_in_rewards) && checkInData?.check_in_data != 0 &&
								<>
									{checkInData?.check_in_rewards[checkInData?.check_in_data - 1]?.rewardType == 'star' ?
										<div className='flex items-center gap-1'>
											<img className=' w-[31px]' src={coins} alt="" />
											<span className='text-style-3 !text-[14px] !leading-[18px] font-bold'>
												+{formatNumber(checkInData?.chain_check_in_rewards[checkInData?.check_in_data - 1]?.reward)}
											</span>
										</div>
										:
										<div className='flex items-center gap-1'>
											<img className=' h-[30px]' src={checkInData?.check_in_rewards[checkInData?.check_in_data - 1]?.rewardType == 'lottery' ? lottery : card} alt="" />
											<span className='text-style-3 !text-[14px] !leading-[18px] font-bold'>
												x{checkInData?.chain_check_in_rewards[checkInData?.check_in_data - 1]?.reward}
											</span>
										</div>}
								</>}
						</div>
					</div>
				</div >
				<div className='relative w-[174px] h-[36px] mx-auto' >
					{!loading
						&&
						<img className=' absolute -top-[13px] w-[27px] right-0' src={up} alt="" />
					}
					<div onClick={async (event) => {
						event.stopPropagation();
						dispatch(changeTransactionStatus(null))
						await checkInHandle()

					}} className='bg-gradient-to-r w-[174px] flex justify-center items-center rounded-[10px] button_shadow h-[36px] text-[#fff] from-[#00E49F] to-[#54ADFF]'>{!loading ? 'Check-in on TON' : <img className='w-5 h-5 animate-spin' src={loadingImg} />}</div>
				</div>
			</div >
		)
	}

	useEffect(() => {

		try {
			let limit = 0
			data.forEach((element: any) => {
				if (element.code.includes("Ton.Ai")) {
					limit++
				}
			});
			const options = {
				method: 'GET',
				headers: {
					accept: 'application/json',
					'x-api-key': import.meta.env.VITE_TONAI_KEY,
				}
			};
			fetch(`https://app.ton.ai/api/v2/openapi/matching/exchange?exchangeCampaignId=${import.meta.env.VITE_TONAI_ID}&telegramUserId=${localStorage.getItem('userId')}&limit=${limit}`, options)
				.then(response => response.json())
				.then(response => {
					setTonAi(response.data.exchangeCampaigns)
				}).catch(err => console.error(err));
		} catch {

		}

	}, [])

	const reportExchange = (index: number) => {
		const options = {
			method: 'POST',
			headers: {
				accept: 'application/json',
				'content-type': 'application/json',
				'x-api-key': import.meta.env.VITE_TONAI_KEY,
			},

			body: JSON.stringify({
				eventType: 'click',
				eventData: {
					telegramUserId: localStorage.getItem('userId'),
					inExchangeCampaignId: tonAi[index].inExchangeCampaignId,
					outExchangeCampaignId: tonAi[index].outExchangeCampaignId
				}
			})
		};
		fetch('https://app.ton.ai/api/v2/openapi/exchange/event/report', options)
			.then(response => response.json())
			.then(response => console.log(response))
			.catch(err => console.error(err));
	}
	//倒计时
	const timeChangeHandle = (val: any) => {
		setRate(100 / 15 * val.seconds)
		setTimeCount(val.seconds)
	}
	//点击完成任务 跳转链接
	const [taskLoading, setTaskLoading] = useState(false)
	let time: any = null
	const taskLinkHandle = async (e: any, el: any) => {
		console.log("🚀 ~ taskLinkHandle ~ el:", el)
		clearTimeout(time)
		if (!taskLoading) {
			setTaskLoading(true)
			e.stopPropagation()
			closePlaySound()
			if (el.code !== 'ton') {
				if (el.code.includes('tg_')) {
					WebApp.openTelegramLink(judgeClient() == 'IOS' ? el.ios_link : el.link)
				} else {
					if (el.code.includes("Ton.Ai")) {
						reportExchange(el.image)
						WebApp.openTelegramLink(tonAi[el.image].destination.url)
					} else {
						WebApp.openLink(judgeClient() == 'IOS' ? el.ios_link : el.link)
					}
				}
				if (!el.state && el.claim_state !== 0) {
					updateClaimState(el, 2).then(() => {
						getTaskData() //重置任务列表
					})
				}
			}
		}
		time = setTimeout(() => {
			setTaskLoading(false)
		}, 1000)

	}
	const claimHandle = async (el: any) => {
		if (claiming) {
			return false;
		}
		setClaiming(el.code)
	}

	//更新claim状态以及任务列表
	const updateClaimState = async (el: any, type: number) => {
		const res = await getClaimPoints({ code: el.code, init_data: WebApp.initData || localStorage.getItem('init_data'), status: el.claim_state == -1 ? 1 : type })
		await sleep(1000)
		return res.data.points || 0
	}
	// ------------------------ 滑动 -----------------------
	useEffect(() => {
		document.addEventListener('touchstart', touchStartHandle);
		return () => {
			document.removeEventListener('touchstart', touchStartHandle);
			// window.scrollTo(0, 0);
		}

	})

	const touchStartHandle = () => {
		window.scrollTo(0, 1);
		ensureDocumentIsScrollable();
	}

	const ensureDocumentIsScrollable = () => {
		const isScrollable = document.documentElement.scrollHeight > window.innerHeight;
		if (!isScrollable) {
			document.documentElement.style.setProperty(
				"height",
				"calc(100vh + 0.5px)",
				"important"
			);
		}
	}

	const [okWallet, setOkWallet] = useState(false)
	const okWalletInfo = useRef<any>(null)
	const isTimeFinish = useRef(false)
	const taskCode = useRef("")

	const { connectHandle, disConnectHandle } = walletHandle()
	useEffect(() => {
		if (okWalletAddress && !okWalletInfo.current?.state) {
			okWalletRask()
		}
	}, [okWalletAddress])

	const okWalletRask = async () => {
		const { code } = await getClaimPoints({ code: okWalletInfo.current.code, init_data: WebApp.initData || localStorage.getItem('init_data') || import.meta.env.VITE_INIT_DATA })
		if (code == '0') {
			setPoint(okWalletInfo.current.points)
			setClaimPointsDialog(true)
			await getClaimPoints({ code: okWalletInfo.current.code, init_data: WebApp.initData || localStorage.getItem('init_data') || import.meta.env.VITE_INIT_DATA }) //领取奖励
			await getTaskData() //重置任务列表
		}
	}


	const getRandom = useCallback((count: number) => {
		const num = getRandomNumber()
		return count == 1 ? num < 6 : count == 2 ? num > 36 : count > 2 ? true : false
	}, [])


	// ------------------------ 滑动 -----------------------

	return (
		<div ref={boxRef} className="Tasks pt-[43px] pb-[122px] role-box overflow-auto h-[100vh] Tasks !w-full">
			<div className=' w-[100vw] fixed z-[2000] top-0 left-0  overflow-auto'>
				{/* <Marquee>
		
				</Marquee> */}
				<Marquee>
					<div onClick={() => {
						navigate('/my-invites')
					}} className='py-[7px] flex w-fit gap-[6px]'>
						{marqueeArray.map((el: any, index: any) => (
							<div key={`${el.account_id}-${index}-marquee`} style={{ background: 'linear-gradient( 90deg, #00E49F 0%, #54ADFF 100%)' }} className='p-[1px] flex-shrink-0 w-fit rounded-[15px] h-[30px]'>
								<div className='px-[6px] text-[12px] leading-[15px] font-medium text-[#ffffff] h-[28px] rounded-[15px] bg-black flex justify-center items-center'>
									{el.name || formatIdString(el.account_id, 3, 3)} received &nbsp;<p className=' font-medium text-style-6 !text-[12px] !leading-[15px] '>{el.amount} USDT</p>
								</div>
							</div>))}
					</div>
				</Marquee>

			</div>
			<div className='px-4'>

				<_Swiper
					modules={[Navigation, Pagination, Autoplay]}
					spaceBetween={0}
					slidesPerView={1}
					autoplay={{ delay: 2000 }}
					loop
				>
					{Array.isArray(bannerMsg?.banner_msgs) && bannerMsg?.banner_msgs.map((el: any, index: number) =>
						<SwiperSlide key={`${index}-banner_msgs`}>
							<img
								onClick={(e: any) => {
									e.stopPropagation(),
										navigate(`/eventAnnouncement?type=${el.name}`)
								}}
								src={el.image_url}
								alt={`Slide ${index + 1}`}
								style={{ width: '100%', height: 'auto' }}
							/>
						</SwiperSlide>
					)}
				</_Swiper>

				{/* <Swiper autoplay={3000} loop className='w-[100%]' >
					{Array.isArray(bannerMsg?.banner_msgs) && bannerMsg?.banner_msgs.map((el: any, index: number) =>
						<Swiper.Item key={`${index}-banner_msgs`} onClick={(e: any) => {
							e.stopPropagation(),
								navigate(`/eventAnnouncement?type=${el.name}`)
						}} className='w-[100%] rounded-xl'>
							<img className='rounded-xl w-[100%]' src={el.image_url} alt="" />
						</Swiper.Item>
					)}
				</Swiper> */}
				<div className='w-[calc(100%)] mt-[10px]'>
					<div className='flex w-[100%] justify-between'>
						<img onClick={() => {
							WebApp.openTelegramLink('https://t.me/StarAI_Channel')
						}} className='w-[48%] flex-shrink-0' src={CHANNEL} alt="" />
						{/* -ml-[2%] */}
						<img onClick={() => {
							WebApp.openTelegramLink('https://t.me/StarAI_Chat')
						}} className='w-[56%] -ml-[4%] flex-shrink-0' src={CROUPCHAT} alt="" />
					</div>
					<div className='flex w-[100%] mt-[6px] justify-between'>
						<div className='flex-shrink-0 w-[57%]'>
							<img onClick={() => {
								WebApp.openLink(' https://x.com/The_StarAI')
							}} className='w-[91.7%]' src={TWITTER} alt="" />
							<img onClick={() => {
								WebApp.openLink('https://starai-1.gitbook.io/starai/user-guide')
							}} className='mt-[6px]' src={HOWTOPLAY} alt="" />
						</div>
						<div className='flex-shrink-0 -ml-[6%] w-[48.5%]'>
							<img onClick={() => {
								WebApp.openLink('https://getgems.io/collection/EQDXs6aceFC21bPYOVUhsRqGUv4nZbwZd_wamwFVpZFL-2Oz')
							}} className='flex-shrink-0 ' src={NFTTrading} alt="" />
						</div>
					</div>
				</div>
				<Swiper indicator={true} style={{}} className='rounded-xl mt-[6px] w-[100%] top-0 left-0' autoplay={2000}>
					<Swiper.Item onClick={(e: any) => { e.stopPropagation(), navigate('/ranks?type=0') }} className='w-[100%]  rounded-xl'>
						<img className='rounded-xl w-[100%]' src={day} alt="" />
					</Swiper.Item>
					<Swiper.Item onClick={(e: any) => { e.stopPropagation(), navigate('/ranks?type=2') }} className='w-[100%] rounded-xl'>
						<img className='rounded-xl w-[100%]' src={weekly} alt="" />
					</Swiper.Item>
					<Swiper.Item onClick={(e: any) => { e.stopPropagation(), navigate('/ranks?type=1') }} className='w-[100%] rounded-xl'>
						<img className='rounded-xl w-[100%]' src={quarterly} alt="" />
					</Swiper.Item>
					<Swiper.Item onClick={(e: any) => {
						e.stopPropagation(),
							WebApp.openLink(WebApp.platform == 'unknown' ? 'https://starai.pro' : 'https://t.me/TheStarAIBot/StarAI')
					}} className='w-[100%] rounded-xl'>
						<img className='rounded-xl w-[100%]' src={WebApp.platform == 'unknown' ? banna_tg : banna_h5} alt="" />
					</Swiper.Item>
				</Swiper>
				<div className=' bg-[#333333] w-[100%] mt-[10px] flex items-center justify-between rounded-[10px] h-[36px]'>
					{tabArr.map((el: any) =>
						<div onClick={() => {
							setActive(el.id)
						}} style={{ background: `${el.id == active ? 'linear-gradient( 90deg, #00E49F 0%, #54ADFF 100%)' : ''}` }} className='w-[34%] text-[#fff] text-[14px] rounded-[10px] leading-[18px] flex items-center relative justify-center h-[100%]' key={el.id}>
							{el.title}
							{Array.isArray(tabCountArray) && !!tabCountArray.find((e) => (el.id == e.type))?.Count &&
								<div style={{ backgroundImage: `url(${tip})`, backgroundSize: '100% 100%' }} className={`w-[18px] flex justify-center h-[18px] absolute text-[#ffF] p-[2px] leading-3 text-[12px] font-semibold  right-[12px] -top-[2px]`}>{Array.isArray(tabCountArray) && tabCountArray.map((e: any) => (el.id == e.type ? e.Count : '')) || ''}</div>
							}
						</div>
					)}
				</div>
				{/* advertisement */}
				<div className=' mt-[10px]'>
					{Array.isArray(data) && adContainer && data.filter(el => el.code == 'teleads').map(el =>
						<div key={el.code} className=' overflow-hidden tabbarIcon relative flex items-center justify-center rounded-[10px]'>
							<div className='mb-3 w-full ad_container rounded-[10px]' id='ad-container'></div>
							{el.state &&
								<img src={done} className='absolute z-[2000] flex-shrink-0 right-[15px] w-9 h-9' alt="" />
							}
						</div>
					)}
					<div className=' mt-[10px]'>
						{Array.isArray(data) && data.filter(el => el.code == 'openad').map(el =>
							<div key={el.code} className=' overflow-hidden tabbarIcon relative flex items-center justify-center rounded-[10px]'
								onClick={async (event) => {
									event.stopPropagation()
									closePlaySound()
									if (!el.state) {
										setTimeout(() => {
											getClaimPoints({ code: el.code, init_data: WebApp.initData || localStorage.getItem('init_data') }).then((res: any) => {
												setClaimPointsDialog(true)
												setPoint(res.data.points)
											})
										}, 6000)
									}
								}}>
								<DynamicScript>
								</DynamicScript>
								{el.state && <img src={done} className='absolute flex-shrink-0 right-[15px] w-9 h-9 z-[10]' alt="" />}
							</div>
						)}
					</div>
				</div>
				{active != 2 ?
					<div>
						{Array.isArray(data) && data.filter((el: any) => el.type == active).map((el: any, index: number) =>
							<div
								onClick={async (event) => {
									if (el.code === 'GateConnect') {
										if (!address) {
											event.stopPropagation();
											connectWallet(el);
											setPoint(el.points)
										} else {
											event.stopPropagation();
										}
										return;
									}
									if (el.code === 'ton') {
										if (!address) {
											event.stopPropagation(); closePlaySound(); connectWallet(el); setPoint(el.points)
										} else {
											event.stopPropagation();
											closePlaySound();
											setIsConnect(!isConnect)
										}
									} else {
										if (el.code.includes('star_')) {
											navigate(el.link)
										} else {
											taskLinkHandle(event, el)
										}
									}
									if (el.code === 'tg_OKXConnect') {
										okWalletInfo.current = el
										// console.log(okWalletAddress)
										if (okWalletAddress) {
											setOkWallet(!okWallet)
										} else {
											await connectHandle()
											// setTimeout(() => {
											//     dispatch(changeOKWalletAddress("0x1232103012123412r9r9r0"))
											// }, 3000)
										}
									}
								}}
								key={`Onboarding${index}`}
								className={`taskBackground tabbarIcon mt-[10px] px-[14px] flex items-center ${el.code == 'openad' || el.code == 'teleads' ? 'hidden' : ''} justify-between`}>
								<div className='flex items-center'>
									<div className='logoBox flex-shrink-0'>
										<img className='w-[30px] h-[30px] rounded-full' src={el.code.includes('Ton.Ai') ? tonAi[el.image]?.icon ? tonAi[el.image]?.icon : StarLogo[el.image] : el.image} alt="" />
									</div>
									<div className='text-[#333333] text-[16px] py-[7px] max-w-[229px] ellipsis leading-[19px] font-bold ml-[10px]'>
										{el.code.includes('Ton.Ai') ? tonAi[el.image]?.text ? tonAi[el.image]?.text : 'StarAI' : el.name}
										<div className='text-[16px] mt-[7px] font-[600] text-style-3 w-fit flex items-center leading-[18px]'>
											<img className=' w-[14px] h-[14px] mr-1' src={coin} alt="" /> +{formatNumber(el.points)}
										</div>
									</div>
								</div>
								{
									el.code === 'tg_OKXConnect' ?
										<div className='flex flex-col items-center relative'>
											{
												!okWalletAddress ?
													<div
														className=''
													// onClick={(event) => { event.stopPropagation(); closePlaySound(); connectWallet(el); setPoint(el.points) }}
													>
														{
															!el.state && <img className={`w-9 h-9 flex-shrink-0`} src={right} alt="" />
														}
														{
															el.state && <img className={`w-9 h-9 flex-shrink-0 `} src={done} alt="" />
														}

													</div> :
													<>
														<div className='w-[84px] h-[30px] rounded-lg outline-none bg-white shadow-md flex items-center justify-around'>
															<span>
																{okWalletAddress.slice(0, 2) + '...' + okWalletAddress.slice(-3)}
															</span>
															<span className={`${!okWallet ? '' : 'hidden'}`}>
																<svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
																	<g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																		<g id="08-tasks" transform="translate(-319.000000, -363.000000)" fillRule="nonzero">
																			<g id="down" transform="translate(319.000000, 363.000000)">
																				<rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="14" height="14"></rect>
																				<polygon id="路径" fill="#333333" points="7.00199609 8.47 3.35365625 4.82332812 2.64666992 5.53 7.00232422 9.88333008 11.3536719 5.53 10.6463301 4.82332813"></polygon>
																			</g>
																		</g>
																	</g>
																</svg>
															</span>
															<span className={`${okWallet ? '' : 'hidden'}`}>
																<svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
																	<g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																		<g id="08-tasks" transform="translate(-319.000000, -334.000000)" fillRule="nonzero">
																			<g id="down备份" transform="translate(326.000000, 341.000000) scale(1, -1) translate(-326.000000, -341.000000) translate(319.000000, 334.000000)">
																				<rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="14" height="14"></rect>
																				<polygon id="路径" fill="#333333" points="7.00199609 8.47 3.35365625 4.82332812 2.64666992 5.53 7.00232422 9.88333008 11.3536719 5.53 10.6463301 4.82332813"></polygon>
																			</g>
																		</g>
																	</g>
																</svg>
															</span>

														</div>
														<div className={`${!okWallet ? 'hidden' : ''} text-sm w-[84px] h-[30px] rounded-lg outline-none bg-white/50 shadow-md flex items-center justify-around absolute top-[34px]`} onClick={(event) => { event.stopPropagation(), closePlaySound(), disConnectHandle() }}>
															Disconnect
														</div>
													</>

											}

										</div> :
										el.code === 'ton' ?
											<div className='flex flex-col items-center relative'>
												{
													!address ?
														<div
															className=''
														// onClick={(event) => { event.stopPropagation(); closePlaySound(); connectWallet(el); setPoint(el.points) }}
														> <img className={`w-9 h-9 flex-shrink-0 ${el.state ? 'hidden' : ''}`} src={right} alt="" /></div> :
														<>
															<div className='w-[84px] h-[30px] rounded-lg outline-none bg-white shadow-md flex items-center justify-around'>
																<span>
																	{address.slice(0, 2) + '...' + address.slice(-3)}
																</span>
																<span className={`${!isConnect ? '' : 'hidden'}`}>
																	<svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
																		<g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																			<g id="08-tasks" transform="translate(-319.000000, -363.000000)" fillRule="nonzero">
																				<g id="down" transform="translate(319.000000, 363.000000)">
																					<rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="14" height="14"></rect>
																					<polygon id="路径" fill="#333333" points="7.00199609 8.47 3.35365625 4.82332812 2.64666992 5.53 7.00232422 9.88333008 11.3536719 5.53 10.6463301 4.82332813"></polygon>
																				</g>
																			</g>
																		</g>
																	</svg>
																</span>
																<span className={`${isConnect ? '' : 'hidden'}`}>
																	<svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
																		<g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																			<g id="08-tasks" transform="translate(-319.000000, -334.000000)" fillRule="nonzero">
																				<g id="down备份" transform="translate(326.000000, 341.000000) scale(1, -1) translate(-326.000000, -341.000000) translate(319.000000, 334.000000)">
																					<rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="14" height="14"></rect>
																					<polygon id="路径" fill="#333333" points="7.00199609 8.47 3.35365625 4.82332812 2.64666992 5.53 7.00232422 9.88333008 11.3536719 5.53 10.6463301 4.82332813"></polygon>
																				</g>
																			</g>
																		</g>
																	</svg>
																</span>

															</div>
															<div className={`${!isConnect ? 'hidden' : ''} text-sm w-[84px] h-[30px] rounded-lg outline-none bg-white/50 shadow-md flex items-center justify-around absolute top-[34px]`} onClick={(event) => { event.stopPropagation(), closePlaySound(), useDisconnectWallet() }}>
																Disconnect
															</div>
														</>
													// <TonConnectButton></TonConnectButton>
												}

												<div className='flex-shrink-0' onClick={(event) => { event.stopPropagation(); closePlaySound(); connectWallet(el); setPoint(el.points) }}> <img className={`w-9 flex-shrink-0 h-9 ${el.state && !address ? '' : 'hidden'}`} src={done} alt="" /></div>
											</div> :
											<>
												{
													el.claim_state == -1 && <div className='w-9 h-9 flex-shrink-0'> <img className={`w-9 h-9 flex-shrink-0`} src={right} alt="" /></div>

												}
												{
													(el.claim_state == 0 && el.code != claiming) &&
													<div className='flex-shrink-0'>
														<ButtonComp width={54} height={26} isOtherColor={true} title="Verify" callBack={async () => {
															if (el.code.includes('star_StartMining')) {
																setClaiming(el.code)
															} else if (getRandom(el.click)) {
																setClaiming(el.code)
															} else {

																if (el.code !== 'ton') {
																	if (el.code.includes('tg_')) {
																		WebApp.openTelegramLink(judgeClient() == 'IOS' ? el.ios_link : el.link)
																	} else {
																		if (el.code.includes("Ton.Ai")) {
																			reportExchange(el.image)
																			WebApp.openTelegramLink(tonAi[el.image].destination.url)
																		} else {
																			WebApp.openLink(judgeClient() == 'IOS' ? el.ios_link : el.link)
																		}
																	}
																}
																updateClaimState(el, 2).then(() => {
																	getTaskData() //重置任务列表
																})
															}
														}}></ButtonComp>
													</div>

												}
												{/* {
													(claiming == el.code) &&
													<div className='flex-shrink-0'>
														<ButtonComp width={54} height={26} isOtherColor={true} title="Verify" callBack={async () => {
															if (getRandom(el.click)) {
																setClaiming(el.code)
															} else {
																await updateClaimState(el, 2)
																await getTaskData()
															}
														}}></ButtonComp>
													</div>
												} */}
												{
													(!el.state && el.claim_state == 0 && claiming == el.code) &&
													<div className='flex-shrink-0 '>
														<ButtonComp width={54} height={26} isOtherColor={true} title="Claim" callBack={async () => {
															const toast = Toast.loading({
																message: "Loading...",
																forbidClick: true,
																duration: 0
															})
															const points = await updateClaimState(el, 3)
															toast.clear()
															setClaimPointsDialog(true)
															setPoint(points)

														}}></ButtonComp>
													</div>


												}
												{
													(el.state && el.claim_state == 1) && <div className='flex-shrink-0'> <img className={`w-9 flex-shrink-0 h-9`} src={done} alt="" /></div>
												}
											</>
								}
							</div>
						)}
					</div>
					:
					<div>
						{
							Array.isArray(data) && data.filter((el: { type: string }) => el.type == '2').map((el: any, index: number) =>
								el.code == 'tweet' ?
									<>
										{Object.keys(checkInData).length > 0 &&
											<div key={`Daily${index}`} className={`taskBackground tabbarIcon px-[14px] mt-[10px] flex items-center justify-between`}
												onClick={(event) => {
													event.stopPropagation();
													dispatch(changeCheckDialog(true))
												}}
											>
												<div className='flex items-center'>
													<div className='logoBox flex-shrink-0'>
														<img className='w-[30px] h-[30px]' src={task_check_in} alt="" />
													</div>
													<div className='text-[#333333] text-[16px] max-w-[229px] ellipsis leading-[19px] py-[8px] font-bold ml-[10px]'>
														Check-in
														<div className='text-[16px] mt-[4px] font-[600] flex items-center text-style-3 w-fit leading-[18px]'>
															<img className=' w-[14px] h-[14px] mr-1' src={coin} alt="" />	+{formatNumber(checkInData.is_check_in ? checkInData.check_in_rewards[checkInData.check_in_data - 1].reward : checkInData.check_in_rewards[checkInData.check_in_data].reward)}
															{checkInData.is_check_in ? checkInData.check_in_rewards[checkInData.check_in_data - 1].rewardType == "card" ? ' Card' : checkInData.check_in_rewards[checkInData.check_in_data - 1].rewardType == "lottery" ? ' Draw Card' : '' : checkInData.check_in_rewards[checkInData.check_in_data].rewardType == "card" ? ' Card' : checkInData.check_in_rewards[checkInData.check_in_data].rewardType == "lottery" ? ' Draw Card' : ''}
														</div>
													</div>
												</div>
												<div>
													<img className={`w-9 flex-shrink-0 h-9 ${checkInData.is_check_in && checkInData.is_chain_check_in ? 'hidden' : ''}`} src={right} alt="" />
													<img className={`w-9 flex-shrink-0 h-9 ${checkInData.is_check_in && checkInData.is_chain_check_in ? '' : 'hidden'}`} src={done} alt="" />
												</div>
											</div>}
									</> :
									<div key={`Daily${index}`} onClick={(event) => {
										if (el.code == "PKArenas") {
											navigate("/pk")
										} else if (el.code != "chats") {
											if (el.code.includes('star_')) {
												navigate(el.link)
											} else {
												taskLinkHandle(event, el)
											}
										}
									}} className="taskBackground mt-[10px] px-[14px] flex items-center justify-between">
										<div className='flex items-center'>
											<div className='logoBox flex-shrink-0'>
												<img className='w-[30px] h-[30px]' src={el.image} alt="" />
											</div>
											{el.code == "PKArenas" || el.code == "chats" ? <div className='text-[#333333] text-[16px] max-w-[229px] ellipsis py-[7px] leading-[19px] font-bold ml-[10px]'>
												{el.name}

												{(el.code == 'chats' || el.code == "PKArenas") && <div className=' bg-white mt-[4px] w-[110px] h-1 rounded-[2px]'>
													<div style={{ background: " linear-gradient( 135deg, #00E49F 0%, #54ADFF 100%)", width: `${Math.floor((el.collected / el.points) * 100)}%` }} className={`h-1 rounded-[2px]`}></div>
												</div>}
												<div className={`text-[12px] mt-[2px] font-[600] ${el.code == 'chats' ? 'text-[12px] mt-[4px]' : ''} text-style-3 w-fit flex items-center leading-[13px]`}>
													<img className=' w-[14px] h-[14px] mr-1' src={coin} alt="" /> {(el.code == 'chats' || el.code == 'PKArenas') ? `${el.collected ? formatNumber(el.collected) : el.collected}/${formatNumber(el.points)}` : `+${formatNumber(el.points)}`}
												</div>
											</div> :
												<div className='text-[#333333] text-[16px] max-w-[229px] ellipsis leading-[19px] py-[8px] font-bold ml-[10px]'>
													{el.name}
													<div className='text-[16px] mt-[4px] font-[600] flex items-center text-style-3 w-fit leading-[18px]'>
														<img className=' w-[14px] h-[14px] mr-1' src={coin} alt="" /> {formatNumber(el.points)}
													</div>
												</div>
											}
										</div>
										<div>
											{(el.code == 'chats' || el.code == 'PKArenas') ? ''
												:
												<>
													{
														el.claim_state == -1 && <div className='w-9 h-9 flex-shrink-0'> <img className={`w-9 h-9 flex-shrink-0`} src={right} alt="" /></div>

													}
													{
														(el.claim_state == 0 && el.code != claiming) &&
														<div className='flex-shrink-0'>
															<ButtonComp width={54} height={26} isOtherColor={true} title="Verify" callBack={async () => {
																if (el.code.includes('star_StartMining')) {
																	setClaiming(el.code)
																} else if (getRandom(el.click)) {
																	setClaiming(el.code)
																} else {
																	if (el.code !== 'ton') {
																		if (el.code.includes('tg_')) {
																			WebApp.openTelegramLink(judgeClient() == 'IOS' ? el.ios_link : el.link)
																		} else {
																			if (el.code.includes("Ton.Ai")) {
																				reportExchange(el.image)
																				WebApp.openTelegramLink(tonAi[el.image].destination.url)
																			} else {
																				WebApp.openLink(judgeClient() == 'IOS' ? el.ios_link : el.link)
																			}
																		}
																		updateClaimState(el, 2).then(() => {
																			getTaskData() //重置任务列表
																		})
																	}
																}
															}}></ButtonComp>
														</div>

													}
													{/* {
													(claiming == el.code) &&
													<div className='flex-shrink-0'>
														<ButtonComp width={54} height={26} isOtherColor={true} title="Verify" callBack={async () => {
															if (getRandom(el.click)) {
																setClaiming(el.code)
															} else {
																await updateClaimState(el, 2)
																await getTaskData()
															}
														}}></ButtonComp>
													</div>
												} */}
													{
														(!el.state && el.claim_state == 0 && claiming == el.code) &&
														<div className='flex-shrink-0 '>
															<ButtonComp width={54} height={26} isOtherColor={true} title="Claim" callBack={async () => {
																const toast = Toast.loading({
																	message: "Loading...",
																	forbidClick: true,
																	duration: 0
																})
																const points = await updateClaimState(el, 3)
																toast.clear()
																setClaimPointsDialog(true)
																setPoint(points)

															}}></ButtonComp>
														</div>


													}
												</>
											}
											<div className={`w-9 flex-shrink-0 h-9 ${el.state ? '' : 'hidden'}`}> <img className={`w-9 flex-shrink-0 h-9`} src={done} alt="" /></div>
										</div>
									</div>
							)
						}
					</div>}
			</div>
			{
				isSoundPlay && <audio ref={AudioRef} src={coinsMusic} id="audio"></audio>
			}
			{
				tonNetworkVisible && <TonNetworkError closeHandle={() => { setTonNetworkVisible(false) }}></TonNetworkError>
			}
			<PublicDialog dialog={dialog} setDialog={setDialog} closeOnClickOverlay={false} content={dialogContent()} customClassName="customClassName"></PublicDialog>
			{/* 签到链接钱包的弹窗 */}
			<PublicDialog width={"290px"} isPadding={false} dialog={checkInWallet} setDialog={setCheckInWallet} content={connect_wallet_button()} customClassName='customClassName'></PublicDialog>

			{/* 已经签到弹窗提示语 */}
			<PublicDialog width={"320px"} isPadding={false} dialog={readyCheck} setDialog={setReadyCheckIn} content={readyCheckIn()}></PublicDialog>


			{/* 该钱包已经签到弹窗提示语 */}
			<PublicDialog width={"320px"} isPadding={false} dialog={walletReadyCheck} setDialog={setWalletReadyCheck} content={walletReadyCheckIn()}></PublicDialog>

			<GetCoinAnimationDialog dialog={claimPointsDialog} point={point} getCoinAnimation={getCoinAnimation} setDialog={() => {
				dispatch(changeIsGate(""))
				setClaimPointsDialog(false)
				isClaim.current = false
				taskCode.current = ''
			}}></GetCoinAnimationDialog>
		</div >)
}


export default Task;
