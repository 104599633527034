import ReactDOM from 'react-dom/client';
import { RouterProvider, useLocation } from "react-router-dom";
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import WebApp from '@twa-dev/sdk'
import router from "@/router/index.tsx"
import store from './store/index';
import { Provider } from 'react-redux';
import telegramAPI from "@/hook/telegramApi/tgUserInfo"
import { judgeClient } from './utils/judgeClient';
import { b64Decode } from './utils';

declare const gtag: any;


if (judgeClient() == 'IOS') {
  // require('./index.css') 
  import('./index.css');
}


const { userId } = telegramAPI()

if (WebApp.platform == 'unknown') {
  localStorage.setItem('startPrice', '')
  localStorage.setItem('endPrice', '')
  localStorage.setItem('startapp', '')
  localStorage.setItem('init_data', '')
}


// Google Data Analytics
if (userId) {
  gtag('js', new Date());
  gtag('config', 'G-FL4RS3EZG1', { // use your Google Analytics Id, usually starts with G-
    userId: WebApp.initDataUnsafe.user!.id,
  });
} else {
  gtag('js', new Date());
  gtag('config', 'G-FL4RS3EZG1', { // use your Google Analytics Id, usually starts with G-
    userId: ' browser-user',
  });
}
// Google Data Analytics

function getQueryString(name: string) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  console.log("🚀 ~ getQueryString ~ window.location:", window.location)
  var r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return '';
}

if (!userId && WebApp.platform != 'unknown' && getQueryString('userId')) {
  localStorage.setItem('chatInstance', getQueryString('chatInstance') || '')
  localStorage.setItem('userName', getQueryString('userName') || '')
  localStorage.setItem('firstName', getQueryString('firstName') || '')
  localStorage.setItem('lastName', getQueryString('lastName') || '')
  localStorage.setItem('userId', getQueryString('userId') || '')
  localStorage.setItem('init_data', b64Decode(getQueryString('init_data')) || '')
}

if (import.meta.env.VITE_MODE == 'dev') {
  // window.localStorage.setItem('userId', '893817263')
  // window.localStorage.setItem('userId', '893817263')
  window.localStorage.setItem('userId', '893817263')
  // window.localStorage.setItem('userId', '893817263')
  // window.localStorage.setItem('userId', '7470222095')
  // window.localStorage.setItem('userId', '8187517572')


}
const manifestUrl = import.meta.env.VITE_MAINFEST_URL;




ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <TonConnectUIProvider manifestUrl={manifestUrl}>
    <Provider store={store}>
      <RouterProvider router={router}></RouterProvider>
    </Provider>

  </TonConnectUIProvider>
)