
import { useCallback, useEffect, useRef, useState } from 'react'
import Back from '../../components/common/BackComp.js';
import { useNavigate } from 'react-router-dom';
import { SHOP_NAV } from "../../constants/common-constants.js"
import silverImg from "../../assets/images/deck/silver.png"
import goldImg from "../../assets/images/deck/gold.png"
import diamondImg from "../../assets/images/deck/diamond.png"
import presentImg from "../../assets/images/shop/persent-icon.png"
import presentSmallImg from "../../assets/images/cards/cardTurntable-1.png"
import ButtonComp from '../../components/common/ButtonComp';
import flowerImg from "../../assets/images/shop/flower.png"
import { calculateUsdtAmountSmall } from '../../utils/common-helpers.js';
import { fromNano } from '@ton/core';
import CardPresentDialog from "../battle/myDeck/CardPresent"
import Pay from './pay.js';
import { formatNumber } from '../../utils/index.js';
import { changeUserInfo } from '../../store/module/tabbar.js';
import { useDispatch } from 'react-redux';
import { getUserInfo } from "../../api/url"

interface PropType {
    dataList: any[]
}
const Claim = ({ dataList }: PropType) => {
    const [isShowPresentDialog, setShowPresentDialog] = useState(false)
    const [presentData, setPresentData] = useState<any>({})
    const [data, setData] = useState<any>({})
    const dispatch = useDispatch()
    const payHandle = async (item: any) => {
        setData(item)

    }

    const upDataHandle = async () => {
        setShowPresentDialog(false)
        await setUserInfo()
    }

    const setUserInfo = async () => {
        const UserInfo = await getUserInfo()
        if (UserInfo.code == "0") {
            dispatch(changeUserInfo(UserInfo.data))
        }
    }
    return (

        <>
            <div style={{ backgroundSize: '100% 100%' }} className="bg-[url(@/assets/images/shop/bg-1.png)] px-4 py-4 relative bg-cover bg-no-repeat h-[220px] w-full">
                <div className='absolute left-[calc(50%-72px)] z-[9] top-5 w-36 flex justify-center'>
                    <img className='w-12 -rotate-[30deg] mt-2' src={silverImg} alt="" />
                    <img className='w-12 z-[3]' src={diamondImg} alt="" />
                    <img className='w-12 rotate-[30deg] mt-2' src={goldImg} alt="" />
                </div>
                <img className='z-[10] absolute w-[120px] left-[calc(50%-63px)] top-10 animate-rotate-turntable' src={presentImg} alt="" />
                <p className='text-white text-shadow-lg w-full h-full text-center flex items-end justify-end'>A large amount of $STAR plus either a Diamond, Gold, or Silver Card.</p>
            </div>
            <div className='grid grid-cols-1 gap-4 mt-4'>
                {
                    dataList.map((item: any, i) => {
                        return (
                            <div key={i}>
                                {
                                    item.length && <div key={i} className={`relative w-full flex flex-col justify-around px-[10px] py-2 h-[90px] rounded-[20px] special-class`}>
                                        {/* {item} */}


                                        <div className='flex items-center justify-center'>
                                            <img className='w-6' src={presentSmallImg} alt="" />
                                            <p className='text-base font-[900] text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF]'>Claim {item && item[0]?.count} Time</p>
                                        </div>
                                        <div className='grid grid-cols-3 gap-3'>

                                            <ButtonComp width={100} callBack={() => {
                                                payHandle(item[0])
                                            }}>
                                                <div className={`${item && item[0]?.discount <= 0 ? 'hidden' : ''} w-8 absolute -top-3 -left-3 flex justify-center items-center`}>
                                                    <img className='w-6' src={flowerImg} alt="" />
                                                    <span className='absolute text-[12px] scale-[0.8] text-white'>{item && item[0]?.discount}%</span>
                                                </div>
                                                {item && calculateUsdtAmountSmall(item[0]?.price / 100)} USDT
                                            </ButtonComp>
                                            <ButtonComp width={100} callBack={() => {
                                                payHandle(item[1])
                                            }}>
                                                <div className={`${item && item[0]?.discount <= 0 ? 'hidden' : ''} w-8 absolute -top-3 -left-3 flex justify-center items-center`}>
                                                    <img className='w-6' src={flowerImg} alt="" />
                                                    <span className='absolute text-[12px] scale-[0.8] text-white'>{item && item[0]?.discount}%</span>
                                                </div>
                                                {item && fromNano(item[1]?.price)} TON
                                            </ButtonComp>
                                            <ButtonComp width={100} callBack={() => {
                                                payHandle(item[2])
                                            }}>
                                                {item && item[2]?.price} STARS
                                            </ButtonComp>
                                        </div>
                                    </div >
                                }
                            </div>

                        )
                    })
                }
            </div >

            {isShowPresentDialog && <CardPresentDialog coinsCount={presentData[0].points} title={`You have received ${data.count} cards and ${formatNumber(presentData[0].points)} $STAR.`} title1={`Swipe left or right to view other cards.`} type='2' data={presentData.map((item: any) => item.card)} callBack={(url: any) => { upDataHandle() }}></CardPresentDialog>}
            <Pay dataObj={data} callBack={(item) => {
                if (item?.code == '0') {
                    setPresentData(item?.data)
                    setShowPresentDialog(true)
                }
                setData({ ...data, id: '' })
            }}></Pay>
        </>


    )
}


export default Claim;