import BackComp from "../../../components/common/BackComp"

import add from "@/assets/images/deck/add.png"
import tip from '@/assets/images/attribute/tip.svg'
import power_bg from '@/assets/images/attribute/power_bg.png'
import del from '@/assets/images/del.svg'


import { getBattleCards, getUserInfo } from "../../../api/url.js"
import { getHeroCard } from "../../../api/character.js"
import { createBattleDeck, getBattleDeck, delBattleArenas, getBattleArenasDetail } from "../../../api/pk_url.js"
import RulesDialog from "../../../components/RulesDialog.js"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import SelectCards from "./SelectCards"
import CardItem from "../../../components/CardItem"
import { attributeImage, levelImage } from "../../../utils/index.js"
import ButtonComp from "../../../components/common/ButtonComp.js"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import PromptDialog from "../../../components/PromptDialog.js"
import { cardPkRulesTextArr } from "../../../text/rulesText.js"
import { changeUserInfo } from "../../../store/module/tabbar.js"
import { useDispatch, useSelector } from "react-redux"
import { ARENA_LIST } from "../../../constants/common-constants.js"
import { changeArenaNavActive, changeNftDeck } from "../../../store/module/battle.js"
import { Toast } from "react-vant"


const CardMyDeck = () => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const [isOK, setIsOK] = useState(false)
  const [rulesDialog, setRulesDialog] = useState(false)
  const [selectCardsPopup, setSelectCardsPopup] = useState(false)
  const [cardData, setCardData] = useState<any>([])
  const [level, setLevel] = useState(0)
  const navigator = useNavigate()
  const [text, setText] = useState('Deck Configuration Failed！')
  const [attribute, setAttribute] = useState('All')
  const [isInit, setIsInit] = useState(false)
  const [dialog, setDialog] = useState(false)
  const [warningDialog, setWarningDialog] = useState(false)
  const { arenaNavActive } = useSelector((state: any) => state.battle)
  const [isNavActive, setNavActive] = useState(arenaNavActive)
  const [isShowSaveBtn, setShowSaveBtn] = useState(false)
  const [type, setType] = useState(0)
  const [active, setActive] = useState<any>({
    Hero: '',
    NFT: '',
    Diamond: '',
    Gold: '',
    Silver: '',
    Bronze: '',
  })
  const [oldActive, setOldActive] = useState<any>({
    Hero: '',
    NFT: '',
    Diamond: '',
    Gold: '',
    Silver: '',
    Bronze: '',
  })
  const [count, setCount] = useState<number>(0)
  const [loadingPage, setLoading] = useState(true)
  const [page, setPage] = useState<any>(1)
  const [isLoading, setIsLoading] = useState(false)
  const [isChange, setChange] = useState(true)
  const [currDeck, setCurrDeck] = useState<number>()
  const isChildren = useRef(false)
  const selectCardsRef = useRef<any>(null)

  const ArenaId: any = searchParams.get("id");

  const gatMyDeck = async () => {
    const toast = Toast.loading({
      message: 'Loading...',
      forbidClick: true,
      duration: 0
    });
    try {
      setIsLoading(true)
      const { data, code } = await getBattleDeck(arenaNavActive)
      setCardData([])
      if (code == '0') {
        setActive({
          Hero: data.character_card,
          NFT: data.nft_card,
          Diamond: data.diamond_card,
          Gold: data.gold_card,
          Silver: data.silver_card,
          Bronze: data.bronze_card,
        })
        setOldActive(
          ({
            Hero: data.character_card,
            NFT: data.nft_card,
            Diamond: data.diamond_card,
            Gold: data.gold_card,
            Silver: data.silver_card,
            Bronze: data.bronze_card,
          })
        )
      } else if (code == "65541") {
        setActive({
          Hero: '',
          NFT: '',
          Diamond: '',
          Gold: '',
          Silver: '',
          Bronze: '',
        })
      }

      toast.clear()
      setIsLoading(false)
    } catch (e) {
      setActive({
        Hero: '',
        NFT: '',
        Diamond: '',
        Gold: '',
        Silver: '',
        Bronze: '',
      })

      toast.clear()
      setIsLoading(false)
    }

  }
  const getData = async () => {
    if (level == 0) return
    page == 0 && setIsInit(true)
    const parent = {
      page: page,
      limit: 10,
      level: level,
      property: attribute == 'All' ? '' : attribute,
      type: 2
    }
    let parentHero: any = {
      page: page,
      limit: 10,
      property: attribute == 'All' ? '' : attribute,
    }
    if (level == 6 && type == 1) {
      parentHero.nft = true
    }
    const toast = Toast.loading({
      message: 'Loading...',
      forbidClick: true,
      duration: 0
    })
    if (level == 8 || (level == 6 && type == 1)) {
      const { data, code } = await getHeroCard(parentHero)
      if (code == '0') {
        setCount(data.count)
        setCardData([...cardData, ...data.cards])
      }
    } else {
      const { data, code } = await getBattleCards(parent)
      if (code == '0') {
        setCount(data.count)
        setCardData([...cardData, ...data.data])
      }
    }
    toast.clear()
    setIsInit(false)
    setLoading(true)

  }



  useEffect(() => {
    getData()
  }, [level, attribute, page, isChange, type])

  useEffect(() => {
    setCardData([])
    setActive({})
    gatMyDeck()
  }, [isNavActive])



  const powerAndAttribute = useMemo(() => {
    let power = 0
    let attribute: any = []
    Object.keys(active).forEach(
      (el: any) => {
        if (active[el]) {
          attribute.push(active[el].property && active[el].property)
        }
        power += (active[el]?.power ? active[el]?.power : 0)
      }
    )
    return { power: power, attribute: attribute }
  }, [active])

  useEffect(() => {
    let list = []
    for (let el in active) {
      if (Object.prototype.toString.call(active[el]) === '[object Object]') {
        list.push(active[el].id)
      }
    }
    dispatch(changeNftDeck(list))
  }, [active])


  const disabled = useMemo(() => {
    let type = false
    Object.keys(active).forEach((el) => {
      if (active[el]) {
        type = true
      }
    })
    return type ? JSON.stringify(oldActive) == JSON.stringify(active) : true
  }, [active, oldActive])

  const createDeck = async () => {
    let list = []
    for (let obj in active) {
      if (Object.prototype.toString.call(active[obj]) === '[object Object]') {
        list.push(1)
      }
    }

    if (list.length < 3) {
      const { code, data } = await getBattleArenasDetail(0, arenaNavActive)
      if (code == "0") {
        if (data && Object.prototype.toString.call(data.cards) != '[object Null]') {
          setWarningDialog(true)
          return false
        }
      }

    }
    await createDeckHandle()

  }

  const delBattleArenasHandle = async (id: number) => {
    try {
      await delBattleArenas(id)

    } catch (e) {

    }

  }
  const createDeckHandle = async () => {

    const toast = Toast.loading({
      message: 'Loading...',
      forbidClick: true,
      duration: 0
    });
    try {
      let params = {
        character_card: active.Hero ? active.Hero.id : 0,
        nft_card: active.NFT ? active.NFT.id : 0,
        diamond_card: active.Diamond ? active.Diamond.id : 0,
        gold_card: active.Gold ? active.Gold.id : 0,
        silver_card: active.Silver ? active.Silver.id : 0,
        bronze_card: active.Bronze ? active.Bronze.id : 0,
        type: arenaNavActive
      }
      const { code } = await createBattleDeck(params)
      if (code == '0') {

        setText("Deck Update Successful!")
        setDialog(true)
      } else {
        setText('Deck Configuration Failed！')
        setDialog(true)
      }
    } catch (e) {
      setText("Network Error!")
      setDialog(true)
    }

    toast.clear()
  }


  return (
    <div style={{ overflowX: "hidden" }} className="px-4 pt-[54px]">
      <div className="fixed w-[100%] top-0 left-0">
        <BackComp title="My Deck" position={true} callback={() => {
          isChildren.current = false
          if (disabled) {
            navigator(-1)
          } else {
            setShowSaveBtn(true)
          }

        }}></BackComp>
      </div>
      <img onClick={() => setRulesDialog(true)} className=" fixed top-[13px] right-4" src={tip} alt="" />

      <div className='text-white grid grid-cols-4 gap-4 mb-4'>
        {
          ARENA_LIST().map(item => {
            return (
              <div onClick={() => {
                setType(0)
                setCardData([])
                dispatch(changeArenaNavActive(item.id))
                setNavActive(item.id)
              }} key={item.id} className={` text-white h-[32px] flex justify-center items-center w-[60px] py-1  rounded-3xl relative`}>
                <p className={`${item.id == isNavActive ? 'text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF]' : ''}`}>{item.name}</p>
              </div>
            )
          })
        }
      </div>
      <p className="text-[16px] text-[#fff] leading-[19px] ">Total Power : <span className=" !text-[16px] text-style-1 !leading-[19px] ">{powerAndAttribute.power}</span> </p>
      <div className="text-[16px] text-[#fff] leading-[19px] flex mt-[10px]">Total Attributes :
        <p className="ml-1 flex gap-1">
          {powerAndAttribute.attribute.length > 0 ? <>
            {powerAndAttribute.attribute.map((el: string, index: number) =>
              <img key={`${index}-${el}`} className=" w-5" src={attributeImage(el)} alt="" />
            )}</> : '-'}
        </p>
      </div>
      <div className=" w-full grid mt-[30px] gap-x-10 gap-y-5 grid-cols-3 grid-rows-2">
        {Object.keys(active).map((el, index: number) =>
          <div
            onClick={() => {

              if (level != (el == 'Hero' ? 8 : 7 - index)) {
                setCardData([])
              }
              setPage(1)
              if (isNavActive == 0) {
                setLevel(el == 'Hero' ? 8 : 7 - index)
              } else {
                setCardData([])
                setCurrDeck(7 - index)
                setChange(!isChange)
                setLevel(el == 'Hero' ? 8 : ARENA_LIST()[isNavActive]?.level as number)
              }
              setAttribute('All')
              setType(0)
              setSelectCardsPopup(true)
            }} key={el}>
            {el != 'Hero' ?
              <div>{!active[el] ?
                isNavActive == 0 ? <div className=" relative flex flex-col items-center justify-center">
                  <img className=" w-[100%]" src={levelImage(el)} alt="" />
                  <img className=" w-6 absolute  mb-[28px] " src={add} alt="" />
                  <p className="text-[14px] text-[#fff] leading-[18px] mt-[10px]"> {el} </p>
                </div> :
                  <div className="relative flex flex-col items-center justify-center">
                    <img className="w-[100%]" src={levelImage(ARENA_LIST()[isNavActive]?.type)} alt="" />
                    <img className=" w-6 absolute  mb-[28px] " src={add} alt="" />
                    <p className="text-[14px] text-[#fff] leading-[18px] mt-[10px]"> {ARENA_LIST()[isNavActive]?.type} </p>
                  </div>
                :
                <div className=" flex flex-col items-center justify-center">
                  <div className="relative">
                    <CardItem power={active[el]?.power}
                      bonusPower={active[el]?.bonus_power}
                      property={active[el]?.property}
                      enhanceLevel={active[el]?.enhance_level}
                      image={active[el]?.url}
                      big={false}
                      height={0}
                      isHero={active[el].character_id ? true : false}
                      is_enhance={true}
                      level={active[el]?.level + 1}
                    >
                    </CardItem>
                    <img className="w-5 absolute bottom-0 z-10 left-0  h-5"
                      src={del}
                      onClick={(e) => {
                        e.stopPropagation()
                        active[el] = ''
                        setActive({ ...active })
                      }}
                      alt="" />
                  </div>
                  <p className="text-[14px] text-[#fff] leading-[18px] mt-[10px]"> {
                    arenaNavActive == 0 ? el : ARENA_LIST()[isNavActive]?.type
                  } </p>
                </div>
              }
              </div>
              : <div>
                {!active.Hero ?
                  <div className="relative flex flex-col items-center justify-center">
                    <img className=" w-[100%]" src={levelImage(el)} alt="" />
                    <img className=" w-6 absolute  mb-[28px] " src={add} alt="" />
                    <p className="text-[14px] text-[#fff] leading-[18px] mt-[10px]"> Hero </p>
                  </div> :

                  <div className="relative flex flex-col items-center justify-center">
                    <div className=" relative ">
                      <img className=" w-[100%] rounded-[10px]" src={active[el].url} alt="" />
                      <img onClick={(e) => {
                        e.stopPropagation()
                        active[el] = ''
                        setActive({ ...active })
                      }} className="w-5 absolute bottom-0 z-10 left-0  h-5" src={del} alt="" />
                    </div>
                    <div className={` ${'h-6'} absolute px-[5px] flex items-center justify-center z-10 top-0 right-0`}>
                      <div className=' absolute top-0 left-0 w-full h-full opacity-60' style={{ backgroundImage: `url(${power_bg})`, backgroundSize: '100% 100%' }}></div>
                      <p className={`${active[el]?.bonus_power ? 'text-style-4 pr-[2px]' : 'text-style-7'} !text-[12px] !font-bold`}>{active[el]?.bonus_power ? active[el]?.bonus_power + active[el]?.power : active[el]?.power}</p>
                    </div>
                    <img className='absolute top-0 left-0  w-[23px] h-[23px]' src={attributeImage(active[el].property)} alt="" />
                    <p className="text-[14px] text-[#fff] leading-[18px] mt-[10px]"> Hero </p>
                  </div>
                }
              </div>
            }
          </div>
        )}
      </div>

      <div className=" w-full flex justify-center items-center">
        {
          !disabled && !isLoading &&
          <ButtonComp width={174} mt={50} title={'OK'} callBack={() => { createDeck() }}>
          </ButtonComp>
        }

      </div>
      <RulesDialog textArr={cardPkRulesTextArr} title="Card Configuration Rules" dialog={rulesDialog} setDialog={setRulesDialog} teleport={undefined}></RulesDialog>
      <PromptDialog title={'Attention'} dialog={dialog} setDialog={setDialog} callback={() => {
        if (isChildren.current) {
          selectCardsRef.current.callBackHandle()
        } else {
          navigator("/pk")
        }
      }} text={text}>

      </PromptDialog>
      <PromptDialog title={'Attention'} dialog={isShowSaveBtn} setDialog={setShowSaveBtn} text={'Do you want to save the deck configuration before leaving the interface?'}>

        <div className="btn flex w-full">
          <ButtonComp width={110} title={'Don\'t save'} callBack={() => {
            if (isChildren.current) {
              selectCardsRef.current.callBackHandle()
            } else {
              navigator("/pk")
            }
            isChildren.current = false
          }}>
          </ButtonComp>
          <ButtonComp width={110} title={'Save'} callBack={() => { setShowSaveBtn(false), createDeck() }}>
          </ButtonComp>
        </div>

      </PromptDialog>
      <PromptDialog title={'Attention'} dialog={warningDialog} setDialog={setWarningDialog} isClose={true} callback={async () => {
        if (ArenaId !== '-1') {
          await delBattleArenasHandle(ArenaId)
        }; await createDeckHandle()
      }} text={"You have configured fewer than 3 cards.continuing will cancel the arena you created."}></PromptDialog>
      {selectCardsPopup &&
        <SelectCards
          ref={selectCardsRef}
          isInit={isInit}
          setCardData={setCardData}
          count={count}
          loadingPage={loadingPage}
          setLoading={setLoading}
          page={page}
          setPage={setPage}
          active={active}
          setActive={setActive}
          level={level}
          type={type}
          setType={setType}
          setLevel={setLevel}
          setAttribute={setAttribute}
          attribute={attribute}
          cardData={cardData}
          selectCardsPopup={selectCardsPopup}
          currDeck={currDeck}
          isSaveDeck={disabled}
          callBack={() => {
            // setIsOK(true)
            console.log('true')
            isChildren.current = true,
              setShowSaveBtn(true)
          }}
          setSelectCardsPopup={setSelectCardsPopup}>
        </SelectCards>
      }

    </div>
  )
}
export default CardMyDeck