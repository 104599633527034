
import { useSelector, useDispatch } from 'react-redux';
import { Popup, Toast } from "react-vant"
import Back from "../../components/common/BackComp";
import { useNavigate } from "react-router-dom";
import bg from "../../assets/images/talk/bg.png"
import ButtonComp from '../../components/common/ButtonComp';
import Chars from "@/components/Chat.tsx"
import { useEffect, useLayoutEffect, useRef, useState, useCallback } from "react";
import PromptDialog from "../../components/PromptDialog";
import { logsShareApi, loadLoadApi, getUserInfo } from '@/api/url'
import { changeCharacterList, changeUserInfo } from '../../store/module/tabbar';
import TalkMessage from '../../components/message/TalkMessageToast';
interface PropType {
    callback: () => void,
    charList: any,
    type?: string | "",
    id?: string
    sessionID?: string
}

const ShareLog = ({ callback, charList, type, id, sessionID }: PropType) => {
    const navigate = useNavigate()
    const [isMessage, setShowMessage] = useState(false)
    const chatContainer = useRef<any>(null)
    const chatMessageList = useSelector((state: any) => state.progress.chatList)
    const shareLogList = useSelector((state: any) => state.createAvatar.shareLogList)
    const characterInfo = useSelector((state: any) => state.progress.currCharacterInfo) || window.localStorage.getItem("currCharacterInfo") && JSON.parse(window.localStorage.getItem("currCharacterInfo")!);
    const [loadDialog, setLoadDialog] = useState(false)
    const [shareDialog, setShareDialog] = useState(false)
    const scrollToBottom = () => {
        chatContainer.current!.scrollIntoView({ behavior: "smooth" });
    };
    const [shareLogsList, setShareLogsList] = useState([])
    const [errText, setText] = useState("")
    const [errValue, setErrValue] = useState(false)
    const dispatch = useDispatch()

    charList = charList.filter((item: any) => item.isHide != 1)
    useEffect(() => {
        charList = charList.map((item: any) => {
            if (shareLogList.includes(item.id)) {
                return {
                    ...item,
                    isSelected: true
                }
            } else {
                return item
            }
        })
        setShareLogsList(charList)
    }, [shareLogList])

    const shareToLoadHandle = () => {

        setShareDialog(true)
    }
    const shareLogsOK = async () => {
        const list = shareLogsList.filter((item: any) => shareLogList.includes(item.id)).map((item: any) => item.text)
        const toast = Toast.loading({
            message: "Share...",
            forbidClick: true
        })
        try {
            const params = {
                character_id: id,
                msgs: list
            }
            const { code, data } = await logsShareApi(params)
            if (code == 0) {
                setTimeout(() => {
                    toast.clear()
                    sessionStorage.setItem('avatarListId', "4")
                    navigate(`/agents?id=${4}`)

                })

            } else if (code == -1) {
                toast.clear()
                setErrValue(true)
                setText("Network Error.Please try again later.")
            } else {
                toast.clear()
                setErrValue(true)
                setText(data)
            }
        } catch (err) {
            toast.clear()
            setErrValue(true)
            setText("Network Error.Please try again later.")

        }

    }

    //load logs
    const loadLogsHandle = async () => {
        const toast = Toast.loading({
            message: "Loading...",
            forbidClick: true
        })
        try {
            const { code, data } = await loadLoadApi(sessionID)
            if (code == 0) {
                setTimeout(() => {
                    toast.clear()
                    navigate(`/talk?id=${id}`);
                }, 1000)

            } else if (code == 65547) {
                toast.clear()
                setErrValue(true)
                setText("Insufficient $STAR.")
            } else {
                toast.clear()
                setErrValue(true)
                setText(data)
            }
            await setUserInfo()
        } catch (e) {
            toast.clear()
            setErrValue(true)
            setText("Network Error.Please try again later.")
        }

    }

    const setUserInfo = useCallback(async () => {
        const UserInfo = await getUserInfo()
        if (UserInfo.code == "0") {
            dispatch(changeUserInfo(UserInfo.data))

        }
    }, [])


    useEffect(() => {
        setTimeout(() => {
            scrollToBottom()
        }, 200)

    }, [])

    return (
        <Popup className="bg-[#000]" visible={true} position="right" style={{ width: '100%', height: "100%" }}>
            {/* <div className={`w-full h-[100vh] px-4 box-border relative bg-no-repeat bg-cover bg-center`} style={{ backgroundImage: `url(${bg})` }}> */}
            <div className={`talk-box w-full h-[100vh] px-4 box-border relative bg-no-repeat bg-cover bg-center`} style={{ backgroundImage: `url(${characterInfo.bg_image})` }}>
                {/* 头部人物信息和点赞 */}
                <div className='w-full h-[80px] px-4 py-3 z-[200] absolute top-0 left-0 bg-gradient-to-b from-[#000000] to-[#fff]/[0] overflow-hidden'>
                    <div className="avatar h-11 mb-2">
                        <Back callback={() => { callback && callback() }}>
                            <div className='flex  items-center justify-between w-full'>
                                <div className='bg-[#000]/[.4] rounded-full flex items-center h-full px-4'>
                                    <div className="img h-9 w-9 rounded-full">
                                        <img className='w-full h-full rounded-full' src={characterInfo.image} alt="" />
                                    </div>
                                    <div className="text text-base text-white ml-3 px-2">
                                        {characterInfo.name}
                                        <p className={`${characterInfo.account_name ? '' : 'hidden'} text-xs text-[#a6a6a6]`}>By@ {characterInfo.account_name}</p>
                                    </div>
                                </div>
                            </div>
                        </Back>

                    </div>

                </div>
                <div className="h-[calc(100vh)] w-full overflow-auto">
                    <div className="chats-content h-[100vh] box-border overflow-auto flex pb-[120px]">
                        {
                            shareLogsList.map((item: any, index: number) => {
                                return <Chars isSelect={type == 'list' ? false : true} isShowVoice={false} info={item} content={item.content} key={index} logsList={shareLogsList}></Chars>
                            })
                        }
                        <div className="blank h-[10px] w-full" ref={chatContainer}></div>
                    </div>
                </div>
            </div>
            {
                type == 'list' ?
                    <div className="flex items-center h-[160px] w-full absolute bottom-0" style={{ backgroundImage: `linear-gradient( to bottom, rgba(0,0,0,0) 0%, #000 100%)`, }}>
                        <ButtonComp width={130} title="Load" callBack={() => {
                            setLoadDialog(true)
                        }}></ButtonComp>

                    </div> :
                    <div className="flex items-center h-[160px] w-full absolute bottom-0" style={{ backgroundImage: `linear-gradient( to bottom, rgba(0,0,0,0) 0%, #000 100%)`, }}>
                        <ButtonComp width={130} isGradient='#a6a6a6' title="Cancel" callBack={() => {
                            setShareDialog(false)
                            callback && callback()
                        }}></ButtonComp>
                        <ButtonComp width={130} title="Share to Logs" callBack={() => {
                            if (shareLogList.length >= 6) {
                                shareToLoadHandle()
                            } else {
                                setShowMessage(true)
                                setTimeout(() => {
                                    setShowMessage(false)
                                }, 1200)
                            }


                        }}></ButtonComp>
                    </div>
            }

            {
                type == "list" ? <PromptDialog
                    lineBreak={true}
                    title='Load this Log'
                    dialog={loadDialog}
                    is_Stars={'coin'}
                    price='100,000'
                    setDialog={setLoadDialog}
                    text_mt={30}
                    text={'You can import the Chat Log and continue the conversation. It will cost 100,000 $STAR. This will clear your existing chat history'}
                    text_1={''}
                    callback={loadLogsHandle}
                ></PromptDialog>
                    :
                    <PromptDialog
                        isClose={true}
                        lineBreak={true}
                        title='Share to Logs'
                        dialog={shareDialog}
                        price='100,000'
                        setDialog={setShareDialog}
                        text={'Your Chat Log will be shared on the Logs page. Earn 2,000 $STAR each time another user loads it.'}
                        callback={shareLogsOK}
                    ></PromptDialog>
            }

            <PromptDialog title="Attention" dialog={errValue} setDialog={setErrValue} callback={() => { setErrValue(false) }} text={errText}></PromptDialog>
            {isMessage && <TalkMessage bottom={120} text={"You must select at least six chat messages to share."}></TalkMessage>}

        </Popup>
    )
}


export default ShareLog