import { Dialog, Toast } from "react-vant"
import WebApp from '@twa-dev/sdk'
import { useEffect, useRef, useState } from "react"
import usePlaySound from "@/hook/usePlaySound.tsx"
import nsfw from "@/assets/images/nsfw.png"
import tonicon from "../assets/images/settings/tonicon.png"
import usdticon from "../assets/images/settings/usdticon.png"
import icon from "@/assets/component/starspay/icon.png"
import music from "@/assets/images/settings/music.svg"
import sound from "@/assets/images/settings/sound.svg"
import vibration from "@/assets/images/settings/vibration.svg"
import introductortImg from "../assets/images/icon-intorductory.png"
import finger from "@/assets/images/myInvites/finger.png"

import { Switch } from 'react-vant';
import { useDispatch, useSelector } from "react-redux";
import { changeSettings, changeShowIntroductory } from "../store/module/settins";
import { postSetting, getUserGlobalSettingLink, postUserGlobalSettingLink, getSetting } from "@/api/url.js";
import PromptDialog from "./PromptDialog"
import wallet from "../hook/wallet/contentWallet"
import ButtonComp from "./common/ButtonComp"
import { PAYTYPE, PAYHANDLETYPE } from "../utils/common-helpers"
import ConnectWallet from "./common/ConnectWallet"
import { Address, beginCell } from "@ton/core"
import TonTransfer from "./connectOkWallet/transform"
import UsdtTransfer from "./connectOkWallet/USDT_transform"
import { changeSettingsTransactionBoc, changeTransactionBoc, changeUsdtTransferId, changeUsdtTransferType } from "../store/module/lottery"
import CloseComp from "./common/CloseComp"
import { postWarningInformation } from "@/api/url.js"


interface DialogProps {
  dialog: boolean
  setDialog: (val: boolean) => void
}
const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

const SettingsDialog = ({ dialog, setDialog }: DialogProps) => {
  const { useConnectWallet, useGetWalletAddress, useDisconnectWallet } = wallet();
  const address = useGetWalletAddress()
  const dialogRef = useRef<any>(null)
  const [is_NSFW, setIs_NSFW] = useState(false)
  const [pay, setPay] = useState<any>({})
  const [payDialog, setPayDialog] = useState<any>(false)
  const [promptText, setPromptText] = useState<any>('')
  const dispatch = useDispatch()

  const settings = useSelector((state: any) => state.settings.settings)
  const { UsdtTransferOrderId, transactionBoc, usdtTransferType } = useSelector((state: any) => state.lottery)
  var mo = function (e: any) { e.preventDefault(); };
  const [promptDialog, setPromptDialog] = useState(false)
  useEffect(() => {

  }, [dialogRef.current])

  const onChange = function (val: any) {
    let obj = { ...settings }
    obj[val] = !obj[val]
    const toast = Toast.loading({
      message: 'Loading...',
      forbidClick: true,
    });
    try {
      postSetting(localStorage.getItem('userId'), obj).then((result: any) => {
        if (result.code == 0) {
          dispatch(changeSettings(obj))
        } else {
          setPayDialog(true)
          setPromptText(result.msg)
        }
      }).catch(() => {
      })
    } catch {
      toast.clear()
    }
    toast.clear()
  }


  //支付相关 ton usdt star

  const [networkError, setNetworkError] = useState(false)
  const errorText = useRef("")
  const [isShowConnectWallet, setIsShowConnectWallet] = useState(false)

  const { transferHandle } = TonTransfer()
  const { UsdtTransformHandle } = UsdtTransfer()

  useEffect(() => {
    if (address) {
      setIsShowConnectWallet(false)
    }
  }, [address])

  const getUserGlobalSettingLinkHandle = async (type: any, address: string | Address) => {
    setIs_NSFW(false)
    if (type != 'star') {
      if (!address) {
        setIsShowConnectWallet(true)
        return false
      }
    }

    const params = {
      type: PAYTYPE[type],
      relevance: address
    }
    const toast = Toast.loading({ message: "Loading...", duration: 0, forbidClick: true });
    const res = await getUserGlobalSettingLink(localStorage.getItem('userId'), params)
    toast.clear()
    if (res.code == 0 && !res.data?.is_paid) {
      switch (type) {
        case 'usdt':
          dispatch(changeUsdtTransferType("settings"))
          dispatch(changeUsdtTransferId(res.data?.order_id))
          dispatch(changeTransactionBoc(""))
          UsdtTransformHandle(0.36, "Global Setting Nsfw", "setting")
          break;
        case 'ton':
          const body = beginCell()
            .storeUint(0, 32) // 写入32个零位以表示后面将跟随文本评论
            .storeStringTail(JSON.stringify({ type: "Global Setting Nsfw", orderID: res.data?.order_id })) // 写下我们的文本评论
            .endCell();
          transferHandle(res.data?.order_id, "0.072", body, "", PAYHANDLETYPE.SETTING, callback)
          break;
        case 'star':
          lotteryHandle(res.data?.link)
          break;
      }

    }
  }


  const orderId = useRef("")
  useEffect(() => {
    orderId.current = UsdtTransferOrderId
    if (transactionBoc && usdtTransferType === 'settings') {

      callback({ order_id: orderId.current, transaction_id: transactionBoc })
    }
  }, [UsdtTransferOrderId, transactionBoc])
  const callback = async (data: any) => {
    try {
      const toast = Toast.loading({ message: "The transaction is being confirmed.Please wait for 1 minute.", duration: 0, forbidClick: true, className: 'custom-toast' });

      const res = await postUserGlobalSettingLink(localStorage.getItem('userId'), data)
      toast.clear()
      dispatch(changeTransactionBoc(""))
      if (res.code == '0') {
        callBackHandle()
        // setPromptDialog(true)

      } else if (res.code == "65551") {
        errorText.current = "Transaction failed, please try again."
        setNetworkError(true)
      } else if (res?.code == "65539") {
        errorText.current = "Blockchain network congested.Please wait for 5 minutes."
        setNetworkError(true)
      }
    } catch (err) {
      errorText.current = "Network Error."
      setNetworkError(true)
    }

  }

  const callBackHandle = async () => {
    dispatch(changeSettings({ ...settings, star_nsfw: true }))
    let ID = window.Telegram.WebApp.initDataUnsafe?.user?.id || WebApp.initDataUnsafe?.user?.id || window.localStorage.getItem("userId") || "";
    if (!ID) {
      postWarningInformation({
        platform: JSON.stringify({
          telegram: window.Telegram.WebApp.platform,
          webApp: WebApp.platform,
        }),
        version: JSON.stringify({
          telegram: window.Telegram.WebApp.version,
          webApp: WebApp.version,
        })
        ,
        initData: JSON.stringify({
          telegram: window.Telegram.WebApp.initData,
          webApp: WebApp.initData,
        }),
        telegram: JSON.stringify(window.Telegram.WebApp.initDataUnsafe),
        webApp: JSON.stringify(WebApp.initDataUnsafe),
        localStorage: window.localStorage.getItem("userId"),
        interfaceName: 'getSetting',
      })
      ID = window.Telegram.WebApp.initDataUnsafe?.user?.id || WebApp.initDataUnsafe?.user?.id || window.localStorage.getItem("userId") || "";
    }
    const setting = await getSetting(ID)
    if (setting.code == 0) {
      dispatch(changeSettings(setting.data))
    }
    setPromptDialog(false)
  }



  const lotteryHandle = async (link: string) => {
    WebApp.openInvoice(link, async (status) => {
      if (status === "paid") {
        dispatch(changeSettings({ ...settings, star_nsfw: true }))
      }
    })
  }

  //钱包相关
  //链接钱包
  const connectWallet = async () => {
    if (address) {
      return false
    }
    useConnectWallet();
  }

  return (
    <Dialog
      className={'settingsDialog'}
      visible={dialog}
      showConfirmButton={false}
      showCancelButton={false}
      onClose={() => { setDialog(false) }}
    >
      <div ref={dialogRef} style={{ backgroundSize: "100% 100% !important" }} className={`w-full !bg-[length:100%_100%] px-[24px] pb-[54px] pt-[42px] overflow-visible relative bg-[url(@/assets/images/dialog_bg.png)]`}>
        <CloseComp position="absolute" top={14} right={16} callBack={() => { setDialog(false) }}></CloseComp>
        <p className="text-[20px] leading-[24px] font-bold text-[#333333] mb-[30px] text-center">Settings</p>
        <div className="w-full">
          <div style={{ background: "linear-gradient(135deg, rgb(87, 255, 61, 0.2) 0%, rgb(84, 173, 255, 0.2) 100%)" }} className="p-[10px] rounded-[6px]">
            <div className="flex items-center justify-between" onClick={() => { dispatch(changeSettings({ ...settings, connect_wallet: !settings.connect_wallet })) }}>
              <div className="flex text-[14px] text-[#333333] gap-[6px] font-semibold leading-[18px] items-center">
                <img className="w-[14px] h-[14px]" src={tonicon} alt="" />
                Connect Wallet
              </div>
              <div className='flex flex-col items-center relative'>
                {
                  !address ?
                    <Switch
                      onChange={(val) => {
                        connectWallet()
                      }}
                      size="22px" activeColor=" #00E49F" inactiveColor="rgb(255, 255, 255,0.5)" checked={settings.connect_wallet} /> :
                    <>
                      <div className='w-[84px] h-[30px] rounded-lg outline-none bg-white shadow-md flex items-center justify-around'>
                        <span>
                          {address.slice(0, 2) + '...' + address.slice(-3)}
                        </span>
                        <span className={`${!settings.connect_wallet ? '' : 'hidden'}`}>
                          <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <g id="08-tasks" transform="translate(-319.000000, -363.000000)" fillRule="nonzero">
                                <g id="down" transform="translate(319.000000, 363.000000)">
                                  <rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="14" height="14"></rect>
                                  <polygon id="路径" fill="#333333" points="7.00199609 8.47 3.35365625 4.82332812 2.64666992 5.53 7.00232422 9.88333008 11.3536719 5.53 10.6463301 4.82332813"></polygon>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </span>
                        <span className={`${settings.connect_wallet ? '' : 'hidden'}`}>
                          <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <g id="08-tasks" transform="translate(-319.000000, -334.000000)" fillRule="nonzero">
                                <g id="down备份" transform="translate(326.000000, 341.000000) scale(1, -1) translate(-326.000000, -341.000000) translate(319.000000, 334.000000)">
                                  <rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="14" height="14"></rect>
                                  <polygon id="路径" fill="#333333" points="7.00199609 8.47 3.35365625 4.82332812 2.64666992 5.53 7.00232422 9.88333008 11.3536719 5.53 10.6463301 4.82332813"></polygon>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </span>

                      </div>
                      <div className={`${!settings.connect_wallet ? 'hidden' : ''} text-sm w-[84px] h-[30px] rounded-lg outline-none bg-white/50 shadow-md flex items-center justify-around absolute top-[34px]`} onClick={(event) => { event.stopPropagation(), useDisconnectWallet() }}>
                        Disconnect
                      </div>
                    </>
                  // <TonConnectButton></TonConnectButton>
                }

                {/* <div className='flex-shrink-0' onClick={(event) => { event.stopPropagation(); closePlaySound(); connectWallet(el); setPoint(el.points) }}> <img className={`w-9 flex-shrink-0 h-9 ${el.state && !address ? '' : 'hidden'}`} src={done} alt="" /></div> */}
              </div>

            </div>
          </div>
          <div style={{ background: "linear-gradient(135deg, rgb(87, 255, 61, 0.2) 0%, rgb(84, 173, 255, 0.2) 100%)" }} className="p-[10px] rounded-[6px] my-[10px]">
            <div className="flex items-center justify-between">
              <div className="flex text-[14px] text-[#333333] gap-[6px] font-semibold leading-[18px] items-center">
                <img className="w-[14px] h-[14px]" src={nsfw} alt="" />
                NSFW
              </div>
              <Switch
                onChange={(val) => {
                  if (settings.nsfw_pay_type != 2) {
                    setIs_NSFW(true)
                    dispatch(changeSettings({ ...settings, star_nsfw: false }))


                  } else {
                    dispatch(changeSettings({ ...settings, star_nsfw: val }))
                    const toast = Toast.loading({
                      message: 'Loading...',
                      forbidClick: true,
                    });
                    try {
                      postSetting(localStorage.getItem('userId'), { ...settings, star_nsfw: val }).then((result: any) => {
                        if (result.code == 0) {
                          toast.clear()

                        } else {
                          if (result.code != 0) {
                            setPayDialog(true)
                            setPromptText(result.msg)
                          }
                          dispatch(changeSettings({ ...settings, star_nsfw: val }))
                        }
                      })
                    } catch {
                      toast.clear()
                    }
                  }
                }}
                size="22px" activeColor=" #00E49F" inactiveColor="rgb(255, 255, 255,0.5)" checked={settings.star_nsfw} />
            </div>
          </div>
          <div style={{ background: "linear-gradient(135deg, rgb(87, 255, 61, 0.2) 0%, rgb(84, 173, 255, 0.2) 100%)" }} className="p-[10px] rounded-[6px]">
            <div className="flex items-center justify-between">
              <div className=" flex text-[14px] text-[#333333] gap-[6px] font-semibold leading-[18px] items-center">
                <img className=" w-[14xp] h-[14px]" src={music} alt="" />
                Music </div>
              <Switch size="22px" activeColor=" #00E49F" onChange={() => {
                onChange('music')
              }} inactiveColor="rgb(255, 255, 255,0.5)" checked={settings.music} />
            </div>
            <div className="flex items-center my-[15px] justify-between">
              <div className="flex text-[14px] text-[#333333] gap-[6px] font-semibold leading-[18px] items-center">
                <img className=" w-[14xp] h-[14px]" src={sound} alt="" />
                Sound
              </div>
              <Switch size="22px" activeColor=" #00E49F" onChange={() => {
                onChange('sound')
              }} inactiveColor="rgb(255, 255, 255,0.5)" checked={settings.sound} />
            </div>

            <div className="flex items-center justify-between">
              <div className="flex text-[14px] text-[#333333] gap-[6px] font-semibold leading-[18px] items-center">
                <img className=" w-[14xp] h-[14px]" src={vibration} alt="" />
                Vibration
              </div>
              <Switch size="22px" activeColor=" #00E49F"
                onChange={() => {
                  onChange('vibration')
                }} inactiveColor="rgb(255, 255, 255,0.5)" checked={settings.vibration} />
            </div>
          </div>
          <div onClick={() => {
            setDialog(false)
            dispatch(changeShowIntroductory(true))
          }} style={{ background: "linear-gradient(135deg, rgb(87, 255, 61, 0.2) 0%, rgb(84, 173, 255, 0.2) 100%)" }} className="p-[10px] rounded-[6px] my-[10px]">
            <div className="flex items-center justify-between relative">
              <div className="flex text-[14px] text-[#333333] gap-[6px] font-semibold leading-[18px] items-center ">
                <img className="w-[14px] h-[14px]" src={introductortImg} alt="" />
                User Guide
                <img className='absolute zoom w-7 h-7 z-10 -right-0 bottom-[-11px]' src={finger} alt="" />
              </div>

            </div>
          </div>
        </div>
      </div>
      <PromptDialog
        isClose={true}
        title='Warning'
        dialog={is_NSFW}
        is_Stars={'Stars'}
        price="9 Stars"
        setDialog={setIs_NSFW}
        text={'You must be at least 18 years old to enable NSFW content. By turning on this setting, you will unlock all NSFW content for a period of six months.You can make payments using the following payment methods.'}
        text_1={'Do you want to proceed?'}
      >
        <ButtonComp title='0.36 USDT' callBack={() => {
          getUserGlobalSettingLinkHandle("usdt", address ? Address.parse(address) : '')
        }}>
          <img className="h-6 mr-10 absolute left-1" src={usdticon} alt="" />
        </ButtonComp>
        <ButtonComp mt={20} title='0.072 TON' callBack={() => {

          getUserGlobalSettingLinkHandle("ton", address ? Address.parse(address) : '')
        }}>
          <img className="h-6 mr-10 absolute left-1" src={tonicon} alt="" />
        </ButtonComp>

        <ButtonComp title='18 STARS' mt={20} callBack={() => { getUserGlobalSettingLinkHandle("star", "") }}>
          <img className="h-6 mr-10 absolute left-1" src={icon} alt="" />
        </ButtonComp>

      </PromptDialog>
      {/* <NsfwPay></NsfwPay> */}
      <PromptDialog title={'Attention'} dialog={payDialog} setDialog={setPayDialog} callback={() => { setDialog(false) }} text={promptText}></PromptDialog>
      {
        isShowConnectWallet && <ConnectWallet callback={() => {
          setIsShowConnectWallet(false)
        }}></ConnectWallet>
      }
      {
        <PromptDialog isClose={true} title={'Attention'} dialog={networkError} setDialog={setNetworkError} text={errorText.current}></PromptDialog>
      }
      {<PromptDialog isClose callback={callBackHandle} teleport={document.querySelector('body')} title={'Congratulations! 🎉'} dialog={promptDialog} setDialog={callBackHandle} text={'You have successfully enabled NSFW for six months.'}>

        <ButtonComp mt={30} title="OK" callBack={callBackHandle}></ButtonComp>
      </PromptDialog>}
    </Dialog>
  )
}
export default SettingsDialog
