interface buttonProps {
    title?: any,
    width?: number | string,
    height?: number,
    children?: any,
    isLoading?: boolean,
    callBack?: () => void,
    isShadow?: boolean,
    fontSize?: number,
    disabled?: boolean,
    isPlayAudio?: boolean,
    mt?: number,
    isGradient?: string,
    isOtherColor?: boolean,
    roundedFull?: boolean
    rounded?: number
}
import loadingImg from '@/assets/images/task/loading.svg'
import { useRef } from "react";
import backMusic from "../../assets/mp3/close.mp3"
import { useSelector } from 'react-redux';
import WebApp from '@twa-dev/sdk';


const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));


const ButtonComp = ({ roundedFull = false, rounded = 10, isOtherColor = false, title, children, width = 174, height = 36, isLoading, callBack, isShadow = true, fontSize = 16, disabled, isPlayAudio = true, mt, isGradient }: buttonProps) => {
    const isSoundPlay = useSelector((state: any) => state.settings.settings.sound)
    const { settings } = useSelector((state: any) => state.settings)
    const AudioRef = useRef<any>()
    const buttonHandle = async () => {

        if (disabled) {
            return false
        }
        if (AudioRef.current && isPlayAudio) {
            // AudioRef.current.currentTime = 0;
            AudioRef.current.play()
        }
        if (settings.vibration) {
            WebApp.HapticFeedback.impactOccurred('heavy'); // 震动1000毫秒
        }
        await sleep(200)
        callBack && callBack()
    }

    return (
        <>
            <div className={`flex items-center rounded-[10px] mx-auto justify-center`}
                style={{
                    width: width,
                    height: height,
                    marginTop: mt + 'px',

                }}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault()
                    buttonHandle()
                }}>
                <div
                    style={{
                        zIndex: 4,
                        // boxShadow: 'inset 0 0 4px rgba(0, 0, 0, 0.5)',
                        background: isGradient ? isGradient : "",
                        width: typeof width == 'string' ? width : width - 4 + 'px',
                        height: typeof height == 'string' ? height : height - 4 + 'px',
                        borderRadius: rounded ? rounded + 'px' : '10px'
                    }}
                    className={`cursor-pointer ${roundedFull ? 'rounded-full' : ''}  relative ${disabled ? '!bg-[#a6a6a6]' : isOtherColor ? 'new_button_shadow-other-color' : 'new_button_shadow'} mx-auto flex justify-center items-center text-white  focus:outline-none`}>
                    {
                        children && children
                    }
                    <span style={{ fontSize: fontSize + 'px' }} className="text-base text-white">{title}</span>
                    {isLoading && <img className='w-4 h-4 animate-spin ml-2' src={loadingImg} />}

                </div>
            </div >
            {
                isSoundPlay && <audio ref={AudioRef} src={backMusic} id="audio"></audio>
            }


        </>
    )
}



export default ButtonComp