import starCard from "../../../assets/images/cards/starCard.png"
import tip_white from "../../../assets/images/cards/tip-white.png"
import arena from "../../../assets/images/pk/arena.png"
import lose from "../../../assets/images/lose.png"
import explosion from "../../../assets/images/explosion.gif"
import PK_bg from "../../../assets/mp3/PK_bg.mp3"
import crashEffects from "../../../assets/mp3/crashEffects.mp3"
import loseAudio from "../../../assets/mp3/lose.mp3"
import winAudio from "../../../assets/mp3/win.mp3"
import attributeRestraint_1 from "../../../assets/mp3/attributeRestraint_1.mp3"
import attributeRestraint from "../../../assets/mp3/attributeRestraint.mp3"
import win from "../../../assets/images/win.png"
import down from "@/assets/images/down.svg"
import up from "@/assets/images/up.svg"
import { postCardBattle, postApexPK } from "@/api/pk_url.js"
import "./index.less"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { useEffect, useRef, useState } from "react"
import CardItem from "../../../components/CardItem"
import { attributeImage } from "../../../utils"
import useNumberScroll from "../../../hook/useNumberScroll"
import RecordDetailsContent from "../../../components/pk/recordDetails"
import PromptDialog from "../../../components/PromptDialog"
import BlurDialog from "../../../components/BlurDilaog"

const BattleResult = () => {
  const [searchParams] = useSearchParams()
  const numberScroll = useNumberScroll()
  const navigate = useNavigate()
  const battleCardData = useSelector((state: any) => state.card.battleCardData)
  const battleInfo = useSelector((state: any) => state.battle.battleInfo)
  const idCard: any = searchParams.get("id") as string;
  const type: any = searchParams.get("type") as string;
  const [resultData, setResultData] = useState<any>({})
  const [scoreChallenger, setScoreChallenger] = useState<any>(0)
  const [scoreOwner, setScoreOwner] = useState<any>(0)
  const [arr, setArr] = useState<any>(0)
  const [arrRes, setArrRes] = useState<any>(-1)
  const PK_bgRef = useRef<any>(null)
  const attributeBeRestraintRef = useRef<any>(null)
  const attributeRestraintRef = useRef<any>(null)
  const crashEffectsRef = useRef<any>(null)
  const loseAudioRef = useRef<any>(null)
  const winAudioRef = useRef<any>(null)
  const [explosionType, setExplosionType] = useState(0)
  const [isRecordDetails, setIsRecordDetails] = useState(false)

  const [promptText, setPromptText] = useState('')
  const [promptDialog, setPromptDialog] = useState(false)

  const isPlayBgMusic = useSelector((state: any) => state.settings.settings.music)
  const isSoundPlay = useSelector((state: any) => state.settings.settings.sound)
  const { settings } = useSelector((state: any) => state.settings)


  const cardBattle = async () => {
    try {
      if (type == 'PKReplay') {
        setResultData(battleInfo)
      } else if (type == 'peak') {
        const { data, code } = await postApexPK(battleCardData.account_id, true)
        if (code == '0') {
          setResultData(data)
        } else if (code == '65541') {
          setPromptText('You need to configure at least three cards in your deck to participate in the Peak Battle!')
          setPromptDialog(true)
        } else if (code == '65555') {
          setPromptText('The target you are challenging is ranked more than 20 positions higher than you, so the challenge cannot proceed! Please update your ranking and try again.')
          setPromptDialog(true)
        } else if (code == '65547') {
          setPromptText('Insufficient $STAR')
          setPromptDialog(true)
        } else {
          setPromptText('An error occurred during the Peak Battle challenge. Please try again later!')
          setPromptDialog(true)
        }
      } else {
        const { data, code, msg } = await postCardBattle(battleCardData.id, idCard)
        if (code == '0') {
          setResultData(data)
        } else {
          setPromptText(msg)
          setPromptDialog(true)
        }
      }
    } catch {
      setPromptText('Please try again later.')
      setPromptDialog(true)
    }


  }
  useEffect(() => {
    if (arr >= 5) {
      setTimeout(() => {
        setIsRecordDetails(true)
      }, 6000)
    }
  }, [arr])

  useEffect(() => {
    try {
      if (Object.keys(resultData).length > 0) {
        let time = setInterval(() => {
          setArrRes(arrRes + 1)
          if (resultData.rounds[arr + 1]?.is_counters != 0) {
            let total_powerArena = resultData.rounds[arr + 1]?.challenger_total_power
            let final_powerChallenger = resultData.rounds[arr + 1]?.challenger_final_power
            let total_power = resultData.rounds[arr + 1]?.owner_total_power
            let final_power = resultData.rounds[arr + 1]?.owner_final_power
            let domArena = document.querySelector(`.arena_decks${arr + 1}`)
            let domChallenger = document.querySelector(`.challenger_power${arr + 1}`)
            numberScroll(domArena, total_power, final_power)
            numberScroll(domChallenger, total_powerArena, final_powerChallenger)
            if (attributeRestraintRef.current && resultData.rounds[arr + 1]?.is_counters) {
              resultData.rounds[arr + 1]?.is_counters == 2 ? attributeRestraintRef?.current?.play() : attributeBeRestraintRef?.current?.play()
            }
          }
          setArr(arr + 1)

        }, 4000)
        if (arr >= 6) {
          clearInterval(time)
        }
        return () => {
          clearInterval(time)
        }
      }
    } catch {

    }
  }, [arr, resultData])


  useEffect(() => {
    try {
      if (Object.keys(resultData).length == 0) {
        setTimeout(() => {
          setExplosionType(explosionType + 1)
          crashEffectsRef?.current?.play()
        }, 3200)

      }
      if (Object.keys(resultData).length > 0) {
        let time = setInterval(() => {
          setExplosionType(explosionType + 1)
          crashEffectsRef?.current?.play()
        }, 3000)
        if (arr >= 6) {
          clearInterval(time)
        }
        return () => {
          clearInterval(time)
        }
      }
    } catch {

    }

  }, [arr])


  useEffect(() => {
    cardBattle()
  }, [])

  return (
    <div style={{ overflowY: 'auto' }} className={`px-4 relative battleResult ${isRecordDetails ? ' pt-[24px]' : 'pt-[34px]'} pb-[32px] overflow-hidden flex justify-center h-[100vh]`}>
      {!isRecordDetails ?
        <div className="my-auto">
          <div className="rounded-[10px] w-full px-5 py-[14px] justify-between items-center flex" style={{ textShadow: '0px 2px 4px rgba(0,0,0,0.5)', background: ' linear-gradient( 135deg, #00E49F 0%, #54ADFF 100%)' }}>
            <div className="text-[14px] text-[#fff] flex items-center gap-[10px] leading-[18px]"><img className="w-5 h-5" src={arena} alt="" /> {resultData.arena_owner_name}</div>
            <div className="text-[14px] text-[#fff] leading-[18px]">Power: {resultData.arena_owner_final_power}</div>
          </div>
          <div className="px-[10px] pt-5 flex flex-wrap justify-between gap-[30px] gap-y-[10px] items-center">
            {Array.isArray(resultData.arena_decks) && resultData.arena_decks.map((el: any, index: any) =>
              <div key={`${el?.id}-arena_decks-${index}`} className="relative flex justify-center items-center w-[25%]">
                <div className={`battle_card ${arr >= index ? 'arena_0' : ''} card1`}
                  onAnimationEnd={() => {
                    if (index === 0) {
                      resultData.rounds[index]?.winner == 1 ? setScoreChallenger(scoreChallenger + 2) : setScoreOwner(scoreOwner + 2)
                    } else {
                      resultData.rounds[index]?.winner == 1 ? setScoreChallenger(scoreChallenger + 1) : setScoreOwner(scoreOwner + 1)
                    }

                  }} >
                  <div className="battle_content">
                    <div className="rounded-[20px]">
                      <div className="battle_back-content">
                        <div className="relative flex justify-center items-center h-full w-full">
                          <img src={tip_white} className=" z-5 absolute w-5" alt="" />
                          <img src={starCard} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="battle_front">
                      <div className="battle_front-content relative ">
                        {el && el.url ?
                          <div className={`battle_back ${arr == index ? 'border_bg' : '!bg-transparent'}`}>
                            <div className={` flex h-[120px] relative w-[80px] justify-center items-center`}>
                              {arrRes < index ?
                                <div className=" flex justify-center items-center absolute z-10 w-[77px] h-[30px] text-[#fff] text-[20px] text-center leading-[30px] bg-[#000]/50 rounded-[6px]">
                                  {arr >= index && resultData.rounds[index]?.is_counters != 0 && <img className="w-[13px] mr-1" src={resultData.rounds[index]?.is_counters == 1 ? up : down} alt="" />}
                                  <span className={`text-[#fff] text-[20px] text-center leading-[30px] arena_decks${index}`}> {el?.bonus_power ? el.power + el?.bonus_power : el.power}</span>
                                </div> :
                                <img className=" z-20 absolute " src={resultData.rounds[index]?.winner == 1 ? lose : win} alt="" />
                              }
                              <CardItem height={120} isHero={!index} rounded={10} enhanceLevel={el?.enhance_level} image={el?.url} big={false} level={el.card_level + 1}>
                                {el.property && <img className={`w-[23px] z-10 top-0 left-0 h-[23px] ${arr >= index && resultData.rounds[index]?.is_counters == 1 ? 'transition-all duration-[0.5s] delay-[1.5s]  translate-y-[20px] translate-x-[28px]' : ''} absolute `} src={attributeImage(el.property)} alt="" />}
                              </CardItem>
                              {
                                (settings.nsfw_pay_type != 2 || !settings.star_nsfw) && el.is_nsfw && <BlurDialog isShowEyes={false}></BlurDialog>
                              }
                            </div>
                          </div>
                          :
                          <div className={`battle_back ${arr == index ? 'border_bg' : ' !bg-transparent'}`}>
                            <div className="relative  h-[120px] w-[80px] flex justify-center items-center">
                              {arrRes < index ?
                                <div className=" flex justify-center items-center absolute z-10 w-[77px] h-[30px] text-[#fff] text-[20px] text-center leading-[30px] bg-[#000]/50 rounded-[6px]">
                                  <span className={`text-[#fff] text-[20px] text-center leading-[30px] arena_decks${index}`}>0</span>
                                </div> :
                                <img className=" z-20 absolute " src={resultData.rounds[index]?.winner == 1 ? lose : win} alt="" />
                              }
                              <div className=" text-[14px] text-[#a6a6a6] z-10 leading-[18px] absolute bottom-7"> None </div>
                              <img src={starCard} alt="" />
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>)}
          </div>
          <div className="px-[36px] w-full my-4 flex justify-between items-center">
            <div className=" bg-white rounded-[10px] w-[90px] h-10 flex justify-center items-center text-[30px] leading-[37px] text-[#333]">{scoreOwner}</div>
            <div className="VS">VS</div>
            <div className=" bg-white rounded-[10px] w-[90px] h-10 flex justify-center items-center text-[30px] leading-[37px] text-[#333]">{scoreChallenger}</div>

          </div>
          <div className="px-[10px] flex flex-wrap justify-between gap-[30px] gap-y-[10px] items-center">
            {Array.isArray(resultData.challenger_decks) && resultData.challenger_decks.map((el: any, index: number) =>
              <div key={`${el?.id}-challenger_decks-${index}`} className="relative flex justify-center items-center w-[25%]">
                <div className={`battle_card ${arr >= index ? 'challenger_0' : ''}  bottom card1`}>
                  {arr >= index && <img className={`${arr >= index ? 'explosionAnimation' : 'hidden'}  absolute z-10 top-[-35px]`} src={explosion} alt="" />}
                  <div className="battle_content">
                    <div className="battle_back rounded-[20px]">
                      <div className="battle_back-content">
                        <div className="relative flex justify-center items-center h-full w-full">
                          <img src={tip_white} className=" z-5 absolute w-5" alt="" />
                          <img src={starCard} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="battle_front">
                      <div className="battle_front-content">
                        {el && el.url ?
                          <div className={`battle_back ${arr == index ? 'border_bg' : '!bg-transparent'}`}>
                            <div className="relative  h-[120px] w-[80px] flex justify-center items-center ">
                              {arrRes < index ?
                                <div className=" flex justify-center  items-center absolute z-10 w-[77px] h-[30px] bg-[#000]/50 rounded-[6px]">

                                  {arr >= index && resultData.rounds[index]?.is_counters != 0 && <img className="w-[13px] mr-1" src={resultData.rounds[index]?.is_counters == 2 ? up : down} alt="" />}

                                  <span className={`text-[#fff] text-[20px] text-center  leading-[30px] challenger_power${index}`}> {el?.bonus_power ? el.power + el?.bonus_power : el.power}</span>
                                </div>
                                :
                                <img className=" z-20 absolute " src={resultData.rounds[index]?.winner == 2 ? lose : win} alt="" />
                              }
                              <CardItem isHero={!index} height={0} rounded={10} enhanceLevel={el?.enhance_level} image={el?.url} big={false} level={el.card_level + 1}>
                                {el.property && <img className={`w-[23px] z-10 top-0 left-0 h-[23px] ${arr >= index && resultData.rounds[index]?.is_counters == 2 ? 'transition-all duration-[0.5s] delay-[1.5s]  translate-y-[20px] translate-x-[28px]' : ''} absolute `} src={attributeImage(el.property)} alt="" />}
                              </CardItem>
                              {
                                (settings.nsfw_pay_type != 2 || !settings.star_nsfw) && el.is_nsfw && <BlurDialog isShowEyes={false} callback={() => { }}></BlurDialog>
                              }
                            </div>
                          </div>
                          :
                          <div className={`battle_back ${arr == index ? ' border_bg' : '!bg-transparent'}`}>
                            <div className="relative flex h-[120px] w-[80px] justify-center items-center">
                              {arrRes < index ?
                                <div className=" flex justify-center  items-center absolute z-10 w-[77px] h-[30px] bg-[#000]/50 rounded-[6px]">
                                  <span className={`text-[#fff] text-[20px] text-center  leading-[30px] challenger_power${index}`}>0</span>
                                </div>
                                :
                                <img className=" z-20 absolute " src={resultData.rounds[index]?.winner == 2 ? lose : win} alt="" />
                              }
                              <div className=" text-[14px] text-[#a6a6a6] z-10 leading-[18px] absolute bottom-7"> None </div>
                              <img src={starCard} alt="" />
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>)}
          </div>
          <div className=" text-[14px] text-center pt-[31px] pb-[34px] leading-[18px] text-[#ffffff]">
            <span className="text-[14px] text-center mt-[31px] border-b-[1px] leading-[18px] text-[#ffffff]" onClick={() => {
              setArr(6)
              setIsRecordDetails(true)
            }}> Skip </span>
          </div>
        </div > :
        <RecordDetailsContent back={type} PK_bgRef={PK_bgRef.current} loseAudioRef={loseAudioRef.current} winAudioRef={winAudioRef.current} closeHandle={() => { navigate('/pk') }} recordResult={resultData} recordInfo={{}} type="1"></RecordDetailsContent>
      }
      {/* {isPlayBgMusic && <audio ref={PK_bgRef} loop src={PK_bg} id="audio"></audio>} */}
      {isSoundPlay && <>
        <audio ref={attributeBeRestraintRef} src={attributeRestraint_1} id="audio"></audio>
        <audio ref={attributeRestraintRef} src={attributeRestraint} id="audio"></audio>
        <audio ref={crashEffectsRef} src={crashEffects} id="audio"></audio>
        <audio ref={loseAudioRef} src={loseAudio} id="audio"></audio>
        <audio ref={winAudioRef} src={winAudio} id="audio"></audio>
      </>}
      <PromptDialog title={'Attention'} buttonText={promptText == 'You need to configure at least three cards in your deck to participate in the Peak Battle!' ? "Configure Deck" : 'OK'} dialog={promptDialog} setDialog={setPromptDialog} callback={() => {
        setPromptDialog(false),
          navigate('/pk')
        if (type == 'peak') {
          if (promptText == 'You need to configure at least three cards in your deck to participate in the Peak Battle!') {
            navigate('/my-deck?id=-1')
          } else {
            navigate('/peaks-battle')
          }
        }
      }
      } text={promptText}></PromptDialog>
    </div>
  )
}
export default BattleResult