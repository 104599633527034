import WebApp from '@twa-dev/sdk'

WebApp.ready();
WebApp.enableClosingConfirmation()

export default function () {
    try {
        const initHeight = WebApp.viewportHeight;
        WebApp.onEvent("viewportChanged", (e) => {
            if (e.isStateStable) {
                if (WebApp.platform == 'ios' && WebApp.isExpanded) {
                    if (initHeight > WebApp.viewportHeight) {
                        const keyboardHeight = initHeight - WebApp.viewportHeight;
                        if (keyboardHeight > 100) {
                            localStorage.setItem("keyboardHeight", keyboardHeight.toString());
                        }
                    }
                }
            }

        })
        
        WebApp.initDataUnsafe?.chat_instance && localStorage.setItem('chatInstance', WebApp.initDataUnsafe?.chat_instance)
        WebApp.initDataUnsafe?.user?.username && localStorage.setItem('userName', WebApp.initDataUnsafe?.user?.username)
        WebApp.initDataUnsafe?.user?.first_name && localStorage.setItem('firstName', WebApp.initDataUnsafe?.user?.first_name)
        WebApp.initDataUnsafe?.user?.last_name && localStorage.setItem('lastName', WebApp.initDataUnsafe?.user?.last_name)
        WebApp.initDataUnsafe?.user?.id && localStorage.setItem('userId', WebApp.initDataUnsafe?.user?.id.toString())
    }
    catch (e) {

    }

    return {
        userId: WebApp.initDataUnsafe?.user?.id.toString(),
        userName: WebApp.initDataUnsafe?.user?.username,
        firstName: WebApp.initDataUnsafe?.user?.first_name,
        lastName: WebApp.initDataUnsafe?.user?.last_name,
        chatInstance: WebApp.initDataUnsafe?.chat_instance,
        initData: WebApp.initData
    }
    // 使用JSON.parse来解析user字段的值


}