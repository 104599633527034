import card_border from '@/assets/images/cards/card_border.png'
import cardLevel_3 from '@/assets/images/cards/cardLevel_3.png'
import cardLevel_2 from '@/assets/images/cards/cardLevel_2.png'
import cardLevel_4 from '@/assets/images/cards/cardLevel_4.png'
import cardLevel_5 from '@/assets/images/cards/cardLevel_5.png'
import cardLevel_7 from '@/assets/images/cards/cardLevel_7.png'
import power_bg from '@/assets/images/attribute/power_bg.png'
import enhance from '@/assets/images/attribute/enhance.png'
import enhance_2 from '@/assets/images/attribute/enhance_2.png'
import enhance_3 from '@/assets/images/attribute/enhance_3.png'
import enhance_4 from '@/assets/images/attribute/enhance_4.png'
import enhance_5 from '@/assets/images/attribute/enhance_5.png'
import nft from "../assets/images/newNft.png"
import nfted from "../assets/images/newNfted.png"
import cardLevel_6 from '@/assets/images/cards/cardLevel_6.png'
import water from '../assets/images/attribute/water.png'
import electric from '../assets/images/attribute/electric.png'
import grass from '../assets/images/attribute/grass.png'
import ground from '../assets/images/attribute/ground.png'
import fire from '../assets/images/attribute/fire.png'
import no_bg from '@/assets/images/cards/no_bg.png'
import coin from '@/assets/images/coin.png'
import LazyLoad from './LazyLoad'
import WebApp from '@twa-dev/sdk'
import { convertNumber, formatNumber } from '../utils'
import { useLocation } from 'react-router-dom'
import { getThumbup } from '@/api/url.js'
import { useState } from 'react'
import PowerDialog from '../pages/cards/PowerDialog'

interface CardProps {
  info?: any,
  no?: string
  image: string
  text?: string
  id?: string
  level: number
  big?: boolean
  mine?: number
  isNo?: boolean
  height?: number
  width?: number
  rounded?: number
  view?: number
  like?: number
  pageName?: string
  setSaleCardsData?: any
  saleCardsData?: Array<any>
  price?: string
  state?: number
  children?: any
  is_nft?: any
  mint_state?: any
  enhanceLevel?: any
  property?: any
  power?: any
  is_enhance?: any
  noLike?: any
  isShowLevel?: boolean
  isHero?: boolean
  cardType?: number,
  bonusPower?: number
}
const CardItem = ({
  cardType,
  isShowLevel = true,
  children,
  no,
  image,
  text,
  level,
  big = false,
  mine = 2,
  isNo = true,
  width,
  height,
  rounded,
  id = '',
  pageName = '',
  like = 0,
  view = 0,
  setSaleCardsData,
  saleCardsData,
  price = '',
  state = 0,
  is_nft =
  false,
  mint_state = 0,
  enhanceLevel = 0,
  property = '',
  is_enhance = false,
  noLike = false,
  isHero = false,
  power = 0,
  bonusPower = 0,
  info = null
}: CardProps) => {
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false)
  function borderImage(level: number) {
    switch (level) {
      case 1:
        return card_border
      case 2:
        return cardLevel_2
      case 3:
        return cardLevel_3
      case 4:
        return cardLevel_4
      case 5:
        return cardLevel_5
      case 10:
        return cardLevel_7
      default:
        return cardLevel_6
    }
  }
  function attributeImage(attribute: string) {
    switch (attribute) {
      case 'water':
        return water
      case 'electric':
        return electric
      case 'grass':
        return grass
      case 'ground':
        return ground
      case 'fire':
        return fire
      default:
    }
  }

  function enhanceImage(val: number) {
    switch (val) {
      case 1:
        return enhance
      case 2:
        return enhance_2
      case 3:
        return enhance_3
      case 4:
        return enhance_4
      case 5:
        return enhance_5
    }
  }
  async function Thumbup() {
    if (!loading && pageName == 'sale') {
      setLoading(true)
      WebApp.HapticFeedback.impactOccurred('heavy'); // 震动1000毫秒
      try {
        const { data } = await getThumbup(id)
        if (!saleCardsData) return
        const newSaleCardsData = saleCardsData.map((el: any) => {
          let newLikeCount = JSON.parse(JSON.stringify(el))
          if (el.id == id) {
            newLikeCount.like_count = data.like_count
          }
          return newLikeCount
        })
        setSaleCardsData(newSaleCardsData)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
  }


  const [isShowPowerDialog, setIsShowPowerDialog] = useState(false)
  return (
    <>
      <div
        className="h-full pointer-events-none absolute opacity-0 z-[30] inset-x-0 w-full animate-card-shine [animation-delay:200ms]">
        <div className="h-[150%] translate-y-[28px] blur-sm opacity-80 md:blur-md w-[26px] bg-[#FFFFFF0F]"></div>
      </div>
      {/* <img style={{width: 'calc(100% - 1px)', height: 'calc(100% - 1px)',borderRadius: '12px',overflow: "hidden"}} src={bg} alt="" /> */}
      <div className=' absolute top-0 left-0 z-10'>
        {!(level == 1 || level == 6) && property && <img className={`${big ? 'w-10 h-10' : is_enhance ? 'w-[15px]' : 'w-[23px] h-[23px]'}`} src={attributeImage(property)} alt="" />}
        {
          is_nft && <div className='nft'>
            {
              mint_state == 3 || level == 10 ? <img className={` ${big ? 'w-[52px]' : 'w-[42px]'} h-5 mt-[6px]`} src={nfted} alt="" /> : <img className={` ${big ? 'w-[52px]' : 'w-[42px]'} h-5 mt-[6px]`} src={nft} alt="" />
            }
          </div>
        }
      </div>
      <img alt="" />
      {level != 6 && level != 1 && power != 0 &&
        <div onClick={() => {
          if (Object.prototype.toString.call(info) === '[object Object]') {
            setIsShowPowerDialog(true)
          }

        }} className={` ${big ? ' h-10 rounded-[20px]' : 'h-6'} absolute px-[5px] flex items-center justify-center z-[70] top-0 right-0`}>
          <div className={`absolute top-0 left-0 w-full h-full opacity-60 ${big ? ' rounded-tr-[20px]' : ''}`} style={{ backgroundImage: `url(${power_bg})`, backgroundSize: '100% 100%' }}></div>
          <p className={`${bonusPower ? 'text-style-4 !leading-[24px] pr-[3px]' : 'text-style-7'}  ${big ? '!text-[30px]' : '!text-[12px]'} !font-bold`}>{bonusPower ? bonusPower + power : power}</p>
        </div>
      }
      {
        image ?
          <LazyLoad
            image={image}
            width={width}
            height={height}
            rounded={rounded}
          /> : <div className={`h-[${height}px]`}></div>
      }
      {enhanceLevel != 0 && <img className={`${is_enhance ? 'h-[20px]' : big ? 'w-[44px]' : 'w-[30px]'} absolute z-10 bottom-0 right-0`} src={enhanceImage(enhanceLevel)} alt="" />}
      {
        (pageName == 'sale' || state === 2) && !noLike && (pathname == '/cards' || (pathname == '/refining' && state === 2) || pathname == "/change-backgroud" || pathname == '/search') &&
        <div className={`flex ${big ? 'scale-150 right-9 top-5' : ' left-4 bottom-9'} justify-center z-[10] font-medium text-[#E0E0E0] text-[12px] leading-3 absolute gap-1 items-center`}>
          <div onClick={(e) => {
            e.stopPropagation()
            Thumbup()
          }} className=' bg-[rgba(0,0,0,0.4)] font-medium text-[#E0E0E0] text-[12px] leading-3 h-4 p-1 rounded-[25px] flex items-center gap-1'>
            <div className=' relative'>
              <svg width="9px" height="8px" viewBox="0 0 9 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g id="炼化mine页面" transform="translate(-35.000000, -266.000000)" fill="#FFFFFF" fillRule="nonzero">
                    <g id="编组-13" transform="translate(30.000000, 262.000000)">
                      <g id="编组备份-3" transform="translate(5.000000, 4.000000)">
                        <path d="M6.16401541,0.0287077182 C6.91957638,-0.0868417951 7.62901168,0.148257022 8.20322002,0.700894807 C8.78665349,1.26264323 9.06633256,2.00282677 8.9866814,2.77923064 C8.90793026,3.54630167 8.49572428,4.30381763 7.8020392,4.97156051 C7.60853639,5.15799522 7.08832884,5.66130225 6.341768,6.38437556 C6.00606313,6.70969189 5.64875793,7.05567381 5.28875271,7.40498889 L4.93864762,7.74408228 L4.7995956,7.87896412 C4.6332039,8.04034529 4.3665786,8.04034529 4.2001869,7.87896412 L2.99146934,6.7056921 L2.73609064,6.4585939 C2.22338378,5.96283984 1.71060133,5.46716204 1.1977433,4.97156051 C0.50405822,4.30381763 0.0920772411,3.54652387 0.013326094,2.77923064 C-0.0663250627,2.00282677 0.213128995,1.26264323 0.796787475,0.700894807 C1.37099582,0.148257022 2.08043112,-0.086619588 2.83599209,0.0287077182 C3.39670023,0.114258799 3.96550849,0.390022169 4.50011625,0.830221382 C5.03494901,0.390244376 5.60353228,0.114258799 6.16424042,0.0287077182 L6.16401541,0.0287077182 Z" id="路径"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <svg className={`svg-celebrate ${loading && state !== 2 ? 'svg-celebrate_active' : ''}`} width="100" height="100" xmlns="http://www.w3.org/2000/svg">
                <polygon points="10,10 20,20"></polygon>
                <polygon points="10,50 20,50"></polygon>
                <polygon points="20,80 30,70"></polygon>
                <polygon points="90,10 80,20"></polygon>
                <polygon points="90,50 80,50"></polygon>
                <polygon points="80,80 70,70"></polygon>
              </svg>
            </div>
            {like}
          </div>
          <div className=' bg-[rgba(0,0,0,0.4)] font-medium text-[#E0E0E0] text-[12px] leading-3 h-4 p-1 rounded-[25px] flex items-center gap-1'>
            <svg width="11px" height="8px" viewBox="0 0 11 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="炼化mine页面" transform="translate(-73.000000, -266.000000)" fill="#FFFFFF" fillRule="nonzero">
                  <g id="编组-15" transform="translate(69.000000, 262.000000)">
                    <g id="编组" transform="translate(4.000000, 4.000000)">
                      <path d="M5.50013508,0 C1.86589644,0 0,3.99974599 0,3.99974599 C0,3.99974599 1.4080277,8 5.50013508,8 C9.20593068,8 11,4.01534775 11,4.01534775 C11,4.01534775 9.19162421,0 5.50013508,0 L5.50013508,0 L5.50013508,0 Z M5.50839968,6.49981117 C4.17957953,6.49981117 3.21901919,5.4059633 3.21901919,3.99970588 C3.21901919,2.59332814 4.17965321,1.49976103 5.50839968,1.49976103 C6.83714616,1.49976103 7.79766965,2.59323456 7.79766965,3.99970588 C7.79766965,5.4059633 6.83715845,6.49981117 5.50839968,6.49981117 L5.50839968,6.49981117 L5.50839968,6.49981117 Z M5.50839968,2.4995412 C4.74892827,2.50144219 4.13480577,3.19616841 4.13480577,3.99971925 C4.13480577,4.80284227 4.74894055,5.4998145 5.50839968,5.4998145 C6.26782198,5.4998145 6.88190763,4.8027888 6.88190763,3.99971925 C6.88195676,3.19588766 6.26783426,2.49799296 5.50839968,2.4995412 L5.50839968,2.4995412 L5.50839968,2.4995412 Z" id="形状"></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            {view}
          </div>
        </div>
      }
      {
        !(pageName == 'sale' && (pathname == '/cards' || pathname == "/change-backgroud" || pathname == '/search')) && state !== 2 || big ?
          <div style={{ background: ' linear-gradient( 180deg, rgba(238,238,238,0) 0%, #070707 100%)' }} className={`absolute italic px-[8%] ${big ? 'text-[16px] leading-[20px]' : 'text-[12px] leading-[14px]'} pb-[8%] text-center mix-blend-lighten w-[95%] bottom-[2px] rounded-[20px] text-[12px] text-[#fff] leading-[14px]`}>
            {text}
          </div>
          :
          <div className={`${cardType == 2 ? 'hidden' : ''} !absolute bottom-4 left-4 text-style-6 flex z-[10] items-center text-[16px] font-bold leading-[18px]`}>
            <img className='w-[14px] h-[14px] mr-1' src={coin} alt="" /> {formatNumber(Number(price))}
          </div >


      }
      {
        isShowLevel && !isHero && <img className="h-full absolute top-0 left-0" src={borderImage(level)} alt="" style={{ width: width ? `${width}px` : '100%' }} />
      }

      {
        (level === 6 && isNo) && <>
          <div style={{ backgroundImage: `url(${no_bg}) ` }} className={`${big ? 'w-[90px] h-[90px]' : 'w-10 h-10'} bg-no-repeat text-center flex items-center justify-center  absolute bg-cover top-0 left-0`}>
            <p className={`${big ? 'text-[18px]  top-[25px] mt-[-23px] ml-[-24px] left-[12px]' : 'scale-[0.833] top-[10px] left-[-1px] mt-[-10px] ml-[-10px] text-[12px]'} -rotate-45  text-[#000000] leading-[12px] ${mine == 1 ? '!left-[-4px]' : ''} font-medium`}>{convertNumber(no)}</p>
          </div>
        </>
      }
      {
        children && children
      }


      {
        isShowPowerDialog && <PowerDialog data={info} callback={() => { setIsShowPowerDialog(false) }}></PowerDialog>
      }


    </>
  )
}
export default CardItem;