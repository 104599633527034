import { useState } from "react";
import wallet from "../../hook/wallet/contentWallet"
import PublicDialog from "../PublicDialog";
import ButtonComp from "./ButtonComp";

interface Props {
    callback?: () => void
}
const ConnectWallet = ({ callback }: Props) => {
    const { useConnectWallet, useGetWalletAddress } = wallet();
    const address = useGetWalletAddress()
    //签到时链接钱包弹窗的显示隐藏
    const [isShowWallet, setIsShowWallet] = useState(true)

    const connectWallet = async (el?: any) => {
        useConnectWallet(el, callback && callback());
    }
    const connect_wallet_button = () => {
        return (
            <div className='flex flex-col items-center justify-around mx-auto h-[216px] py-5'>
                <p className='text-sm text-[#333]'>Please connect your TON wallet.</p>
                {/* <div className='bg-gradient-to-r button_shadow text-[#fff] from-[#00E49F] to-[#54ADFF] w-[174px] h-[36px] rounded-[10px]' onClick={(event) => { event.stopPropagation(); closePlaySound(); ; }}>
                    Connect
                </div> */}
                <ButtonComp callBack={() => { connectWallet() }} title="Connect"></ButtonComp>
            </div>
        )
    }
    return (
        <>
            <PublicDialog teleport={document.querySelector('body')} width={"290px"} isPadding={false}  closeOnClickOverlay={false} dialog={true} setDialog={() => {
                callback && callback()
            }} content={connect_wallet_button()} customClassName="customClassName"></PublicDialog>
        </>
    )
}

export default ConnectWallet