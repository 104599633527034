
// import "../search/index.less"
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom'
// import starImg from "../../assets/images/inviting/star.png"
import { judgeClient } from '../../utils/judgeClient';
import { useSelector } from 'react-redux';
import CardItem from '../../components/CardItem';
import { convertNumber } from '../../utils';
import Back from '../../components/common/BackComp';
import ButtonComp from '../../components/common/ButtonComp';
import PayDialog from '../../components/common/PayDialog';
import { getShopPayConfig } from "@/api/shop.js"
import { Toast } from 'react-vant';
import tonImg from "../../assets/images/shop/ton.png"
import usdtImg from "../../assets/images/shop/usdt.png"
import starImg from "../../assets/images/shop/star.png"
import { isInputElement } from 'react-router-dom/dist/dom';
import { calculateUsdtAmountSmall } from '../../utils/common-helpers';




const Regenerate = (props: any) => {
    const { closePopup } = props
    const [isShowPayDialog, setShowPayDialog] = useState(false)
    const [starCount, setStarCount] = useState(5)
    const navigate = useNavigate()
    const regenerateData = useSelector((state: any) => state.search.regenerateData)
    const { shopCount } = useSelector((state: any) => state.lottery)

    const [message2, setMessage2] = useState("")
    const scrollToRef = useRef<any>(null)
    const inputRef = useRef<any>(null)
    const contentRef = useRef<any>(null)
    const contentTopRef = useRef<any>(null)
    const [payList, setPay] = useState<any>([])
    const focusHandle = () => {
        if (judgeClient() == "IOS") {
            setTimeout(() => {
                scrollToRef.current?.scrollIntoView(false)
            }, 100)
            inputRef.current.style.paddingBottom = "220px"
        }

        if (judgeClient() == "Android") {
            setTimeout(() => {
                scrollToRef.current?.scrollIntoView(false)
            }, 100)
            inputRef.current.style.paddingBottom = "160px"
            // contentRef.current.style.paddingBottom = "180px"
        }

    }
    const blurHandle = () => {
        inputRef.current.style.paddingBottom = "0px"
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        getPayConfigHandle()
        window.addEventListener("resize", resizeHandle)
        return () => {
            window.removeEventListener("resize", resizeHandle)
        }
    }, [])
    const resizeHandle = () => {
        if (contentTopRef) {
            contentTopRef.current.style.height = window.innerWidth - 72 + "px"
        }
        const dom = document.querySelectorAll(".role")
        for (let i = 0; i < dom.length; i++) {
            const width = dom[i].clientWidth;
            dom[i].style.height = width * 1.5 + 'px'
        }

    }

    const goBack = () => {
        closePopup()
    }
    //start refining 
    const refiningHandle = async () => {
        if (shopCount[0] > 0) {
            navigate(`/loading-regenerate?message=${message2}&order=`);
        } else {
            setShowPayDialog(true)
        }

    }

    const getPayConfigHandle = async () => {
        const toast = Toast.loading({
            message: "Loading...",
            forbidClick: true,
            duration: 0
        })
        try {
            const { code, data } = await getShopPayConfig(3)
            if (code == 0) {
                let list = data.filter((item: any) => item.count == 1)
                list = list.map((item: any) => {
                    return {
                        ...item,
                        title: "Regenertate Bundle"
                    }
                });
                setPay(list)
            }
            toast.clear()
        } catch (e) {
            toast.clear()
        }

    }


    useEffect(() => {

        document.addEventListener('touchstart', touchStartHandle);
        return () => {
            document.removeEventListener('touchstart', touchStartHandle);
        }
    })

    const touchStartHandle = () => {
        window.scrollTo(0, 1);
        ensureDocumentIsScrollable();
    }

    const ensureDocumentIsScrollable = () => {
        const isScrollable = document.documentElement.scrollHeight > window.innerHeight;
        if (!isScrollable) {
            document.documentElement.style.setProperty(
                "height",
                "calc(100vh + 1px)",
                "important"
            );

        }
    }
    return (
        <div className='h-full w-full box-border bg-[#000] text-white'>
            <div className="h-14 flex items-center w-full z-[9] px-4 box-border">
                <Back title="Regenerate" callback={() => { goBack() }}></Back>
            </div>
            <div className="h-[calc(100vh_-_56px)] px-4 box-border overflow-auto">
                <div className="refining-card rounded-xl flex justify-center p-4 box-border">
                    <div className="card relative w-[67%] overflow-hidden rounded-xl">

                        {
                            regenerateData && <CardItem id={regenerateData.id} price={regenerateData.price} like={regenerateData.like_count} view={regenerateData.view_count} state={regenerateData.state} mine={1} no={`No. ${convertNumber(regenerateData.seq)}`} image={regenerateData.image} level={regenerateData.is_official ? 6 : regenerateData.level + 1} text={regenerateData.description}>

                            </CardItem>
                        }

                    </div>
                </div>
                <div className="icon-title flex items-center mt-2">

                    <svg width="13px" height="16px" viewBox="0 0 13 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">

                        <defs>
                            <linearGradient x1="18%" y1="-1.94289029e-13%" x2="82%" y2="100%" id="linearGradient-1">
                                <stop stopColor="#00E49F" offset="0%"></stop>
                                <stop stopColor="#54ADFF" offset="100%"></stop>
                            </linearGradient>
                            <linearGradient x1="-2.72351586e-14%" y1="32%" x2="100%" y2="68%" id="linearGradient-2">
                                <stop stopColor="#00E49F" offset="0%"></stop>
                                <stop stopColor="#54ADFF" offset="100%"></stop>
                            </linearGradient>
                        </defs>
                        <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="炼化" transform="translate(-16.000000, -432.000000)" fillRule="nonzero">
                                <g id="编组备份-30" transform="translate(16.000000, 432.000000)">
                                    <path d="M9.7387444,6.54784909 C9.59991618,6.54084312 9.54182181,6.70984973 9.65801055,6.77984303 C9.91104961,6.93284547 10.1050299,7.17584642 10.0488971,7.56486045 C9.91106596,8.53787696 8.04619421,8.98687338 6.39892299,8.98687338 C4.75067099,8.98687338 2.88481847,8.53986918 2.74992969,7.56486045 C2.68987378,7.13284769 2.93503398,6.88084855 3.22746736,6.73284326 C3.32987626,6.68283855 3.28360019,6.53684208 3.16937299,6.54283534 C-0.570194557,6.71483028 -0.597770584,8.33486981 1.01798518,9.33788582 C1.64782235,9.73172977 2.32937416,10.0330529 3.04236308,10.2328903 C2.91829547,12.7779505 1.72790033,14.742976 1.72790033,14.742976 C1.72790033,15.4379783 3.820213,16 6.40090088,16 C8.98256952,16 11.0738851,15.4359861 11.0738851,14.742976 C11.0738851,14.742976 9.8844707,12.7779339 9.76041945,10.232907 C10.4728043,10.0322378 11.1539148,9.73096131 11.7838002,9.33790243 C13.3808559,8.343868 13.3710155,6.74785131 9.7387444,6.54784909 L9.7387444,6.54784909 Z M7.08618231,2.69478074 L8.35732765,3.35978355 L8.49123566,4.70981096 C8.51585302,4.97381322 8.85654037,5.09580345 9.06526491,4.91582304 L10.1375261,3.99579956 L11.559318,4.29579458 C11.8359936,4.35380137 12.0644153,4.09280404 11.946265,3.85179531 L11.3367809,2.61778147 L12.0841125,1.45176871 C12.2288417,1.22575143 12.0319191,0.940764458 11.7493424,0.97176007 L10.3019525,1.12975966 L9.33997902,0.108747268 C9.15289685,-0.0892627291 8.80238544,-0.00326526233 8.74625261,0.256735959 L8.46071732,1.58675817 L7.11869489,2.12176866 C6.85776062,2.22876744 6.83806345,2.56578454 7.08618231,2.69478074 Z" id="形状" fill="url(#linearGradient-1)"></path>
                                    <path d="M5.40119964,6.41351849 L6.05607864,6.64009764 L6.12406628,7.10092228 C6.13606703,7.19020875 6.31103229,7.23244529 6.42001834,7.17100307 L6.97291587,6.85802229 L7.70477892,6.95978846 C7.84775879,6.97899413 7.96472874,6.89162027 7.90374568,6.80905977 L7.5898008,6.38664648 L7.97472106,5.99110521 C8.04971329,5.91332621 7.94773183,5.81732971 7.80176422,5.82789681 L7.05489609,5.88165676 L6.55898136,5.5341217 C6.46201266,5.46690981 6.28203463,5.49762295 6.25303697,5.58499682 L6.10406503,6.03909549 L5.41819655,6.21959696 C5.28222127,6.25415123 5.27322486,6.36935341 5.40119964,6.41447479 L5.40119964,6.41351849 Z M0.911990334,4.05181016 L0.675046103,4.77569676 C0.628055621,4.91585835 0.864999853,5.03010422 1.07296302,4.96577716 L2.15276478,4.64224521 L3.19658088,5.00611705 C3.4005438,5.0761819 3.64650104,4.97345943 3.61449905,4.83136931 L3.44954271,4.09981638 L4.33437795,3.60155263 C4.50633888,3.50458389 4.42435866,3.3279395 4.19340651,3.30202379 L3.01061078,3.17241337 L2.51170831,2.50037412 C2.41472301,2.36980741 2.11578322,2.36596627 2.00578466,2.49269185 L1.44189889,3.14456913 L0.250123349,3.22808592 C0.0171627683,3.2444227 -0.0828268673,3.41818226 0.0801542469,3.52090473 L0.911990334,4.05085386 L0.911990334,4.05181016 Z" id="形状" fill="url(#linearGradient-2)"></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <p className="ml-2 mt-1 text-base text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF]">AI Prompt <span className='text-[#a6a6a6] ml-1'>(optional)</span></p>
                </div>
                <div className="input-box mt-2" ref={inputRef}>
                    <textarea
                        value={message2}
                        onChange={(e) => setMessage2(e.target.value)}
                        onFocus={(e) => { e.stopPropagation(); focusHandle() }}
                        onBlur={(e) => { e.stopPropagation(); blurHandle() }}

                        style={{
                            borderRadius: "6px",
                            fontSize: "14px",
                            width: '100%',
                            height: '140px',
                            resize: 'none',
                            padding: '10px',
                            color: '#a6a6a6',
                            background: "#333",
                            boxSizing: 'border-box',
                        }}
                        placeholder="e.g. The boy walking on the streets of Tokyo."
                    />
                </div>
                <div className="text-xs text-[#a6a6a6] text-left mt-[5px]">
                    Please describe the card you want to generate, including colors, patterns, and style details. This will help AI better assist you in creating an image that meets your expectations.
                </div>

                {/* <div onClick={(e) => { e.stopPropagation(); }} className="m-auto button w-36 h-9 text-center rounded-lg flex  mt-4 justify-center items-center bg-gradient-to-r from-[#00E49F] to-[#54ADFF] text-white" ></div> */}

                <ButtonComp mt={25} title="Regenerate" callBack={() => {
                    refiningHandle()
                }}></ButtonComp>
                <div className={`${shopCount[0] > 0 ? 'hidden' : ''} w-full h-4 flex justify-center items-center mt-2 relative`}>
                    {/* <div className='text-style-6 text-[14px] ml-2 font-semibold leading-[18px]'>$ {calculateUsdtAmountSmall(payList.filter((item: any) => item.pay_type == '4')[0]?.price / 100)}</div> */}
                    <div className='flex justify-around px-4'>
                        <img style={{ boxShadow: '0px 0px 6px rgba(0,0,0,.5)' }} className='w-5 rounded-[20px]' src={starImg} alt="" />
                        <img style={{ boxShadow: '0px 0px 6px rgba(0,0,0,.5)' }} className='w-5 rounded-[20px] mx-2' src={usdtImg} alt="" />
                        <img style={{ boxShadow: '0px 0px 6px rgba(0,0,0,.5)' }} className='w-5 rounded-[20px]' src={tonImg} alt="" />
                    </div>
                </div>

                <div ref={scrollToRef} className="scrollInto h-[20px] w-full"></div>
            </div>

            {isShowPayDialog && <PayDialog isNeedDialogSuccess={false} dataList={payList} title={`Regenerate Bundle`} callBack={(val) => {
                setShowPayDialog(false)
                if (val != 'close') {
                    navigate(`/loading-regenerate?message=${message2}&order=${val}`)
                }

            }} />}


        </div>
    )
}


export default Regenerate;
