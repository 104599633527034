

import talk from "@/assets/images/tabbar/talk.png";
import task from "@/assets/images/tabbar/task.png";
import card from "@/assets/images/tabbar/card.png";
import ranks from "@/assets/images/tabbar/ranks.png";
import talkActive from "@/assets/images/tabbar/talk_active.png";
import taskActive from "@/assets/images/tabbar/task_active.png";
import cardActive from "@/assets/images/tabbar/card_active.png";
import ranksActive from "@/assets/images/tabbar/ranks_active.png";
import inviteActive from "@/assets/images/tabbar/invite_active.png";
import invite from "@/assets/images/tabbar/invite.png";
import ai from "@/assets/images/tabbar/ai.png";
import aiActive from "@/assets/images/tabbar/ai_active.png";
import battle from "@/assets/images/tabbar/battle.png";
import battleActive from "@/assets/images/tabbar/battle_active.png";
export default function useTabbar() {
    const tabbarList = [
        {
            id: 6,
            title: 'PK',
            path: '/pk',
            imgUrl: battle,
            imgUrlActive: battleActive,
            isHidden: false,
            introductoryIdex: 1
        },
        {
            id: 3,
            title: 'Cards',
            path: '/cards?id=2',
            imgUrl: card,
            imgUrlActive: cardActive,
            isHidden: false,
            introductoryIdex: 2
        },
        {
            id: 1,
            title: 'AI',
            path: '/agents',
            imgUrl: aiActive,
            imgUrlActive: aiActive,
            isHidden: false,
            isHome: true,
            introductoryIdex: 0
        },



        {
            id: 4,
            title: 'Ranks',
            path: '/ranks',
            imgUrl: ranks,
            imgUrlActive: ranksActive,
            isHidden: true
        },
        {
            id: 5,
            title: 'Spin',
            path: '/my-invites',
            imgUrl: invite,
            imgUrlActive: inviteActive,
            isHidden: false,
            introductoryIdex: 3
        },
        {
            id: 2,
            title: 'Tasks',
            path: '/tasks',
            imgUrl: task,
            imgUrlActive: taskActive,
            isHidden: false,
            introductoryIdex: 4
        },

        // {
        //     id: 6,
        //     title: 'Ranks',
        //     path: '/my-invites',
        //     imgUrl: ranks,
        //     imgUrlActive: ranksActive
        // }
    ]
    return {
        tabbarList,
    }
}