import request from '@/utils/axios-req'



//用户个人主页

export const getAccountProfile = (id) => {
  return request({
    url: `/api/v1/miniapp/account/${id}/profile`,
    method: 'get',
  })
}

export const postAccountProfile = (id, data) => {
  return request({
    url: `/api/v1/miniapp/account/${id}/profile`,
    method: 'post',
    data: {
      image: data.image,
      name: data.name
    }
  })
}

export const getAccountLogsType = (id, sales_type, type) => {
  return request({
    url: `/api/v1/miniapp/account/${id}/logs_type`,
    method: 'get',
    data: {
      sales_type: sales_type,
      type: type
    }
  })
}

export const getRecordsLogsType = (id, data) => {
  return request({
    url: `/api/v1/miniapp/account/${id}/logs`,
    method: 'get',
    data: {
      type: data.type,
      sales_type: data.sales_type,
      query_time: data.query_time,
      code: data.code,
      page: data.page,
      limit: data.limit
    }
  })
}

export const postShareProfile = (id, image) => {
  return request({
    url: `/api/v1/miniapp/account/${id}/share_profile`,
    method: 'post',
    data: {
      image: image,
    }
  })
}



//邀请时获取消息id
export const apiGetShareMessageId = (data) => {
  return request({
    url: `/api/v1/miniapp/story/prepared_inline`,
    method: 'post',
    data,
  })
}


// 用户个人主页分享次数
export const setShareProfileCount = (id) => {
  return request({
    url: `/api/v1/miniapp/account/${id}/share_profile_count`,
    method: 'get',
  })
}

// 用户个人主页分享次数
export const setShareProfile = (id) => {
  return request({
    url: `/api/v1/miniapp/account/${id}/share_profile`,
    method: 'get',
  })
}


